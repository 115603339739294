<section class="advantages-services" *ngIf="dataAdvantages.showAdvantages" [ngClass]="{'cities-detail' : citiesDetail}">
    <div class="container">
        <div class="item" *ngFor="let ceoDetail of dataAdvantages.seoDetails">
            <div class="content-info">
                <div class="img-icon">
                    <img [src]="ceoDetail.icon.src" [alt]="ceoDetail.icon.alt">
                </div>
                <div class="content">
                    <h4 class="title">{{ ceoDetail.title }}</h4>
                    <div class="texts">
                        <p>{{ ceoDetail.summary }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
