import { isPlatformBrowser } from '@angular/common';

export default class Utilities {
  /**
   * @description Función para realizar el scroll
   * @param controlEl  Dato que recibe para controlar el scroll
   */
  getTopOffset(controlEl: HTMLElement) {
    controlEl?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  /**
   * @description Función para validar las fechas validas
   * @param value
   * @param platformId
   * @param disabledDaysCustom
   * @param blocked_days
   * @param formData
   * @param idCity
   * @param idFieldDetailValidation
   * @param DataDefaultFields
   * @param selectedProduct
   * @returns
   */
  static validateAvailableDate(
    value: string,
    platformId: any,
    disabledDaysCustom: any,
    blocked_days: any,
    formData: any,
    idCity: any,
    idFieldDetailValidation: any,
    DataDefaultFields: any,
    selectedProduct: any
  ) {
    let selectedDaysCustom = [];
    if (isPlatformBrowser(platformId)) {
      localStorage.setItem('temporarySelectedCity', value);
    }
    selectedDaysCustom = disabledDaysCustom[value] || [];
    blocked_days.forEach((element) => {
      selectedDaysCustom.push(element);
    });
    const dataArr = new Set(selectedDaysCustom);
    selectedDaysCustom = [...dataArr];
    if (
      formData.get('' + idCity + '') &&
      formData.get('' + idFieldDetailValidation + '') &&
      !DataDefaultFields.hasDefaultInfo &&
      !selectedProduct.is_clic_soft &&
      !selectedProduct.apply_messaging
    ) {
      if (
        this.findDataInArray(
          selectedDaysCustom,
          formData
            .get('' + idFieldDetailValidation + '')
            .value.replaceAll('/', '-')
        )
      ) {
        if (isPlatformBrowser(platformId)) {
          setTimeout(() => {
            return '';
          }, 2000);
        }
      }
    }
    return selectedDaysCustom;
  }

  /**
   * @description Función para encontrar el dato dentro de un arreglo
   * @param array
   * @param data
   * @returns
   */
  static findDataInArray(array: string[], data: string) {
    return array.includes(data);
  }

  /**
   * @description Función para cambiar la ciudad
   * @param value
   */
  static changeCity(value: string, availableCities: any): void {
    availableCities.forEach((element) => {
      if (element.value === value) {
        return element;
      }
    });
  }

  /**
   * @description Funcion para obtener el mes
   * @param position
   * @param array
   * @returns
   */
  static getDatesForMonths(position: number, array: Date[]) {
    let temArray = [];
    array.forEach((element) => {
      if (element.getMonth() === position) {
        temArray.push(element);
      }
    });
    return temArray;
  }

  /**
   * Method that returns the text of the selected days
   * @param month
   * @param dates
   */

  static buildStringSelectedDays(month: string, dates: Date[]) {
    let tempDate = [];
    dates.forEach((element) => {
      tempDate.push(element.getDate());
    });
    return `${month} ${tempDate.join('-')}`;
  }

  /**
   * Method that returns the text of the selected days
   * @param month
   * @param dates
   */

  static buildStringSelectedDaysNewDetail(month: string, dates: Date[]) {
    let tempDate = [];
    let tempYear = [];
    dates.forEach((element) => {
      tempYear.push(element.getFullYear());
      tempDate.push(element.getDate());
    });
    return `${month.slice(0, 3)} ${tempDate.join('-')}, ${tempYear.join('-')}`;
  }

  /**
   * @description Funcion para remover HTML tags de un string
   * @param str
   * @returns
   */
  static removeTags(str: string) {
    if (str === null || str === '') {
      return false;
    } else {
      str = str.toString();
      const str_repl = str.replace(/(<([^>]{1,200})>)/gi, '');
      return str_repl.replace(/&nbsp;/g, '');
    }
  }
}
