import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-error-pqr',
  templateUrl: './modal-error-pqr.component.html',
  styleUrls: ['./modal-error-pqr.component.scss']
})
export class ModalErrorPqrComponent {

  constructor(
    public dialogRef: MatDialogRef<ModalErrorPqrComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  close(){
    this.dialogRef.close();
  }

}
