import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuUserService {
  private sendHideMenuUserSubject = new Subject<boolean>();
  sendMenuUserObservable = this.sendHideMenuUserSubject.asObservable();

  closeMenu(){
    this.sendHideMenuUserSubject.next();
  }
}
