<main class="pageserror content-pages-error" id="layout-top">
  <article class="pageserror_msn">
    <picture>
      <source media="(max-width: 767px)" srcset="./../../../../assets/img/gif/maintenance.gif">
      <img class="pageserror_msn_img" src="./../../../../assets/img/gif/maintenance.gif" alt="pageserror">
    </picture>
    <h1 class="pageserror_msn_title">Nuestro sitio web está en mantenimiento</h1>
    <p class="pageserror_msn_content">¡Trabajamos para mejorar tu experiencia!</p>
    <p class="pageserror_msn_content">Pronto podrás consultar nuevamente nuestros productos y servicios.</p>
    <p class="pageserror_msn_content">Si requieres atención, puedes comunicarte por WhatsApp al número +57 314 3738533.
    </p>
  </article>
</main>