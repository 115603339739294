import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SetDataAddressService {

  private sendSetDataAddressSubject = new Subject<object>();
  setDataAddressObservable = this.sendSetDataAddressSubject.asObservable();

  setDataAddress(data: any) {
    this.sendSetDataAddressSubject.next({
      data
    });
  }
}
