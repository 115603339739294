<mat-dialog-content>
  <div class="content-modal"> 
    <div class="header-modal">
      <a mat-dialog-close class="close-modal"
        ><span class="icon-close"></span
      ></a>
    </div>
    <div>
      <ng-container>
        <div class="shape-icon">
          <img src="../../../../assets/img/iconos/Shape.png" alt="icon" />
        </div>
      </ng-container>
      <ng-container>
        <div>
          <p class="section-contents">
            Ups algo pasó y no hemos podido radicar tu solicitud, por favor
            inténtalo de nuevo en unos minutos.
          </p>
        </div>
      </ng-container>
      <ng-container>
        <div class="custom-button-container">
          <button mat-raised-button class="custom-button" (click)="close()">
            Entendido
          </button>
        </div>
      </ng-container>

    </div>
  </div>
</mat-dialog-content>
