import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ally, NewUserData, UserData } from '@main-views/allies/interfaces/allies';
import { AlliesService } from '@main-views/allies/services/allies.service';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ILibTbModalNotification } from 'tech-block-lib';

declare const dataLayer: any;

@Component({
  selector: 'app-modal-out-jelpit',
  templateUrl: './modal-out-jelpit.component.html',
  styleUrls: ['./modal-out-jelpit.component.scss']
})
export class ModalOutJelpitComponent implements OnInit, OnDestroy {

  @Input() modalData: ILibTbModalNotification;
  @Input() allyOb: BehaviorSubject<Ally>;
  @Input() selectedCategoryOb: string;
  @Input() selectedOriginInput: string;

  public ally: Ally;
  public selectedCategory: string;
  public selectedOrigin: string;
  private subscriptions: Subscription;
  //Email pattern used to validate if the email strucutre is correct.
  private emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  public alphaRegex: RegExp = /[A-z ]/;
  public disableButton: boolean = false;
  public form = new FormGroup({
    fullName: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60),
      this.noWhitespaceValidator,
    ]),
    cellphone: new FormControl('', [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(10),
      Validators.min(0)
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(this.emailPattern)
    ]),
    terms: new FormControl(false, Validators.requiredTrue),
  });

  constructor(
    private alliesService: AlliesService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private confirmationService: ConfirmationService,
    ) { }

  ngOnInit(): void {
    let noLoginData = localStorage.getItem('dataTempFormNoLogin');
    let noLoginObject: any = {};
    if (noLoginData) {
      noLoginObject = JSON.parse(atob(noLoginData));
    }
    let email = localStorage.getItem('email') || noLoginObject?.email;
    if (email) {
      this.alliesService.getUserData(email).subscribe({
        next: (res) => {
          this.form.controls['email'].setValue(email);
          this.form.controls['fullName'].setValue(res.result.name_complete);
          this.form.controls['cellphone'].setValue(res.result.phone);
        }
      });
    };

    this.subscriptions = this.allyOb.subscribe({next: response => {
      this.ally = response;
      this.selectedCategory = this.selectedCategoryOb;
      this.selectedOrigin = this.selectedOriginInput;
      if(this.ally?.id >= 0 && this.selectedCategory){
        this.openModal(this.ally, this.selectedCategory, this.selectedOrigin);
      }
    }})
  }
  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }
    /**
 * If the value of the control is not whitespace, return null, otherwise return an error object with
 * a whitespace key
 * @param {FormControl} control - FormControl - The control that is to be validated.
 * @returns A function that takes a FormControl and returns an object with a whitespace property if
 * the control is invalid.
 */
    public noWhitespaceValidator(control: FormControl) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace && !control.value.startsWith(' ');
      return isValid ? null : { whitespace: true };
    }

     /**
   * The function sets user data in local storage and redirects to a page if the form is valid and the
   * platform is a browser.
   */
  setUserData() {
    if (isPlatformBrowser(this.platformId) && !this.formHasError()) {
      let formData: NewUserData = {
        ds_name_complete: this.form.value.fullName,
        ds_phone: <number>this.form.value.cellphone,
        ds_email: this.form.value.email,
        fl_accept_terms_and_conditions: this.form.value.terms,
        ally_id: this.ally.id,
        ds_origin: this.selectedOrigin
      };
      let userData: UserData = {
        name_complete: formData.ds_name_complete,
        email: formData.ds_email,
        phone: formData.ds_phone
      };
      localStorage.setItem('userData', btoa(JSON.stringify(userData)));
      this.redirectToPage(formData);
    }
  }

  /**
   * The function checks if a form has any errors and returns true if it does, otherwise it returns
   * false.
   * @returns a boolean value. If the form is invalid, it will return true. Otherwise, it will return
   * false.
   */
  formHasError(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.scrollToError();
      return true;
    }
    else return false;
  }
  /**
   * The function scrollToError scrolls to the first element with an error in a form.
   */
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  /**
   * The function `redirectToPage` disables a button, sets user data using a service, and then enables
   * the button again, while also closing a confirmation service, pushing data to Google Analytics, and
   * opening a new window with a selected ally's URL.
   * @param {NewUserData} formData - The formData parameter is an object of type NewUserData, which
   * contains the data submitted by the user in a form.
   */
  redirectToPage(formData: NewUserData) {
    this.disableButton = true;
    this.alliesService.setUserData(formData)
      .pipe(
        finalize(() => this.disableButton = false)
      )
      .subscribe({
        next: (e) => {
          let token = this.ally.name.substring(0, 3).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
          token = token + '_token=' + e.result;
          this.confirmationService.close();
          let param = '?';
          if (this.ally.url.includes('?'))
            param = param.replace('?', '&');
          param = param + token;

          //Porcion de codigo para agregar token solamente a hogaru
          if (this.ally.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() !== 'hogaru')
            param = '';

          //Tag 12F
          dataLayer.push({
            'event': 'ga_event',
            'category': 'aliados',
            'action': 'JPW - formulario aliados',
            'label': 'enviar',
            'aliado': this.ally.name
          });
          setTimeout(() => window.open(this.ally.url + param, "_blank"));
        }
      });
  }
  /**
   * The openModal function is used to handle the logic for opening a modal, pushing data to the
   * dataLayer, and redirecting to a page based on user data.
   * @param {Ally} ally - The "ally" parameter is an object representing an ally. It likely contains
   * properties such as id, name, website, etc.
   * @param {string} categoryName - The `categoryName` parameter is a string that represents the
   * category name for the event being tracked. It is used in the Google Analytics event tracking code
   * to provide additional information about the event.
   */
  openModal(ally: Ally, categoryName: string, origin: string) {
    let userData = localStorage.getItem('userData');
    //Tag 12D
    dataLayer.push({
      'event': 'ga_event',
      'category': 'aliados',
      'action': `JPW - ${categoryName}`,
      'label': `ir a website - ${ally.name}`
    });
    if (userData && !this.disableButton) {
      userData = atob(userData);
      let userObject: UserData = JSON.parse(userData);
      let formData: NewUserData = {
        ally_id: ally.id,
        ds_email: userObject.email,
        ds_name_complete: userObject.name_complete,
        ds_phone: userObject.phone,
        fl_accept_terms_and_conditions: true,
        ds_origin: origin
      };
      this.redirectToPage(formData);
    }
    else{
      this.confirmationService.confirm({
        key: 'modalCaptureData',
        reject: () => {
          //Tag 12E
          dataLayer.push({
            'event': 'ga_event',
            'category': 'aliados',
            'action': 'JPW - formulario aliados',
            'label': 'cerrar',
            'aliado': ally.name
          });
        }
      });
    }
  }
}
