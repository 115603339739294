
export const environment = {
    production: true,
    url: 'https://www.jelpit.com',
    key_analitics: 'UA-129140617-1',
    apiUrl: 'https://admin-asistencias.serviciosbolivar.com/api/',
    urlImage: 'https://d2fwqvykysu662.cloudfront.net/logo_color.svg',
    urlLoginTransversal: 'https://registro.solucionesbolivarsites.com/',
    scriptLoginTranversal: 'https://registro.solucionesbolivarsites.com/javascripts/basic-login-system.js',
    portal: 'wwwjelpitcom',
    statusMetaIndexSpecific: 'noindex,nofollow',
    statusMetaGeneric: 'index, follow',
    type: 'PostalAddress',
    addressCountry: 'COL',
    postalCode: '110931',
    streetAddress: 'Avenida Calle 26',
    addressLocality: 'Bogotá',
    apiUrlPay: 'https://pagos-personas.jelpit.com/',
    apiUrlLibreria: 'https://api-libreria.solucionesbolivar.com/api/',
    googleKey: 'AIzaSyCz6e-ju5pKl_h7xm5Q6dNyc6Zx3A9MOgg',
    urlTokenPayment: 'https://api-gateway.solucionesbolivar.com/',
    userTokenPayment: { user: 'jelpit.crypt', pass: 'SjNscDF0'},
    cdnFilesStatic: 'https://d2fwqvykysu662.cloudfront.net/',
    secretKeyGateway: 'c69ec7dff3ffffeccb2f047bededcde7',
    ivGateway: 'f4db037c67154644e51e9800ec0c66db',
    sitemapCompanies: 'https://admin-empresas.jelpit.com/api/',
    ShoppingCart: { url: 'https://carrito.solucionesbolivar.com/', urlBack: 'https://api-carrito.solucionesbolivar.com/api/', token: 'wCXBY6CobPkc9LgRN587'},
    subclientGateway: 'JELPER-0001',
    clickApiKey: "undefined",
    idPortal: '5',
    urlSearcherPageResult: 'https://doqppwz46qvpv.cloudfront.net/jelpit-buscador-page-result.js',
    urlSearcherBanner: 'https://doqppwz46qvpv.cloudfront.net/jelpit-buscador-banner.js',
    urlCart: 'https://carrito.solucionesbolivar.com/elements/carrito.js',
    google_tag_manager_key: 'GTM-N7F7TW7',
    google_tag_manager_key_other: 'GTM-KBF7SJK',
    aviliableEmpresas: true,
    maintenance: false,
    ecosystemUrl:'https://admin.jelpit.com/api/ecoSystemHome',
    jelpitSetsPayments: 'https://web-conjuntos.jelpit.com/zona-publica-pagos?utm_source=Jelpit&utm_medium=Landing&utm_campaign=boton_pagos_residentes&utm_content=pagos_conjuntos_personas',
    cognitoAccesUrl: 'https://back-to-back-user-pool-jelpit-personas-prod-app.auth.us-east-1.amazoncognito.com/oauth2/token',
    extractionIdUrl: 'https://iasqerr8wd.execute-api.us-east-1.amazonaws.com/prod/v1/davivienda/extraction',
    cognitoParams: {grant_type: 'client_credentials', client_id: '6mkacn1cioed9d2bpk6nor4um6', scope: 'control-acceso-solicitudes/scope_control_acceso_jelpit' },
    cognitoAuthToken: 'Nm1rYWNuMWNpb2VkOWQyYnBrNm5vcjR1bTY6MzJvbmJldjAyaTFicDA0cnUxOWQ0bzF2ZzZyaHE2dXY5YnFmMG9jN3JodmthdDEzZzNl',
    secureInsuranceBolivar: {baseUrl: 'https://www.segurosbolivar.com', publicKey: 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANNPd0F0qftShVAjHWEanwDznV7gNF9wqXajCPtI56hbW4QtOMNxILMznoQ0UQ9s10OBjs2c7LLpykEU6is9GScCAwEAAQ==', secretKey: 'esta es la llave', processId: '3922a2ef-afe0-4726-aca6-943553f35b09'},
    loggerChannel: 'prodPersonas',
    siteApi: 'https://api-conjuntos.jelpit.com/api/v1',
    iaEndpoint: 'https://iasqerr8wd.execute-api.us-east-1.amazonaws.com/prod',
    allySection: 'Sección Aliado',
    serviceSection: 'Sección Servicio',
    generalPath: '/,/conjuntos,/conjuntos/administrador,/conjuntos/residente,/conjuntos/unirme,/preguntas-frecuentes,/conjuntos/terminos-y-condiciones,/conjuntos/politica-de-privacidad',
    registerAdmin: 'https://web-conjuntos.jelpit.com/register',
    registerResident: 'https://web-conjuntos.jelpit.com/zona-publica-registro',
    comunicaciones_url: 'https://comunicaciones-sb.serviciosbolivar.com/ley2300',
    residentialsComunicationsUrl: 'https://comunicaciones.jelpit.com/ley2300',
    administrationFeeUrl: 'https://web-conjuntos.jelpit.com/pagar-mi-administracion',
};
