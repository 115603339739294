import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  OnDestroy,
  Input,
  Inject,
  PLATFORM_ID,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './../../services/auth/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CarSumaryServiceService } from '@main-views/car-sumary/service/car-sumary-service.service';
import { ILibTbModalNotification, ILibTbSearch } from 'tech-block-lib';
import { ConfirmationService } from 'primeng/api';
import { ServicesJelpitService } from '@services/services-jelpit/services-jelpit.service';
import { environment } from '@environment/environment';

declare const dataLayer: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [CarSumaryServiceService],
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {
  private utm = 'utm_source=jelpit&utm_medium=header&utm_campaign=boton_flotante';
  private administrationFeeUrlMaster = "https://web-conjuntos.jelpit.com/pagar-mi-administracion#/";
  public administrationFeeUrl: string = `${environment.administrationFeeUrl ?? this.administrationFeeUrlMaster}${environment.production ? "?"+this.utm : ""}`;
  searchBarConfig: ILibTbSearch = {
    iconPosition: 'left',
    cornerRounded: 'on',
    size: 'large',
  };
  selectedSubmenu: any;
  nameUser: string = 'Iniciar sesión';
  /*is login*/
  show_menu_user: boolean = false;
  userIsLogin: boolean = false;
  loading: boolean[] = [false, false, false, false];
  // Administration Components children
  @Input() menuData: any = [];
  @Input() domain: string = "";

  listServicesMenu: any = [];
  listJelpitpacksMenu: any = [];
  listJelpitResidentialMenu: any = [];
  alliesMenu: any = {};
  menuNames: any = {};
  public isHome: boolean = false;
  private subscriber: Subscription;

  customModal: ILibTbModalNotification = {
    key: 'modalLogin',
    closeOnEscape: true,
    showClose: true,
    dismissableMask: true,
    acceptButtonVisible: false,
    rejectButtonVisible: false,
    class: 'modal-login',
  };

  loginModal: any = {};
  urlEnvironment: string = '';
  @ViewChild('headerDesktopWhite', { static: false })
  headerDesktopWhite: ElementRef;
  countProducts: number = 0;

  @HostListener('window:pagehide')
  doSomething() {
    this.closeModalLogin();
  }

  public envUrl : string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private router: Router,
    private carSumaryServiceService: CarSumaryServiceService,
    private jelpitService: ServicesJelpitService
  ) {
    this.envUrl = jelpitService.envUrl;
    this.validateSearch();
  }
  ngOnChanges(changes: SimpleChanges): void {
    let menuD = changes.menuData;
    if (menuD?.currentValue[0]) {
      this.buildMenuData(menuD.currentValue);
    }
  }

  /**
   * The function "buildMenuData" takes in a data object and assigns specific elements from the object
   * to different variables based on their names.
   * @param data - The `data` parameter is an array of objects. Each object represents a category in a
   * menu. Each object has a `name` property which represents the name of the category, and a
   * `sub_categories` property which is an array of objects representing sub-categories within the
   * category. Each sub-category object
   */
  buildMenuData(data) {
    data.forEach(element => {
      switch (element.name) {
        case 'Conjuntos': {
          this.listJelpitResidentialMenu = element;
          break;
        }
        case 'Personas': {
          this.loginModal = element.login_modal;
          this.menuNames = element.menu_names;
          element.sub_categories.forEach(type => {
            switch (type.name) {
              case 'Jelpit Packs': {
                this.listJelpitpacksMenu = type.categories;
                break;
              }
              case 'Servicios': {
                this.listServicesMenu = type.categories;
                break;
              }
              case 'Aliados': {
                this.alliesMenu = type;
                break;
              }
            }
          });
          break;
        }
      }
    });
  }

  /**
   * This function validates a search by subscribing to router events and checking if the current URL
   * is the home page, while also retrieving and displaying the count of products in the user's cart.
   */
  private validateSearch() {
    this.subscriber = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.loadCartData();
        if (event['url'] === '/') {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
      });
  }

  ngOnInit(): void {
    this.userIsLogin = this.authService.isLoggedIn();
    this.urlEnvironment = this.splitUrl(this.envUrl)[2];
    if (this.userIsLogin) {
      if (isPlatformBrowser(this.platformId)) {
        const dataUserLocalSotrage = JSON.parse(
          atob(localStorage.getItem('dataUser'))
        );
        this.nameUser = `Hola ${dataUserLocalSotrage.first_name}`;
      }
    }
  }

  /**
   * The function redirects the user to a specified URL or navigates to a specific route based on the
   * provided element and additional URL.
   * @param element - The "element" parameter is an object that contains information about the element
   * being clicked or selected. It typically has properties such as "name", "url", and "slug".
   * @param [additionalUrl] - The additionalUrl parameter is an optional parameter that represents an
   * additional URL segment that can be appended to the base URL.
   */
  redirectUrl(element, additionalUrl?) {
    if (element.url) {
      window.location.href = element.url;
    } else {
      if (additionalUrl)
        this.router.navigate([`${additionalUrl}${element.slug}`]);
      else this.router.navigate([`/${element.slug}`]);
    }
  }

  /**
   * The function sets a category value in the local storage.
   * @param {string} category - The category parameter is a string that represents the category being
   * used.
   */
  setCategoryUsed(category: string) {
    localStorage.setItem('categoryUsed', category);
  }

  /**
   * This function sets a data type in local storage based on a given item value.
   * @param item - The "item" parameter is a variable that represents the type of data that needs to be
   * set. If the value of "item" is 2, then the data type will be set to "jelpitpacks". Otherwise, the
   * data type will be set to "servicios".
   */
  setType(item) {
    if (isPlatformBrowser(this.platformId)) {
      if (item === 2) {
        localStorage.setItem('dataType', 'jelpitpacks');
      } else {
        localStorage.setItem('dataType', 'servicios');
      }
    }
  }

  /**
   * This function toggles a submenu and updates the header's appearance based on the user's scroll
   * position.
   * @param {number} opcion - a number representing the selected submenu option.
   */
  showMegamenu(opcion: number) {
    if (this.selectedSubmenu === opcion) {
      this.selectedSubmenu = 0;
      this.headerDesktopWhite.nativeElement.classList.remove('white_header');
    } else {
      this.selectedSubmenu = opcion;
      this.headerDesktopWhite.nativeElement.classList.add('white_header');
    }

    if (window.scrollY >= 100) {
      this.headerDesktopWhite.nativeElement.classList.add('white_header');
      this.headerDesktopWhite.nativeElement.classList.add('shadow_menu');
    }
  }

  /**
   * The function closes a mega menu and creates a data layer with selected submenu and text link
   * information.
   * @param {string} textLink - The textLink parameter is a string that represents the text of the link
   * that was clicked to close the mega menu. It is used as a parameter for the createDataLayer
   * function, which is likely a function that sends data to a tracking or analytics tool.
   */
  closeMegaMenu(textLink: string) {
    this.selectedSubmenu = 0;
  }

  /**
   * The function resets a selected submenu and adds or removes classes to a header element based on
   * the scroll position of the window.
   * @param {Event} e - The parameter "e" is an event object that is passed to the function
   * "onClickedOutside". It is likely an event that is triggered when the user clicks outside of a
   * specific element or area on the webpage. The specific type of event is not specified in the code
   * snippet.
   */
  onClickedOutside(e: Event) {
    this.selectedSubmenu = 0;
    if (window.scrollY >= 100) {
      this.headerDesktopWhite.nativeElement.classList.add('white_header');
      this.headerDesktopWhite.nativeElement.classList.add('shadow_menu');
    } else {
      this.headerDesktopWhite.nativeElement.classList.remove('white_header');
      this.headerDesktopWhite.nativeElement.classList.remove('shadow_menu');
    }
  }

  /**
   * The function hides the user menu when a click event occurs outside of it.
   * @param {Event} e - Event - this is the event object that is passed as a parameter to the function.
   * It contains information about the event that triggered the function, such as the type of event,
   * the target element, and any additional data related to the event. In this case, it is used to
   * detect when the user
   */
  onClickedOutMenu(e: Event) {
    this.show_menu_user = false;
  }

  /**
   * This function shows the user menu if they are logged in, otherwise it redirects them to the login
   * page and creates a data layer for login.
   */
  showMenuUser(): void {
    if (this.userIsLogin) {
      this.show_menu_user = !this.show_menu_user;
    } else {
      window.open(this.loginModal.url_set_login, '_self');
    }
    this.eventUserMenu('Iniciar sesión');
  }
  /**
   * The function closeModalLogin() closes a confirmation modal.
   */
  closeModalLogin() {
    this.loading = [false, false, false, false];
    this.confirmationService.close();
  }

  /**
   * The function creates a data layer floating button and pushes an event to the data layer with
   * specific category, action, and label values.
   */
  createDataLayerFloatingButton() {
    dataLayer.push({
      event: 'ga_event',
      category: 'home header',
      action: 'JPW - header boton flotante',
      label: 'pagar administracion',
    });
  }

  /**
   * The function `eventMenuTag` pushes an event to the dataLayer with specific category, action, and
   * label values.
   * @param text - The "text" parameter in the "eventMenuTag" function represents the label or text
   * associated with the menu item that triggered the event. It is used as the value for the "label"
   * property in the dataLayer.push() method.
   */
  eventMenuTag(text) {
    //47A
    dataLayer.push({
      event: 'ga_event',
      category: 'home menu',
      action: 'JPW - home menu',
      label: text,
    });
  }

  /**
   * The function `eventResidential` pushes an event to the dataLayer with specific category, action,
   * and label values.
   * @param text - The "text" parameter is a string that represents the label for the event. It is used
   * to provide additional information or context about the event being tracked.
   */
  eventResidential(text) {
    //47E
    dataLayer.push({
      event: 'ga_event',
      category: 'home menu',
      action: 'JPW - home menu conjuntos',
      label: text,
    });
  }

  /**
   * The function `eventUserMenu` pushes an event to the dataLayer with specific category, action, and
   * label values.
   * @param text - The "text" parameter is a string that represents the label for the event. It is used
   * to provide additional information or context about the event being tracked.
   */
  eventUserMenu(text) {
    //47F
    dataLayer.push({
      event: 'ga_event',
      category: 'home servicios',
      action: 'JPW - home menu flotante',
      label: text,
    });
  }

  /**
   * This function adds or removes classes from an element based on the user's scrolling behavior.
   * @param e - The parameter "e" in the function "onWindowScroll" is likely an event object that is
   * passed to the function when the window is scrolled. It may contain information about the scroll
   * event, such as the scroll position or direction. However, it is not used in the function and can
   * be removed
   */
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.scrollY >= 100) {
      this.headerDesktopWhite.nativeElement.classList.add('white_header');
      this.headerDesktopWhite.nativeElement.classList.add('shadow_menu');
    } else {
      this.headerDesktopWhite.nativeElement.classList.remove('white_header');
      this.headerDesktopWhite.nativeElement.classList.remove('shadow_menu');
    }
    if (this.selectedSubmenu) {
      this.headerDesktopWhite.nativeElement.classList.add('white_header');
    }
  }

  /**
   * The `lazyHref` function sets the `window.location.href` to the specified URL after a delay of 200
   * milliseconds.
   * @param {string} url - The `url` parameter is a string that represents the URL that you want to
   * navigate to.
   */
  lazyHref(url: string) {
    let splitUrl = this.splitUrl(url);
    if(splitUrl[2] !== this.urlEnvironment){
      setTimeout(() => {
        window.location.href = url;
      }, 500);
    }else{
      let internalPath = "";
      for(let i = 3; i<splitUrl.length; i++){
        internalPath += '/' + splitUrl[i];
      }
      this.router.navigateByUrl(internalPath);
      this.closeModalLogin();
    }
  }

  /**
   * The function `loadCartData()` retrieves the user ID from local storage and uses it to make a
   * request to the `carCount()` method of the `carSumaryServiceService` service, updating the
   * `countProducts` variable with the result.
   */
  loadCartData() {
    let idUser;
    if (localStorage.getItem('dataUser')) {
      const user = JSON.parse(atob(localStorage.getItem('dataUser')));
      idUser = user?.id;
    } else {
      idUser = localStorage.getItem('userIDNoLogin');
    }
    if (idUser) {
      this.carSumaryServiceService.carCount(idUser).subscribe(
        (res: any) => {
          this.countProducts = res.result;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  splitUrl(url: string){
    let splitUrl = url.split('/');
    return splitUrl;
  }
  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
