import { Component, Input, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

declare const dataLayer: any;

@Component({
  selector: 'app-list-cities',
  templateUrl: './list-cities.component.html',
  styleUrls: ['./list-cities.component.scss'],
})
export class ListCitiesComponent {

  @Input()
  public infoCities: any = [];

  @Input()
  public listExpand: any;
  constructor(
    @Inject(DOCUMENT) private document,
    private route:Router
  ) { }

  trackByRenderService(index: number, service: any): string {
    return service.title;
  }

  createDataLayer(title: string, infoCitiesTitle: string): void {
    if (infoCitiesTitle?.toLowerCase().trim() === 'todos los servicios'){
      // Tag 12B
      dataLayer.push({
        'event': 'ga_event',
        'category': 'servicios',
        'action': 'JPW- todos los servicios - servicio',
        'label': title
      });
    }
  }

  dataLayerExpand(title: string, infoCitiesTitle: string): void {
    if (infoCitiesTitle?.toLowerCase().trim() === 'todos los servicios'){
      // Tag 12B
      dataLayer.push({
        event: 'ga_event',
        category: 'detalle servicio',
        action: 'JPW- todos los servicios - servicio',
        label: title,
      });
    } else {
      let infoCitiesTitleLower = infoCitiesTitle?.toLowerCase().trim();
      if (typeof infoCitiesTitle !== 'undefined') {
        if (infoCitiesTitleLower.indexOf('todos los servicios') !== -1) {
          // Tag 12B
          dataLayer.push({
            event: 'ga_event',
            category: 'servicios',
            action: 'JPW- todos los servicios - menu',
            label: title,
          });
        }
      }
    }
  }

  goTo(value:string){
    const link = value?.split('/');
    if(link[3]==='ciudad'){
      this.route.navigate(['ciudad/'+link[link.length-1]]);
    }else{
      this.route.navigate([link[link.length-1]]);
    }
  }
}
