import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  private cognitoAccesUrl: string = environment.cognitoAccesUrl;

  constructor(private httpClient: HttpClient) { }

  public getAccesCognito(){
    const headers = new HttpHeaders()
    .set('Content-Type','application/x-www-form-urlencoded')
    .set('Authorization', `Basic ${environment.cognitoAuthToken}`);
    const params = environment.cognitoParams;
    return this.httpClient.post<any>(this.cognitoAccesUrl,null,{headers:headers, params:params})
  }
}
