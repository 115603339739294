import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { TcDavivienda, TcDaviviendaHomeResponse } from '../model/tc-davivienda';
import { CryptoJSAesEncrypt } from '@cryptoJs/encrypt.service';

@Injectable({
  providedIn: 'root',
})
export class TcDaviviendaService {
  url = environment.apiUrl + 'tcDavivienda';

  emmiterEventTag$ = new EventEmitter();

  constructor(
    private http: HttpClient,
    private encrypt: CryptoJSAesEncrypt,
  ) { }

  consultUser(data: TcDavivienda): Observable<any> {
    let body = {value: this.encrypt.CryptoJSAesEncrypt(JSON.stringify(data))};
    return this.http.post<any>(this.url, body);
  }

  /**
   * The function `getHome` makes an HTTP GET request to retrieve data related to the home page from a
   * specified URL.
   * @returns An Observable of type TcDaviviendaHomeResponse is being returned.
   */
  getHome(): Observable<TcDaviviendaHomeResponse> {
    return this.http.get<TcDaviviendaHomeResponse>(this.url+'Home');
  }
}
