import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalErrorPqrComponent } from '@components/modal-error-pqr/modal-error-pqr.component';
import { ModalTipsPqrComponent } from '@components/modal-tips-pqr/modal-tips-pqr.component';
import { PqrsService } from '@services/jelpit-pqrs/pqrs.service';
import Utilities from '../../utils/utilities';
import { CsrfTokenService } from '@services/csrf-token/csrf-token.service';

@Component({
  selector: 'app-form-pqrs',
  templateUrl: './form-pqrs.component.html',
  styleUrls: ['./form-pqrs.component.scss'],
})
export class FormPqrsComponent implements OnInit {
  /**
   * @description Formulario
   */
  pqrInformation: FormGroup;

  /**
   * @description Variable para el control de tipos de documentos
   */
  typeDocuments: any[];

  /**
   * @description Variable para el control de tipos de gestion
   */
  typeManagement: any[];

  /**
   * @description Variable para el control de los servicios
   */
  services: any[];

  /**
   * @description Variable para el control de los bancos
   */
  banks: any[] = [];

  /**
   * @description Variable para el control de los tipos de cuentas
   */
  typeAccount: any;

  isManagement = false;

  submitted = false;

  cantCharacters = 0;

  cantCharactersManagement = 0;

  typeField = '';

  typeFieldManagement = '';

  typePersons: any[] = [
    {
      value: 'Natural',
      name: 'Natural',
    },
    {
      value: 'Juridica',
      name: 'Jurídica',
    },
  ];
  crfToken: string | null = '';

  constructor(
    private route: Router,
    private servicePQR: PqrsService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object,
    private csrfTokenService: CsrfTokenService
  ) {}

  ngOnInit() {
    this.crfToken = this.csrfTokenService.generateCsrfToken(40);
    if (isPlatformBrowser(this.platformId)){
      if (!localStorage.getItem('reset-css')) {
        localStorage.setItem('reset-css', 'no reload');
        location.reload();
      } else {
        localStorage.removeItem('reset-css');
      }
    }
    this.servicePQR.getIdentificationType().subscribe((response) => {
      this.typeDocuments = response.result;
    });
    this.servicePQR.getTypesTicket().subscribe((response) => {
      this.typeManagement = response.result;
    });
    this.servicePQR.getServiceTickets().subscribe((response) => {
      this.services = response.result;
    });
    this.createForm();
  }

  /**
   * This function creates a form with various form controls and validators, including disabling a
   * specific control.
   */
  createForm() {
    this.pqrInformation = this.formBuilder.group({});
    this.pqrInformation.addControl(
      '_csrf',
      new FormControl(this.crfToken, [Validators.required])
    );
    this.pqrInformation.addControl(
      'nameComplete',
      new FormControl('', [Validators.required])
    );
    this.pqrInformation.addControl(
      'numberDoc',
      new FormControl('', Validators.required)
    );
    let emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    this.pqrInformation.addControl(
      'email',
      new FormControl('', [
        Validators.required,
        Validators.pattern(emailPattern),
      ])
    );
    this.pqrInformation.addControl(
      'typeDoc',
      new FormControl('', Validators.required)
    );
    this.pqrInformation.addControl(
      'confirmEmail',
      new FormControl('', [
        Validators.required,
        Validators.pattern(emailPattern),
        this.EmailValidator('email'),
      ])
    );
    this.pqrInformation.addControl(
      'cellphone',
      new FormControl('3', [
        Validators.required,
        Validators.pattern('[3][0-9]+'),
        Validators.maxLength(10),
        Validators.minLength(10),
      ])
    );
    this.pqrInformation.addControl(
      'managementType',
      new FormControl('', Validators.required)
    );
    this.pqrInformation.addControl(
      'serviceHide',
      new FormControl('', Validators.required)
    );
    this.pqrInformation.addControl(
      'detailApplication',
      new FormControl('', [Validators.required, Validators.maxLength(2000)])
    );
    this.pqrInformation.controls['numberDoc'].disable();
  }

  /**
   * This function updates the validators, character limit, and type field for a numeric document form
   * input.
   */
  updateNumberDocForm() {
    this.numberDoc.setValidators([
      Validators.required,
      Validators.pattern('[0-9]+'),
      Validators.maxLength(20),
    ]);
    this.cantCharacters = 20;
    this.typeField = 'numérico';
    this.numberDoc.updateValueAndValidity();
  }

  /**
   * This function updates the validation rules and input field properties based on the selected type
   * of document.
   * @param {any} event - The parameter "event" is of type "any", which means it can be any type of
   * data. It is likely an event object that is passed as an argument to the "selectTypeDocument"
   * function. The function uses the "name" property of the event object to determine which case to
   * execute
   */
  selectTypeDocument(event: any) {
    this.pqrInformation.enable();
    this.numberDoc.setValue('');
    this.numberDoc.clearValidators();
    switch (event.name) {
      case 'Cédula de ciudadanía':
        this.updateNumberDocForm();
        break;
      case 'Cédula de extranjería':
        this.updateNumberDocForm();
        break;
      case 'Nit':
        this.numberDoc.setValidators([
          Validators.required,
          Validators.pattern('[0-9]+'),
          Validators.maxLength(9),
          Validators.minLength(9),
        ]);
        this.cantCharacters = 9;
        this.typeField = 'numérico';
        this.numberDoc.updateValueAndValidity();
        break;
      case 'Pasaporte':
        this.numberDoc.setValidators([
          Validators.required,
          Validators.pattern('[A-Za-z0-9]+'),
          Validators.maxLength(191),
        ]);
        this.cantCharacters = 191;
        this.typeField = 'alfaNumérico';
        this.numberDoc.updateValueAndValidity();
        break;
      default:
        this.numberDoc.setValidators([Validators.required]);
        this.numberDoc.updateValueAndValidity();
        break;
    }
  }

  /**
   * This function updates the validators, character limit, and type of a numeric field in a document
   * management form.
   */
  updatenumberDocManagementForm() {
    this.numberDocManagement.setValidators([
      Validators.required,
      Validators.pattern('[0-9]+'),
      Validators.maxLength(20),
    ]);
    this.cantCharactersManagement = 20;
    this.typeFieldManagement = 'numérico';
    this.numberDocManagement.updateValueAndValidity();
  }

  /**
   * This function updates the validation rules and input field properties based on the selected
   * document type.
   * @param {any} event - The parameter "event" is of type "any", which means it can be any type of
   * data. It is likely an event object that is passed as an argument to the function
   * "selectTypeDocManagament". The function uses the "name" property of the event object to determine
   * which case
   */
  selectTypeDocManagament(event: any) {
    this.pqrInformation.enable();
    this.numberDocManagement.setValue('');
    this.numberDocManagement.clearValidators();
    this.numberDocManagement.updateValueAndValidity();
    switch (event.name) {
      case 'Cédula de ciudadanía':
        this.updatenumberDocManagementForm();
        break;
      case 'Cédula de extranjería':
        this.updatenumberDocManagementForm();
        break;
      case 'Nit':
        this.numberDocManagement.setValidators([
          Validators.required,
          Validators.pattern('[0-9]+'),
          Validators.maxLength(9),
          Validators.minLength(9),
        ]);
        this.cantCharactersManagement = 9;
        this.typeFieldManagement = 'numérico';
        this.numberDocManagement.updateValueAndValidity();
        break;
      case 'Pasaporte':
        this.numberDocManagement.setValidators([
          Validators.required,
          Validators.pattern('[A-Za-z0-9]+'),
          Validators.maxLength(191),
        ]);
        this.cantCharactersManagement = 191;
        this.typeFieldManagement = 'alfaNumérico';
        this.numberDocManagement.updateValueAndValidity();
        break;
      default:
        this.numberDocManagement.setValidators([Validators.required]);
        this.numberDocManagement.updateValueAndValidity();
        break;
    }
  }

  /**
   * The function checks if the length of a phone number is less than or equal to 1 and sets it to '3'
   * if it is.
   */
  checkPhoneNumber() {
    if (this.cellphone?.value?.length <= 1) {
      this.cellphone.setValue('3');
    }
  }

  /**
   * The function selects a type of management and adds or removes controls accordingly.
   * @param {any} event - The parameter "event" is of type "any" and is used to capture the event
   * object that is triggered when a certain action is performed, such as a button click or a dropdown
   * selection. The function "selectTypeManagement" takes this event object as input and checks its
   * "name" property to
   */
  selectTypeManagement(event: any) {
    if (event.name === 'Devolución') {
      this.isManagement = true;
      this.addControls();
    } else {
      this.isManagement = false;
      this.removeControls();
    }
  }

  openTips() {
    this.pendingForm();
    this.dialog.open(ModalTipsPqrComponent, {
      panelClass: ['full-modal', 'modal-address'],
      width: '485px',
      autoFocus: false,
    });
  }

  /**
   * The function disables or enables a form field based on the selected bank name.
   * @param {any} event - The parameter "event" is of type "any", which means it can be any type of
   * data. It is likely an object that contains information about a bank selection event, such as the
   * name of the selected bank. The function "selectedBank" uses this information to update the
   * validation rules for a
   */
  selectedBank(event: any) {
    if (
      event.name.toUpperCase() === 'NEQUI' ||
      event.name.toUpperCase() === 'DAVIPLATA'
    ) {
      this.pqrInformation.get('accountType').clearValidators();
      this.pqrInformation.get('accountType').disable();
      this.pqrInformation.get('accountType').updateValueAndValidity();
    } else {
      this.pqrInformation.get('accountType').setValidators(Validators.required);
      this.pqrInformation.get('accountType').enable();
      this.pqrInformation.get('accountType').updateValueAndValidity();
    }
  }

  addControls() {
    this.servicePQR.getBanks().subscribe((response) => {
      this.banks = response.result;
    });
    this.servicePQR.getTypesAccountBank().subscribe((response) => {
      this.typeAccount = response.result;
    });
    this.pqrInformation.addControl(
      'bank',
      new FormControl('', Validators.required)
    );
    this.pqrInformation.addControl(
      'accountType',
      new FormControl('', Validators.required)
    );
    this.pqrInformation.addControl(
      'accountNumber',
      new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]+'),
        Validators.maxLength(16),
      ])
    );
    this.pqrInformation.addControl(
      'typeDocument',
      new FormControl('', Validators.required)
    );
    this.pqrInformation.addControl(
      'numberDocManagement',
      new FormControl('', Validators.required)
    );
    this.pqrInformation.addControl(
      'nameHeadline',
      new FormControl('', Validators.required)
    );
    this.pqrInformation.addControl(
      'typePerson',
      new FormControl('', Validators.required)
    );
    this.pqrInformation.controls['numberDocManagement'].disable();
  }

  /**
   * The function removes specific controls from a form.
   */
  removeControls() {
    this.pqrInformation.removeControl('bank');
    this.pqrInformation.removeControl('accountType');
    this.pqrInformation.removeControl('accountNumber');
    this.pqrInformation.removeControl('typeDocument');
    this.pqrInformation.removeControl('numberDocManagement');
    this.pqrInformation.removeControl('nameHeadline');
    this.pqrInformation.removeControl('typePerson');
  }

  sendData() {
    this.enableForm();
    if (this.pqrInformation.valid) {
      let info = this.updateModel(this.pqrInformation.value);
      this.servicePQR.sendPqr(info).subscribe(
        (response) => {
          localStorage.setItem('tempTicketPQR', response.result);
          this.route.navigate(['/pqrs/confirm-pqr']);
        },
        (error) => {
          this.dialog.open(ModalErrorPqrComponent, {
            panelClass: ['full-modal', 'modal-address'],
            width: '485px',
            autoFocus: false,
          });
        }
      );
    } else {
      this.pqrInformation.markAllAsTouched();
      setTimeout(() => {
        this.scrollToError();
      }, 100);
    }
  }

  EmailValidator(confirmEmailInput: string) {
    let confirmEmailControl: FormControl;
    let emailControl: FormControl;

    return (control: FormControl) => {
      if (!control.parent) {
        return null;
      }

      if (!confirmEmailControl) {
        confirmEmailControl = control;
        emailControl = control.parent.get(confirmEmailInput) as FormControl;
        emailControl.valueChanges.subscribe(() => {
          confirmEmailControl.updateValueAndValidity();
        });
      }

      if (
        emailControl.value.toLocaleLowerCase() !==
        confirmEmailControl.value.toLocaleLowerCase()
      ) {
        return { notMatch: true };
      }

      return null;
    };
  }

  /**
   * @description metodo para configurar el modelo de datos.
   * @param model datos que recibe para agregar al modelo
   * @returns retorna el modelo
   */
  updateModel(model: any) {
    let data = {
      full_name: model?.nameComplete,
      identification_number: model?.numberDoc,
      phone: parseInt(model?.cellphone),
      email: model?.email,
      email_confirmation: model?.confirmEmail,
      service_name: model?.serviceHide.name,
      description: model?.detailApplication,
      identification_type_id: model?.typeDoc.id,
      ticket_type_id: model?.managementType
        ? model?.managementType.id
        : undefined,
      person_type: model?.typePerson,
      name_owner_account_bank: model?.nameHeadline,
      identification_number_info_refund: model?.numberDocManagement,
      bank_id: model?.bank ? model?.bank.id : undefined,
      bank_account_type_id: model?.accountType,
      identification_type_id_info_refund: model?.typeDocument
        ? model?.typeDocument.id
        : undefined,
      number_account_bank: model?.accountNumber,
      _csrf: model?._csrf
    };
    return data;
  }

  /**
   * El metodo scrollToError seleciona el primer elemento del form que tiene un error y trae su FormControlName
   * y ejecuta el metodo ScrollTo hacia el elemento que tiene el error
   */
  scrollToError(): void {
    const firstElementWithError: HTMLElement = document.querySelector(
      '.ng-invalid[formControlName]'
    );
    let u = new Utilities();
    u.getTopOffset(firstElementWithError);
  }

  pendingForm() {
    this.nameComplete.markAsPending();
    this.numberDoc.markAsPending();
    this.typeDoc.markAsPending();
    this.email.markAsPending();
    this.confirmEmail.markAsPending();
    this.cellphone.markAsPending();
    this.managementType.markAsPending();
    this.detailApplication.markAsPending();
    this.bank?.markAsPending();
    this.accountNumber?.markAsPending();
    this.typeDocument?.markAsPending();
    this.numberDocManagement?.markAsPending();
    this.nameHeadline?.markAsPending();
    this.serviceHide?.markAsPending();
    this.accountType?.markAsPending();
    this.typePerson?.markAsPending();
  }

  enableForm() {
    this.nameComplete.updateValueAndValidity();
    this.numberDoc.updateValueAndValidity();
    this.typeDoc.updateValueAndValidity();
    this.email.updateValueAndValidity();
    this.confirmEmail.updateValueAndValidity();
    this.cellphone.updateValueAndValidity();
    this.managementType.updateValueAndValidity();
    this.detailApplication.updateValueAndValidity();
    this.bank?.updateValueAndValidity();
    this.accountNumber?.updateValueAndValidity();
    this.typeDocument?.updateValueAndValidity();
    this.numberDocManagement?.updateValueAndValidity();
    this.nameHeadline?.updateValueAndValidity();
    this.serviceHide?.updateValueAndValidity();
    this.accountType?.updateValueAndValidity();
    this.typePerson?.updateValueAndValidity();
  }

  get typePerson() {
    return this.pqrInformation.get('typePerson');
  }

  get numberDoc() {
    return this.pqrInformation.get('numberDoc');
  }

  get nameComplete() {
    return this.pqrInformation.get('nameComplete');
  }

  get typeDoc() {
    return this.pqrInformation.get('typeDoc');
  }

  get email() {
    return this.pqrInformation.get('email');
  }

  get confirmEmail() {
    return this.pqrInformation.get('confirmEmail');
  }

  get cellphone() {
    return this.pqrInformation.get('cellphone');
  }

  get managementType() {
    return this.pqrInformation.get('managementType');
  }

  get detailApplication() {
    return this.pqrInformation.get('detailApplication');
  }

  get bank() {
    return this.pqrInformation.get('bank');
  }

  get accountNumber() {
    return this.pqrInformation.get('accountNumber');
  }

  get typeDocument() {
    return this.pqrInformation.get('typeDocument');
  }

  get numberDocManagement() {
    return this.pqrInformation.get('numberDocManagement');
  }

  get nameHeadline() {
    return this.pqrInformation.get('nameHeadline');
  }

  get serviceHide() {
    return this.pqrInformation.get('serviceHide');
  }

  get accountType() {
    return this.pqrInformation.get('accountType');
  }
}
