import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class CryptoJSAesDecrypt {

  /**
   * The function decrypts an AES encrypted JSON string using a PBKDF2 derived key.
   * @param encrypted_json_string - The parameter `encrypted_json_string` is a string that represents
   * an encrypted JSON object.
   * @returns a decrypted string in UTF-8 format.
   */
  cryptoJSAesDecrypt(encrypted_json_string) {
    let obj_json = JSON.parse(atob(encrypted_json_string));

    let encrypted = obj_json.value;
    let salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    let iv = CryptoJS.enc.Hex.parse(obj_json.iv);

    let key = CryptoJS.PBKDF2('bebfa47a9bf87f22d49ee5193305c736', salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: 64 / 8,
      iterations: 999,
    });

    let decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      mode: CryptoJS.mode.CTR,
      iv: iv,
      padding: CryptoJS.pad.NoPadding,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
