import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { Routes, RouterModule, Router, NavigationStart, ActivatedRoute, Params } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

// Guards
import { MetaGuard } from '@guards/meta-guards/meta-guard.service';

// Components
import { MaintenanceComponent } from '@main-views/pages-error/maintenance/maintenance.component';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { UseDaviviendaCardsComponent } from '@main-views/tc-davivienda/use-davivienda-cards/use-davivienda-cards.component';
import { environment } from '@environment/environment';
import { MainLayoutComponent } from '@layouts/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'login',
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            './main-views/login-transversal/login-transversal.module'
          ).then((m) => m.LoginTransversalModule),
      },
    ],
  },
  {
    path: 'register',
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            './main-views/login-transversal/register-transversal.module'
          ).then((m) => m.RegisterTransversalModule),
      },
    ],
  },
  {
    path: 'servicios-complementarios',
    loadChildren: () =>
      import('./main-views/ecosystem-home/ecosystem-home.module').then(
        (m) => m.EcosystemHomeModule
      ),
  },
  {
    path: 'alianzas/davivienda/beneficios-tarjeta-credito',
    loadChildren: () => import('./main-views/tc-davivienda/tc-davivienda.module').then(m => m.TcDaviviendaModule)
  },
  {
    path: 'alianzas/davivienda/beneficios-tarjeta-credito/servicios',
    component: UseDaviviendaCardsComponent,
    data: {
      meta: {
        robots: environment.statusMetaGeneric
      }
    }
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivateChild: [MetaGuard],
    children: [
      {
        path: 'conjuntos',
        loadChildren: () =>
          import('./main-views/jelpit-residential/jelpit-conjuntos.module').then(
            (m) => m.JelpitConjuntosModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./main-views/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'legal',
        loadChildren: () =>
          import('./main-views/legal/legal.module').then((m) => m.LegalModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('./main-views/categories/categories.module').then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./main-views/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'aliados',
        loadChildren: () => import('./main-views/allies/allies.module').then(m => m.AlliesModule)
      },
      {
        path: 'aliados/:id',
        loadChildren: () => import('./main-views/allies/allies.module').then(m => m.AlliesModule)
      },
      {
        path: 'verificar/:token',
        loadChildren: () =>
          import('./main-views/login-transversal/verify-token.module').then(
            (m) => m.VerifyTokenModule
          ),
      },
      {
        path: 'confirmacion',
        loadChildren: () =>
          import('./main-views/confirmation-payment/confirmation.module').then(
            (m) => m.ConfirmationModule
          ),
      },
      {
        path: 'carrito-de-compras',
        loadChildren: () =>
          import('./main-views/car-sumary/car-sumary.module').then(
            (m) => m.CarSumaryModule
          ),
      },
      {
        path: 'registro-de-proveedores',
        loadChildren: () =>
          import('./main-views/work-with-us/work-with-us.module').then(
            (m) => m.WorkWithUsModule
          ),
      },
      {
        path: 'preguntas-frecuentes',
        loadChildren: () =>
          import('./main-views/pqr/pqr.module').then((m) => m.PqrModule),
      },
      {
        path: 'pqrs',
        loadChildren: () =>
          import('./main-views/pqr-and-follow/pqr-and-follow.module').then(
            (m) => m.PqrAndFollowModule
          ),
      },
      {
        path: 'ciudad',
        loadChildren: () =>
          import('./main-views/detail-city/detail-city.module').then(
            (m) => m.DetailCityModule
          ),
      },
      {
        path: '404',
        loadChildren: () => import('./main-views/pages-error/pages-error.module').then(m => m.PagesErrorModule),
      },
      {
        path: ':id',
        loadChildren: () =>
          import(
            './main-views/new-detail-service/new-detail-service.module'
          ).then((m) => m.NewDetailServiceModule),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./main-views/pages-error/pages-error.module').then(
            (m) => m.PagesErrorModule
          ),
      },
    ],
  },
  {
    path: 'pqr-and-follow',
    loadChildren: () =>
      import('./main-views/pqr-and-follow/pqr-and-follow.module').then(
        (m) => m.PqrAndFollowModule
      ),
  },
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: 'enabledNonBlocking',
    }),
    // MetaModule.forRoot(),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {


  /**
   * It takes the URL, removes all special characters, and then redirects to the new URL
   * @param {Router} router - Router - This is the Angular Router service.
   * @param {Object} platformId - Object - This is the platform ID that Angular uses to determine if
   * the app is running in the browser or not.
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    let originalURL: string;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        originalURL = event.url;
        originalURL = decodeURIComponent(originalURL);
        let newURL: string = originalURL
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '')
          .replace(/[!¡“”"#%‘()+; .:,<>^~{}]/g, '')
          .replace(/\[/g, '')
          .replace(/\]/g, '')
          .replace(/&amp;/g, '&');
        if (newURL !== originalURL) {
          if (newURL.includes('utm')) {
            this.setUTM(newURL, true);
          }
          else {
            let splitUrl = newURL.split('?');
            let params = splitUrl[splitUrl.length - 1].split('&');
            let newParams: Params = {};
            params.forEach((param) => {
              let keyValue = param.split('=');
              newParams[keyValue[0]] = keyValue[1];
            });
            this.router.navigate([splitUrl[0]], { queryParams: newParams });
          }
        } else if (newURL.includes('utm')) {
          this.setUTM(newURL, false);
        }
      });
    this.readIdQueryParam();
  }

  /**
   * It takes a URL, splits it into segments, checks if any of the segments contain the string "utm",
   * and if so, stores the segment in local storage
   * @param {string} url - The URL you want to check for UTM parameters.
   * @param {boolean} redirect - boolean - If you want to redirect to the same page with the query
   * parameters, set this to true.
   */
  private setUTM(url: string, redirect: boolean) {
    const validateUrl = url.split('/');
    const validateSegment = validateUrl[1]?.split('?');
    let search = {},
      urlSegment = '',
      segmentParams = '';
    if (validateSegment) {
      if (validateSegment[0]?.includes('utm')) {
        search = JSON.parse(
          '{"' +
          decodeURI(validateSegment[0])
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
        );
        segmentParams = validateSegment[0];
      }
      if (validateSegment[1]?.includes('utm')) {
        search = JSON.parse(
          '{"' +
          decodeURI(validateSegment[1])
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
        );
        urlSegment = validateSegment[0];
        segmentParams = validateSegment[1];
      }
      if (
        validateSegment[0]?.includes('utm') ||
        validateSegment[1]?.includes('utm')
      ) {
        localStorage.setItem('QueryParams', segmentParams);
        if (redirect)
          this.router.navigate(['/' + urlSegment], { queryParams: search });
      } else if (isPlatformBrowser(this.platformId)) {
        localStorage.removeItem('QueryParams');
      }
    }
  }

  private readIdQueryParam() {
    this.route.queryParams.subscribe(params => {
      const id = params.id;
      if (id) {
        localStorage.setItem('tokenIdQueryParam', id)
      } else {
        localStorage.setItem('tokenIdQueryParam', '')
      }
    });

  }
}
