import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HeadersService } from '../headers/headers.service';
import { isPlatformBrowser } from '@angular/common';
import { CryptoJSAesEncrypt } from '@cryptoJs/encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public apiUrl: string = environment.apiUrl;
  public portal: string = environment.portal;
  public apiUrlLoginTransversal: string = environment.urlLoginTransversal;

  public user: any;

  private decodeTokenService: string = this.apiUrl + "decode";
  private changePasswordService: string = this.apiUrl + "reset";
  private changePasswordAssitantService: string = this.apiUrlLoginTransversal + "login-system/" + this.portal + "/forgot-password";
  private verifyTokenService: string = this.apiUrlLoginTransversal + "login-system/" + this.portal + "/verify";
  private registerPopupService: string = this.apiUrlLoginTransversal + "login-system/v2/sign-up/" + this.portal;
  private verifyTokenEmailService: string = this.apiUrl + "validate/";
  private verifyUserService: string = this.apiUrl + "basic_service/validEmail";
  private renewTokenService: string = this.apiUrlLoginTransversal + "login-system/" + this.portal + "/refresh";
  private refreshTokenService: string = this.apiUrlLoginTransversal + "login-system/" + this.portal + "/refresh";
  private updatePasswordTransversalService: string = this.apiUrlLoginTransversal + "login-system/" + this.portal + "/update-password"
  private sendMailResetPasswordService: string = this.apiUrl + "restore";
  private sendUseTermService: string = this.apiUrl + "userTerm";
  private loginTransversalService: string = this.apiUrl + "login"

  constructor(
    private http: HttpClient,
    private _headersService: HeadersService,
    @Inject(PLATFORM_ID) private platformId: object,
    private encrypt: CryptoJSAesEncrypt,
  ) { }

  setValidateTokenLogin(): Observable<any> {
    const url = `${this.apiUrl}valid-token`;
    return this.http.post<any>(url, {});
  }

  sendMailResetPassword(email: string) {
    return this.http.post(this.sendMailResetPasswordService, { "email": email });
  }

  loginSession(): Observable<any> {
    let headers;
    let email ;

    if (isPlatformBrowser(this.platformId)) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem("token-node") });
      email = this.encrypt.CryptoJSAesEncrypt(JSON.stringify({email: localStorage.getItem("email")}));
    }
    return this.http.post(this.loginTransversalService, { value: email }, { headers });
  }

  verifyToken(): Observable<any> {
    return this.http.post(this.verifyTokenService, {}, { headers: this._headersService.getHttpHeadersNotificationToken() });
  }

  registerUser(user: any, token: string, uid: string): Observable<any> {
    const url = `${this.apiUrl}register`;
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'x-firebase-uid': uid });
    return this.http.post(url,user, { headers: headers });
  }

  setAdditionalData(data: any): Observable<any> {
    const url = `${this.apiUrl}personal-information/update`;
    return this.http.put<any>(url, data);
  }
   /**
   * se envia ga y gid como client id
   * @param clientId  ga y gid
   * @returns respuesta del server
   */
    sendClientId(clientId: object):Observable<any>{
      const url = `${this.apiUrl}personal-information/ga-fields`;
      return this.http.put<any>(url, clientId);
    }

  verifyTokenEmail(token) {
    return this.http.post(this.verifyTokenEmailService + token, {});
  }

  refreshToken(uid) {
    return this.http.post(this.refreshTokenService, {}, { headers: this._headersService.getHttpHeadersRefreshToken(uid) }).pipe(
      catchError((error, caught) => {
        return of(error);
      }) as any
    );
  }

  updatePasswordTransversal(token, password) {
    return this.http.post(this.updatePasswordTransversalService, { password }, { headers: this._headersService.getHttpHeaderUpdatePassword(token) }).pipe(
      catchError((error, caught) => {
        return of(error);
      }) as any
    );
  }

  changePassword(token) {
    return this.http.post(`${this.changePasswordService}/${token}`, {}).pipe(
      catchError((error, caught) => {
        return of(error);
      }) as any
    );
  }

  getUserData(): Observable<any> {
    const url = `${this.apiUrl}personal-information`;
    return this.http.get<any>(url);
  }

  registerUserPopup(user: any): Observable<any> {
    return this.http.post(this.registerPopupService, user);
  }
}
