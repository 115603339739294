<div class="container-desktop">
  <div
    *ngIf="enableChatAI && domain.includes('jelpit')"
    class="container-desktop__icon"
    (click)="toggleChat()"
  >
    <i class="fa-light fa-message-smile"></i>
    <span>Chat MIA</span>
  </div>
  <div
    class="container-desktop__chat"
    [@openClose]="showChat ? 'open' : 'close'"
  >
    <app-chat-ai
      [setLoadMessages]="showChat"
      [enableSpeech2text]="enableMicInput"
      [enableThumbButtons]="enableThumbs"
    ></app-chat-ai>
  </div>
</div>
<div
  class="container-mobile"
  id="container-mobile"
  [@openCloseMobile]="showChatMobile ? 'open' : 'close'"
>
  <div class="container-mobile__header">
    <div class="container-mobile__header__controller" id="mobile-controller">
      <div class="container-mobile__header__controller__select"></div>
    </div>
    <img
      (click)="closeModalMobile()"
      class="container-mobile__header__close"
      src="assets/img/iconos/icon_close.svg"
      alt="send"
    />
  </div>
  <app-chat-ai
    [setLoadMessages]="showChatMobile"
    [enableSpeech2text]="enableMicInput"
    [enableThumbButtons]="enableThumbs"
  ></app-chat-ai>
</div>
<div class="container-lower-menu">
  <ul class="container-lower-menu__icons" [ngClass]="{'assistances': !domain.includes('asistencias')}">
    <li (click)="setDataLayerCar()" *ngIf="services.length > 0 && domain.includes('asistencias')">
      <a
        class="item_link"
        routerLink="/carrito-de-compras"
        data-qa-id="JP-P-SH-M-aref_go_shopping_cart"
      >
        <app-car-button-sumary
          [countProducts]="countProducts"
        ></app-car-button-sumary>
        <span class="txt_link">Carrito</span>
      </a>
    </li>
    <li *ngIf="domain.includes('jelpit')">
      <a
        class="item_link"
        (click)="showMenuUser()"
        data-qa-id="JP-P-SH-M-aref_show_user_menu"
      >
        <i class="fa-light fa-user"></i>
        <span class="txt_link">{{ nameUser }}</span>
      </a>
      <div id="menu_user" class="menu_user">
        <app-menu-user></app-menu-user>
      </div>
    </li>
    <li *ngIf="enableChatAI && domain.includes('jelpit')">
      <a class="item_link" (click)="toggleChatMobile()">
        <i class="fa-light fa-message-smile"></i>
        <span class="txt_link">Chat MIA</span>
      </a>
    </li>
  </ul>
</div>
