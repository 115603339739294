import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { BonoModel } from '../models/bonoModel';
import { PaymentCart } from '../models/paymentCart';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CarSumaryServiceService {
  url = environment.apiUrl;
  _listners = new Subject<any>();

  constructor(private httpClient: HttpClient) {}

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  setAction() {
    this._listners.next();
  }

  detailCart(id: string) {
    return this.httpClient.get<any>(this.url + 'cart/shopping/' + id);
  }

  getConfigExpiration() {
    return this.httpClient.get<any>(this.url + 'cart/portal/config-expiration');
  }

  deleteProduct(id: any) {
    return this.httpClient.delete(
      this.url + 'cart/shopping/product-remove/' + id
    );
  }

  confirmCart(model: PaymentCart) {
    return this.httpClient.post(this.url + 'cart/shopping/confirm', model);
  }

  applyBono(modelBono: BonoModel) {
    return this.httpClient.post(
      this.url + 'cart/shopping/apply-bonos',
      modelBono
    );
  }

  getTopSales(params: any) {
    return this.httpClient.post(this.url + 'cart/products/top-sales', params);
  }

  getConfigPortal() {
    return this.httpClient.get(this.url + 'cart/portal/detail');
  }

  carCount(id: string) {
    return this.httpClient.get(this.url + 'cart/shopping/products-count/' + id);
  }
}
