<div class="dialog-location map-modal">
    <h2 mat-dialog-title class="title">Confirmar dirección</h2>
    <span class="icon-close" mat-dialog-close></span>
    <div mat-dialog-content>
        <div class="content-text">
            <p class="subtitle">Usa el ícono para ubicar la dirección correcta en el mapa.</p>
            <form [formGroup]="addressDataForm" autocomplete="off">
                <div class="grid">
                    <div class="form_item grid-12">
                        <div id="mapGoogle" style="width:100%;height:370px;"></div>
                    </div>
                    <div class="form_item grid-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Dirección donde necesitas el servicio</mat-label>
                            <input type="text" #txtAutocompleteMaps id="txtAutocompleteMaps"
                                placeholder="Ej: Calle 26 # 69 - 01" autocorrect="off" autocapitalize="off"
                                spellcheck="false" (change)="setAddresValue($event.target.value)" matInput>
                        </mat-form-field>
                        <p class="error" *ngIf="submitted && addressDataForm.get('address').errors">Ingresa una
                            dirección</p>
                        <p class="error-gray" *ngIf="showErrorAdrresFound === 'false'">Ayúdanos a ubicar tu dirección usando el ícono en
                            el mapa o seleccionando alguna recomendación de la lista.</p>
                        <span class="icon-add-user-button1" onkeypress="" (click)="txtAutocompleteMaps.focus()"></span>
                    </div>
                    <div class="form_item grid-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Complemento de la dirección</mat-label>
                            <input type="text" formControlName="complementAddress"
                                placeholder='Ej: "Apartamento 290, torre 2 Edificio Centro"' matInput>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions>
        <button class="btn-primary" (click)="sendAddressInformation()">CONFIRMAR UBICACIÓN</button>
    </mat-dialog-actions>
</div>