<div class="mobile_menu_button">
  <a
    class="item_link"
    (click)="showMenu()"
    data-qa-id="JP-P-SH-M-aref_open_menu"
  >
    <i class="fa-solid fa-bars"></i>
  </a>
</div>
<div class="container_mobile_m" id="menu_item">
  <div class="header_menu">
    <div class="logo">
      <a
        routerLink="/"
        queryParamsHandling="preserve"
        (click)="closeMenu('home')"
        class="logo_color"
      >
        <picture>
          <img
            defaultImage="./assets/img/gif/loading-small.gif"
            lazyLoad="../../../../assets/img/brand/logo_jelpit_color.svg"
            alt="jelpit"
          />
        </picture>
      </a>
    </div>
    <div class="close_icon">
      <a (click)="closeMenu('cerrar menu')">
        <i class="fa-light fa-xmark"></i>
      </a>
    </div>
  </div>
  <div class="content_menus">
    <div class="menu_category">
      <ul *ngIf="dataMenu.length > 0">
        <li class="list_item">
          <a href="/" routerLink="/" (click)="closeMenu('home')">
            <i class="fa-light fa-house-blank"></i>
            Inicio
          </a>
        </li>
        <li class="list_item" *ngIf="listJelpitResidentialMenu.name">
          <div class="dropdown" (click)="toggleList(drop)">
            <i class="fa-light fa-circle-dollar"></i>
            <p>{{ listJelpitResidentialMenu.name }}</p>
            <i class="fa-light fa-angle-down"></i>
          </div>
          <div class="dropdown_content" #drop>
            <a
              [href]="option.url"
              *ngFor="let option of listJelpitResidentialMenu.options"
              (click)="
                closeMenu(listJelpitResidentialMenu.name);
                eventResidential(option.name)
              "
              >{{ option.name }}</a
            >
          </div>
        </li>
        <li class="list_item" *ngIf="listServicesMenu.categories">
          <div class="dropdown" (click)="toggleList(drop)">
            <i class="fa-light fa-pump-soap"></i>
            <p>{{ menuNames.service ?? listServicesMenu.name }}</p>
            <i class="fa-light fa-angle-down"></i>
          </div>
          <div class="dropdown_content" #drop>
            <a
              (click)="redirectUrl(option, listServicesMenu.name); setType(1)"
              *ngFor="let option of listServicesMenu.categories"
              >{{ option.name }}</a
            >
          </div>
        </li>
        <li class="list_item" *ngIf="listJelpitpacksMenu.categories">
          <div class="dropdown" (click)="toggleList(drop)">
            <i class="fa-light fa-basket-shopping"></i>
            <p>{{ listJelpitpacksMenu.name }}</p>
            <i class="fa-light fa-angle-down"></i>
          </div>
          <div class="dropdown_content" #drop>
            <a
              (click)="
                redirectUrl(option, listJelpitpacksMenu.name); setType(2)
              "
              *ngFor="let option of listJelpitpacksMenu.categories"
              >{{ option.name }}</a
            >
          </div>
        </li>
        <li class="list_item" *ngIf="alliesMenu.name">
          <a
            [href]="alliesMenu.slug"
            [routerLink]="alliesMenu.slug"
            (click)="closeMenu(alliesMenu.name)"
          >
            <i class="fa-light fa-handshake"></i>
            {{ alliesMenu.name }}
          </a>
        </li>
        <li class="list_item" *ngIf="false">
          <a
            href="/blog"
            target="_blank"
            rel="noopener nofollow"
            (click)="closeMenu('Blog')"
          >
            <i class="fa-light fa-newspaper"></i>
            Blog
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="floating_btn" showOnlyOnHome>
  <a
    href="{{ administrationFeeUrl }}"
    (click)="createDataLayerFloatingButton()"
  >
    <img
      src="../../../../assets/img/home/floating_residential_jelpit.svg"
      alt="pagar administracion jelpit"
    />
  </a>
</div>
<div class="background"></div>
