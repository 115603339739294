<mat-dialog-content>
  <div class="content-modal">
    <div class="header-modal">
      <a mat-dialog-close class="close-modal"
        ><span class="icon-close"></span
      ></a>
    </div>
    <div>
      <ng-container>
        <div class="modal-title">
          Conozca los tipos de solicitudes que puede radicar
        </div>
      </ng-container>
      <ng-container>
        <div class="modal-section-title">
          Queja/Reclamo
          <p class="modal-section-contents">
            Manifestación de una inconformidad frente a ofertas publicadas en la plataforma de contacto con respecto a la compra de servicios con nuestros aliados. 
          </p>
            <ul>
              <li>
                Para irregularidades en la prestación de servicios y exigir una solución a una atención inadecuada la Queja/Reclamo será redireccionada a los canales de atención de los aliados (prestadores de los servicios) correspondientes. 
              </li>
            </ul> 
          
        </div>
      </ng-container>
      <ng-container>
        <div class="modal-section-title">
          Felicitaciones
          <p class="modal-section-contents">
            Expresar tu satisfacción o reconocimiento hacia una persona, entidad o aliado por el trabajo bien hecho.
          </p>
        </div>
      </ng-container>
      <ng-container>
        <div class="modal-section-title">
          Solicitud/Sugerencia
          <p class="modal-section-contents">
            Recomendar una idea o proponer una mejora constructiva para la plataforma www.jelpit.com    
          </p>
        </div>
      </ng-container>
      <ng-container>
        <div class="modal-button-container">
          <button mat-raised-button class="custom-button" (click)="close()">
            Entendido
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
