<lib-tb-modal-notification [custom]="modalData">
  <ng-template libTbTemplate="body">
    <div class="modal-content">
      <div class="modal-header">
        <h1>Atención, saldrás de Jelpit hogar</h1>
        <p>Te solicitamos amablemente los siguientes datos:</p>
      </div>
      <div class="modal-body">
        <form
          class="modal-form"
          [formGroup]="form"
          (ngSubmit)="setUserData()"
          autocomplete="off"
        >
          <div class="fullName">
            <label htmlFor="fullName">Nombres</label>
            <input
              pInputText
              id="fullName"
              aria-errormessage="fullName-error"
              placeholder="Nombres y apellidos"
              [pKeyFilter]="alphaRegex"
              formControlName="fullName"
            />
            <div class="error-container" id="fullName-error">
              <div
                *ngIf="
                  form.controls['fullName'].dirty ||
                  form.controls['fullName'].touched
                "
              >
                <small
                  *ngIf="
                    form.get('fullName').hasError('required') ||
                    form.get('fullName').hasError('whitespace')
                  "
                >
                  Campo obligatorio
                </small>
                <small *ngIf="form.get('fullName').hasError('maxlength')">
                  Longitud máxima de 60 caracteres.
                </small>
                <small *ngIf="form.get('fullName').hasError('minlength')">
                  Longitud mínima de 3 caracteres.
                </small>
              </div>
            </div>
          </div>
          <div class="cellphone">
            <label htmlFor="cellphone">Teléfono</label>
            <input
              pInputText
              id="cellphone"
              aria-errormessage="cellphone-error"
              placeholder="Ejemplo 3101234567"
              pKeyFilter="int"
              formControlName="cellphone"
            />
            <div class="error-container" id="cellphone-error">
              <div
                *ngIf="
                  form.controls['cellphone'].dirty ||
                  form.controls['cellphone'].touched
                "
              >
                <small *ngIf="form.get('cellphone').hasError('required')">
                  Campo obligatorio.
                </small>
                <small *ngIf="form.get('cellphone').hasError('min')">
                  Valor inválido.
                </small>
                <small *ngIf="form.get('cellphone').hasError('maxlength')">
                  Longitud máxima de 10 caracteres.
                </small>
                <small *ngIf="form.get('cellphone').hasError('minlength')">
                  Longitud mínima de 7 caracteres.
                </small>
              </div>
            </div>
          </div>
          <div class="email">
            <label htmlFor="email">Correo electrónico</label>
            <input
              pInputText
              id="email"
              aria-errormessage="email-error"
              placeholder="email"
              formControlName="email"
            />
            <div class="error-container" id="email-error">
              <div
                *ngIf="
                  form.controls['email'].dirty ||
                  form.controls['email'].touched
                "
              >
                <small *ngIf="form.get('email').hasError('required')">
                  Campo obligatorio
                </small>
                <small
                  *ngIf="
                    form.get('email').hasError('email') ||
                    form.get('email').hasError('pattern')
                  "
                >
                  Usa el siguiente formato: correo&#64;ejemplo.com
                </small>
              </div>
            </div>
          </div>
          <div class="terms">
            <div>
              <p-checkbox
                [binary]="true"
                inputId="terms"
                formControlName="terms"
                aria-errormessage="terms-error"
              ></p-checkbox>
              <label for="terms"
                >Acepto los
                <a
                  routerLink="/legal/terminos-y-condiciones"
                  target="_blank"
                  rel="noopener, nofollow"
                  >Términos y condiciones,</a
                >
                he leído la
                <a
                  routerLink="/legal/politica-de-privacidad"
                  target="_blank"
                  rel="noopener, nofollow"
                  >Política de privacidad</a
                >
                y autorizo el
                <a
                  routerLink="/legal/autorizacion-de-datos"
                  target="_blank"
                  rel="noopener, nofollow"
                  >Tratamiento de mis datos personales</a
                >.</label
              >
            </div>
            <div class="error-container" id="terms-error">
              <div
                *ngIf="
                  form.controls['terms'].dirty ||
                  form.controls['terms'].touched
                "
              >
                <small *ngIf="form.get('terms').hasError('required')">
                  Campo obligatorio
                </small>
              </div>
            </div>
          </div>
          <p-button
            label="Enviar"
            type="submit"
            [disabled]="disableButton"
          ></p-button>
        </form>
      </div>
    </div>
  </ng-template>
</lib-tb-modal-notification>
