<header
  itemscope=""
  itemtype="https://www.schema.org/SiteNavigationElement"
  role="navigation"
>
  <div class="start">
    <a href="/" class="logo" data-qa-id="JP-P-SH-M-aref_go_home">
      <picture [ngClass]="{'assistance':!this.path?.includes('jelpit')}">
        <source *ngIf="menuData[1]?.show_searcher; else elseBlock"
          media="(max-width: 1023px)"
          srcset= "{{ this.path?.includes('jelpit') ? './assets/img/brand/Isotipo_jelpit.svg' : ''./assets/img/ServiciosBolivarBrand.png'}}"
          height="40"
          width="22"
        />
        <ng-template #elseBlock>
          <source
            media="(max-width: 1023px)"
            srcset="{{ this.path?.includes('jelpit') ? './assets/img/brand/logo_jelpit_color.svg' : ''./assets/img/ServiciosBolivarBrand.png'}}"
            height="40"
          />
        </ng-template>
        <img src="{{ this.path?.includes('jelpit') ? './assets/img/brand/logo_jelpit_color.svg' : './assets/img/ServiciosBolivarBrand.png'}}" alt="{{ this.path === '/' ? 'jelpit' : 'asistenciabolivar' }}" />
      </picture>
    </a>
  </div>
  <div class="end">
    <app-menu
      [menuData]="menuData"
      [domain]="this.path"
      >
    </app-menu>
  </div>
</header>
