<main
  #carousel
  id="carousel"
  *ngIf="cards"
  (touchstart)="swipe($event, 'start')"
  (touchend)="swipe($event, 'end')"
  [class.static]="!checkOverflow()"
>
  <div
    class="item"
    *ngFor="let card of cards; index as itemI"
    [style.--offset]="itemI + 1"
    [class.disabled]="itemI + 1 !== actualPosition"
    (click)="setInput(itemI)"
    onkeypress=""
  >
    <img [src]="card.creditCard" alt="Tarjeta de credito" />
    <img
      class="select-icon-cards"
      alt="Selecciona tu tarjeta"
      src="../../../../assets/img/tc-davivienda/Select.png"
    />
  </div>
</main>
<div #controls id="controls" *ngIf="cards" [class.static]="!checkOverflow()">
  <label
    [for]="'input' + inputI"
    class="content-input"
    *ngFor="let item of cards; index as inputI"
  >
    <mat-radio-button
      [id]="'input' + inputI"
      type="radio"
      name="position"
      (click)="setInput(inputI)"
      onkeypress=""
      [checked]="actualPosition === inputI + 1"
    ></mat-radio-button>
  </label>
</div>
<img
  class="select-icon-center"
  alt="Selecciona tu tarjeta"
  src="../../../../assets/img/tc-davivienda/Select.png"
  [class.static]="!checkOverflow()"
/>
