import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environment';

declare const dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  urlBase = environment.apiUrl;
  urlUserActive = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  isLoggedIn(url?: string): boolean {

    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem("token-session") || '';
      if (token !== '') {
        return true;
      } else {
        if (url !== undefined) {
          this.urlUserActive = url;
        }
        return false;
      }
    }
  }

  logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('portal');
      localStorage.removeItem('sendEmailRestore');
      localStorage.removeItem('token-node');
      localStorage.removeItem('email');
      localStorage.removeItem('token-session');
      localStorage.removeItem('dataUser');
      localStorage.removeItem('firstLogin');
      localStorage.removeItem('userIDNoLogin');
      localStorage.removeItem('dataUpdateCart');
      localStorage.removeItem('dataTempAddress');
      localStorage.removeItem('directionTemp');
      localStorage.removeItem('UserRegister');
      localStorage.removeItem('userData');
      window.location.href = '/';
    }
  }

  createDataLayer(): void {
    if (this.isLoggedIn()){
      const dataUserLocalStorage = JSON.parse(
        atob(localStorage.getItem('dataUser'))
      );
      setTimeout(() => {
        dataLayer.push({
          userId: dataUserLocalStorage.id,
          tipoUsuario: 'Personas',
        });
      }, 7000);
    }
  }
}
