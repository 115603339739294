import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { HomeService } from './../../services/home/home.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environment/environment';

declare const dataLayer: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  menuData = [];
  public path: string = "";
  public generalPath: string = environment.generalPath;
  public splitedPath: Array<string> = [];
  constructor(
    private homeService: HomeService,
    private router: Router
  ) {
    this.splitedPath = this.generalPath.split(',');
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        this.path = window.location.hostname;
      }
    })
  }

  ngOnInit(): void {
    this.getDataMenu();
  }

  getDataMenu(): void {
    this.subscriptions.push(
      this.homeService
        .getDataMenu()
        .subscribe((response: any) => {
          this.menuData = this.buildMenuData(response.result);
        }));
  }

  buildMenuData(data) {
    let menuData = [];
    data.forEach(element => {
      if (element.sub_categories || element.options) {
        menuData.push(element);
      }
    });
    menuData.reverse();
    return menuData;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
  }
}
