<form class="main-container">
  <ng-container>
    <h1 class="main-title">Atención al usuario</h1>
    <p>
      Radique aquí sus
      <strong>peticiones, quejas, reclamos o felicitaciones</strong> y ayúdenos
      a mejorar.
    </p>
  </ng-container>
  <br />
  <form [formGroup]="pqrInformation" autocomplete="off">
    <!-- Datos del cliente -->
    <ng-container class="custom-text-align">
      <div class="section-title">
        <h1>Datos del cliente</h1>
      </div>
      <input type="hidden" formControlName="_csrf" name="_csrf">
      <div class="custom-wide-input">
        <mat-form-field appearance="outline" class="custom-wide-input">
          <mat-label>Nombres y apellidos</mat-label>
          <input
            formControlName="nameComplete"
            matInput
            #input
            placeholder="Nombres y apellidos"
            type="text"
          />
          <mat-error *ngIf="nameComplete.errors?.required"
            >Campo requerido</mat-error
          >
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline" class="custom-short-input">
          <mat-label>Tipo de documento</mat-label>
          <mat-select
            (selectionChange)="selectTypeDocument($event.value)"
            formControlName="typeDoc"
          >
            <mat-option *ngFor="let type of typeDocuments" [value]="type">{{
              type.name
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="typeDoc.errors?.required"
            >Campo requerido</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-short-right-input">
          <mat-label>Número de documento </mat-label>
          <input
            matInput
            #input
            placeholder="Número de documento"
            formControlName="numberDoc"
          />
          <mat-error *ngIf="numberDoc.errors?.required"
            >Campo requerido</mat-error
          >
          <mat-error *ngIf="numberDoc.errors?.pattern"
            >Campo solo {{ typeField }}</mat-error
          >
          <mat-error *ngIf="numberDoc.errors?.maxlength && !numberDoc.errors?.pattern">
            Solo se admiten {{ cantCharacters }} caracteres</mat-error
          >
          <mat-error *ngIf="numberDoc.errors?.minlength && !numberDoc.errors?.pattern">
            Cantidad de caracteres inválida
            {{ numberDoc.value?.length + "/" + cantCharacters }}
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <!-- Datos de contacto -->
    <ng-container>
      <div class="section-title">
        <h1>Datos de contacto</h1>
      </div>

      <div>
        <mat-form-field appearance="outline" class="custom-short-input">
          <mat-label>Correo electrónico</mat-label>
          <input
            matInput
            #input
            placeholder="Correo electrónico"
            type="email"
            formControlName="email"
          />
          <mat-error *ngIf="email.errors?.required">Campo requerido</mat-error>
          <mat-error *ngIf="email.errors?.pattern">Formato erróneo</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-short-right-input">
          <mat-label>Confirmar correo electrónico</mat-label>
          <input
            matInput
            #input
            placeholder="Confirmar correo electrónico"
            type="email"
            formControlName="confirmEmail"
          />
          <mat-error *ngIf="confirmEmail.errors"
            >Correo electrónico no coincide, Formato erroneo</mat-error
          >
          <mat-error *ngIf="confirmEmail.errors?.required"
            >&nbsp;</mat-error
          >
          <mat-error *ngIf="confirmEmail.errors?.pattern"
            >&nbsp;</mat-error
          >
        </mat-form-field>
      </div>

      <div class="custom-text-align">
        <mat-form-field appearance="outline" class="custom-short-input">
          <mat-label>Celular</mat-label>
          <input
            matInput
            #input
            placeholder="Celular"
            formControlName="cellphone"
            (keyup)="checkPhoneNumber()"
          />
          <mat-error *ngIf="cellphone.errors?.required"
            >Campo requerido</mat-error
          >
          <mat-error *ngIf="cellphone.errors?.pattern"
            >{{ cellphone.value[0] == 3 ? 'Se admiten solo números' : 'El número debe empezar en 3' }}</mat-error
          >
          <mat-error *ngIf="cellphone.errors?.maxlength && !cellphone.errors?.pattern">
            Solo se admiten 10 caracteres</mat-error
          >
          <mat-error *ngIf="cellphone.errors?.minlength && !cellphone.errors?.pattern">
            Cantidad de caracteres inválida
            {{ cellphone.value?.length + "/10" }}
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <!-- datos de solicitud -->
    <ng-container>
      <div class="section-title">
        <h1>Datos de la solicitud</h1>
      </div>
      <div>
        <div class="content-management">
          <mat-form-field appearance="outline" class="custom-short-input">
            <mat-label>Tipo de gestión</mat-label>
            <mat-select
              formControlName="managementType"
              (selectionChange)="selectTypeManagement($event.value)"
            >
              <mat-option
                *ngFor="let types of typeManagement"
                [value]="types"
                >{{ types.name }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="managementType.errors?.required"
              >Campo requerido</mat-error
            >
          </mat-form-field>
          <button
            class="mobile-minifab-button-position"
            mat-mini-fab
            (click)="openTips()"
          ></button>
        </div>

        <div class="content-service">
          <mat-form-field appearance="outline" class="custom-short-right-input">
            <mat-label>Servicio que contrató</mat-label>
            <mat-select disableOptionCentering formControlName="serviceHide">
              <mat-option *ngFor="let service of services" [value]="service">{{
                service.name
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="serviceHide.errors?.required"
              >Campo requerido</mat-error
            >
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <!-- datos de la devolucion -->
    <ng-container *ngIf="isManagement">
      <div class="section-title">
        <h1>Datos para la devolución</h1>
      </div>
      <div>
        <mat-form-field appearance="outline" class="custom-short-input">
          <mat-label>Nombre del Banco</mat-label>
          <mat-select
            formControlName="bank"
            (selectionChange)="selectedBank($event.value)"
          >
            <mat-option *ngFor="let bank of banks" [value]="bank">{{
              bank.name
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="bank.errors?.required">Campo requerido</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-short-right-input">
          <mat-label>Tipo de cuenta</mat-label>
          <mat-select formControlName="accountType">
            <mat-option
              *ngFor="let accountType of typeAccount"
              [value]="accountType.id"
              >{{ accountType.name }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="accountType.errors?.required"
            >Campo requerido</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-short-input">
          <mat-label>Número de cuenta o Billetera...</mat-label>
          <input
            matInput
            #input
            placeholder="Número de cuenta o Billetera..."
            type="numeric"
            formControlName="accountNumber"
          />
          <mat-error *ngIf="accountNumber.errors?.required"
            >Campo requerido</mat-error
          >
          <mat-error *ngIf="accountNumber.errors?.pattern"
            >Formato erróneo</mat-error
          >
          <mat-error *ngIf="accountNumber.errors?.maxlength && !accountNumber.errors?.pattern"
            >Cantidad máxima de caracteres 16</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-short-right-input">
          <mat-label>Tipo de persona</mat-label>
          <mat-select
            formControlName="typePerson"
          >
            <mat-option
              *ngFor="let type of typePersons"
              [value]="type.value"
              >{{ type.name }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="typePerson.errors?.required"
            >Campo requerido</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-short-input">
          <mat-label>Tipo de identificación</mat-label>
          <mat-select
            formControlName="typeDocument"
            (selectionChange)="selectTypeDocManagament($event.value)"
          >
            <mat-option
              *ngFor="let typesDoc of typeDocuments"
              [value]="typesDoc"
              >{{ typesDoc.name }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="typeDocument.errors?.required"
            >Campo requerido</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-short-right-input">
          <mat-label>Número de documento </mat-label>
          <input
            matInput
            #input
            placeholder="Número de documento"
            formControlName="numberDocManagement"
          />
          <mat-error *ngIf="numberDocManagement.errors?.required"
            >Campo requerido</mat-error
          >
          <mat-error *ngIf="numberDocManagement.errors?.pattern"
            >Campo solo {{ typeFieldManagement }}</mat-error
          >
          <mat-error *ngIf="numberDocManagement.errors?.maxlength && !numberDocManagement.errors?.pattern">
            Solo se admiten {{ cantCharactersManagement }} caracteres</mat-error
          >
          <mat-error *ngIf="numberDocManagement.errors?.minlength && !numberDocManagement.errors?.pattern">
            Cantidad de caracteres inválida
            {{
              numberDocManagement.value?.length + "/" + cantCharactersManagement
            }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-short-input float-left">
          <mat-label>Nombre del Titular</mat-label>
          <input
            formControlName="nameHeadline"
            matInput
            #input
            placeholder="Nombre del Titular"
          />
          <mat-error *ngIf="nameHeadline.errors?.required"
            >Campo requerido</mat-error
          >
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container>
      <mat-form-field appearance="outline" class="custom-full-input">
        <mat-label>Detalles de su solicitud</mat-label>
        <textarea
          matInput
          formControlName="detailApplication"
          placeholder="Cuéntenos su inquietud, sugerencia, reclamo o motivo de la reversión"
        ></textarea>
        <mat-hint align="end"
          >{{ detailApplication.value?.length || 0 }}/2000</mat-hint
        >
        <mat-error *ngIf="detailApplication.errors?.required"
          >Campo requerido</mat-error
        >
        <mat-error *ngIf="detailApplication.errors?.maxlength"
          >Solo se admiten hasta 2000 caracteres</mat-error
        >
      </mat-form-field>
    </ng-container>
  </form>

  <!-- Boton enviar -->
  <ng-container>
    <div class="custom-button-container">
      <button mat-raised-button class="custom-button" [ngClass]="{'custom-button-ready':pqrInformation.valid}" (click)="sendData()">
        Enviar
      </button>
    </div>
  </ng-container>
</form>
