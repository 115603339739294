import { Directive, ElementRef, Renderer2 } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Directive({
    selector: '[showOnlyOnHome]'
})
export class ShowOnlyOnHome {

    constructor(private elementRef: ElementRef, private router: Router, private renderer: Renderer2) {
        if (this.router.url !== "/") this.hideElement(this.elementRef);
        this.setRouterEvent();
    }

    /**
     * The function sets up an event listener on the router events and hides or shows an element based
     * on the current navigation URL.
     */
    setRouterEvent() {
        this.router.events
            .pipe(
                filter(
                    (event) => {
                        return (event instanceof NavigationStart)
                    }
                )
            )
            .subscribe({
                next: (response: any) => {
                    if (response.url !== "/") this.hideElement(this.elementRef);
                    else this.showElement(this.elementRef);
                }
            });
    }

    /**
     * The function hides an element by adding the 'hidde' class to it.
     * @param {ElementRef} el - The `el` parameter is of type `ElementRef`, which is a reference to a
     * DOM element in Angular. It is used to target and manipulate the element in the DOM.
     */
    hideElement(el: ElementRef) {
        this.renderer.addClass(el.nativeElement, 'hidden');
    }

    /**
     * The function removes the 'hidde' class from the specified element.
     * @param {ElementRef} el - The `el` parameter is of type `ElementRef`, which is a reference to a
     * DOM element.
     */
    showElement(el: ElementRef) {
        this.renderer.removeClass(el.nativeElement, 'hidden');
    }
}