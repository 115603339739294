import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { CurrentLocationService } from './../currentLocation/current-location.service';
import { MatDialog } from '@angular/material/dialog';
import { CryptoJSAesDecrypt } from '../../../shared/cryptoJs/decrypt.service';
import { CryptoJSAesEncrypt } from '../../../shared/cryptoJs/encrypt.service';
import * as CryptoJS from 'crypto-js';
import { isPlatformBrowser } from '@angular/common';
import { NewOrderParamsInterface } from '@interfaces/new-order-params/newOrderParams.interface';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class ServicesJelpitService {
  urlBase = environment.apiUrl;
  urlBaseLibreria = environment.apiUrlLibreria;
  envUrl = window.location.protocol + '//' + window.location.hostname;
  
  constructor(
    private httpClient: HttpClient,
    private currentLocationService: CurrentLocationService,
    private encrypt: CryptoJSAesEncrypt,
    private decrypt: CryptoJSAesDecrypt,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  setDataCreateOrder(data: any): Observable<any> {
    const url = `${this.urlBase}orders`;
    return this.httpClient.post<any>(url, data);
  }
  /**
   * @description Servicio crear orden para el rediseñor
   * @param data
   * @returns
   */
  setDataCreateOrderNewDetail(data: any): Observable<any> {
    const url = `${this.urlBase}v1/orders`;
    return this.httpClient.post<any>(url, data);
  }

  getUseraddressFields(): Observable<any> {
    const url = `${this.urlBase}address-fields`;
    return this.httpClient.get<any>(url);
  }

  getUseraddress(): Observable<any> {
    const url = `${this.urlBase}addressesUsers`;
    return this.httpClient.get<any>(url);
  }

  postUseraddress(newAddress: any): Observable<any> {
    const url = `${this.urlBase}addressesUsers`;
    return this.httpClient.post<any>(url, newAddress);
  }

  putUseraddress(newAddress: any, idAddress: any): Observable<any> {
    const url = `${this.urlBase}addressesUsers/` + idAddress;
    return this.httpClient.put<any>(url, newAddress);
  }

  getTokenPayment(): Observable<any> {
    const url = `${environment.urlTokenPayment}load/compare`;
    return this.httpClient.post<any>(url, environment.userTokenPayment);
  }

  getApiEncrypt(data: any, token: string): Observable<any> {
    const url = `${environment.urlTokenPayment}api/encrypt`;
    let headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.httpClient.post<any>(url, data, { headers });
  }

  setDataUpdateOrders(orders: any): Observable<any> {
    const url = `${this.urlBase}orders/completing-info `;
    return this.httpClient.post<any>(url, orders);
  }

  generateIDNoLogin(len: number, an?: string): string {
    an = an && an.toLowerCase();
    let str = '',
      i = 0,
      min = an === 'a' ? 10 : 0,
      max = an === 'n' ? 10 : 62;
    const crypto = window.crypto;
    while (i < len) {
      const array = new Uint8Array(1)
      const randomNumberGenerated = Number(crypto.getRandomValues(array))/255;
      let r = (randomNumberGenerated * (max - min) + min) << 0;
      str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
      i++;
    }
    return str;
  }

  // Mensajeria
  getAddressMessaging(): Observable<any> {
    const url = `${this.urlBase}savedAddressMessagings`;
    return this.httpClient.get<any>(url);
  }

  getDataCotizacion(data): Observable<any> {
    const url =
      'https://www.logiexentregas.com/logiexapirestruta/api/jelpit/rutalogiex/';
    return this.httpClient.post<any>(url, data);
  }

  /**
   * FUnction to contruct the listo orders variable
   * @param dataFormDynamic
   * @returns complete obbject list Orders
   */
  getListOrders(dataFormDynamic: any): any {
    if (isPlatformBrowser(this.platformId)) {
      return {
        user_cookie: localStorage.getItem('userIDNoLogin')
          ? localStorage.getItem('userIDNoLogin')
          : null,
        products: dataFormDynamic.products,
        audience_token: localStorage.getItem('audience_token')
          ? localStorage.getItem('audience_token')
          : '',
        tc_davivienda: dataFormDynamic.tc_davivienda,
        discount: dataFormDynamic.discount,
        category_slug: localStorage.getItem('categoryUsed')
      };
    } else {
      return {};
    }
  }
  /**
   * @returns format User Payment Data
   */
  getUserPaymentData(): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('dataUser')) {
        const dataUserLocalSotrage = JSON.parse(
          atob(localStorage.getItem('dataUser'))
        );
        return {
          name: `${dataUserLocalSotrage.first_name} ${
            dataUserLocalSotrage.middle_name === null
              ? ''
              : dataUserLocalSotrage.middle_name
          }`,
          last_name: `${dataUserLocalSotrage.last_name} ${
            dataUserLocalSotrage.second_last_name === null
              ? ''
              : dataUserLocalSotrage.second_last_name
          }`,
          email: dataUserLocalSotrage.email,
          phone: dataUserLocalSotrage.phone,
          id_type: this.convertDocumentTypePaymentGateway(
            dataUserLocalSotrage.document_type
          ),
          document_number: dataUserLocalSotrage.document_number,
        };
      } else {
        return {
          name: '',
          last_name: '',
          email: '',
          phone: '',
          id_type: '',
          document_number: '',
        };
      }
    }
  }
  /**
   * @description Metodo general para retornar datos básicaos de la orden
   */
  getNewOrderParams(dataFormDynamic: any): NewOrderParamsInterface {
    return {
      urlReturn: `${this.envUrl}/confirmacion/`,
      anotherData: dataFormDynamic?.anotherData,
      nameService: dataFormDynamic?.nameService,
      category: dataFormDynamic?.anotherData?.category,
      description: dataFormDynamic?.anotherData?.description,
      products: dataFormDynamic?.products,
      listOrders: this.getListOrders(dataFormDynamic),
      dataUserPayment: this.getUserPaymentData(),
    };
  }
  /**
   * @description Función para encriptar data
   * */
  encryptData(data: any) {
    return this.encrypt.CryptoJSAesEncrypt(JSON.stringify(data));
  }
  // Función para descriptar data
  decryptData(data: any) {
    return JSON.parse(this.decrypt.cryptoJSAesDecrypt(data));
  }
  /**
   * @description Método para procesar orden cuanto es de pago recurrente
   * @param dataUserPayment Datos del usuario
   * @param  Data desencriptada
   * @param anotherData Datos adicionales
   * @param urlReturn Url de redirección
   */
  processOrderByRecurrentPayment(
    dataUserPayment: any,
    dataAtob: any,
    anotherData: any,
    urlReturn: string
  ) {
    let configPay: any = {};
    configPay.name = this.deleteDiacriticos(dataUserPayment.name);
    configPay.last_name = this.deleteDiacriticos(dataUserPayment.last_name);
    configPay.last_name = this.deleteDiacriticos(dataUserPayment.last_name);
    configPay.email = dataUserPayment.email;
    configPay.phone = dataUserPayment.phone;
    configPay.type_user = 'N';
    configPay.id_type = dataUserPayment.id_type;
    configPay.id = dataUserPayment.document_number;
    configPay.reference = dataAtob[0].voucher;
    configPay.description = `Servicio Jelpit - ${this.deleteDiacriticos(
      anotherData.categorySlug
    )}`;
    configPay.vat = 0;
    configPay.amount = parseFloat(dataAtob[0].total);
    configPay.url_return = `${urlReturn}suscripcion/${dataAtob[0].voucher}`;
    configPay.url_return_problem = `${this.envUrl}`;
    configPay.subclient = environment.subclientGateway;
    configPay.plan = {
      name: dataAtob[0]?.suscriptions?.name_product,
      recurrence: dataAtob[0]?.suscriptions?.slug,
      id_plan: dataAtob[0]?.suscriptions?.id_plan,
      dues: dataAtob[0]?.suscriptions?.dues,
    };
    window.open(
      environment.apiUrlPay +
        'pagos/solicita?q=' +
        this.CryptoGateway(configPay),
      '_self'
    );
  }
  /**
   * @description Función para procesar orden cuando no es pago recurrente
   * @param data service information
   * @param component Modal class to open
   * @param matDialog reference to MAtDialog
   */
  processOrderByNotRecurrentPayment(
    data: any,
    component: ComponentType<any>,
    matDialog: MatDialog
  ){
    matDialog.open(component, {
      panelClass: 'full-location',
      width: '551px',
      disableClose: true,
      data: {
        nameService: data.nameService,
        total: data.total,
        category: data.category,
        description: data.description,
        products: data.products,
      },
    });
  }
  /**
   * @description Este metodo calcula el total de los productos agregados
   * @param data
   */
  getTotalPrice(data) {
    if (data.products?.length > 0) {
      return data.products
        .map((item) => item.total)
        .reduce((prev, curr) => prev + curr, 0);
    } else {
      return data
        .map((item) => item.total)
        .reduce((prev, curr) => prev + curr, 0);
    }
  }

  getCoordinates(address: any) {
    return new Promise((resolve) => {
      let tempCoordinates: any = {};
      this.currentLocationService
        .getCurrentLocation(address)
        .subscribe((location: any) => {
          tempCoordinates.longitude = location.lng;
          tempCoordinates.latitude = location.lat;
          resolve(tempCoordinates);
        });
    });
  }

  convertDocumentTypePaymentGateway(text: string): string {
    switch (text) {
      case 'Cédula de Ciudadania': {
        return 'CC';
      }
      case 'Número de Identificación Tributaria': {
        return 'NIT';
      }
      case 'Cédula de Extranjería': {
        return 'CE';
      }
      case 'Pasaporte': {
        return 'CC';
      }
      default: {
        return 'CC';
      }
    }
  }

  deleteDiacriticos(texto) {
    if (texto) {
      return texto
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/-/gi, ' ');
    }
  }

  CryptoGateway(object: any) {
    const keyEncr = CryptoJS.enc.Hex.parse(environment.secretKeyGateway);
    const iv = CryptoJS.enc.Hex.parse(environment.ivGateway);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(object), keyEncr, {
      mode: CryptoJS.mode.CTR,
      iv: iv,
      padding: CryptoJS.pad.NoPadding,
    });
    const finalString = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    let chars = Array.from(finalString);
    const arrayC = [
      '!',
      '*',
      "'",
      '(',
      ')',
      ';',
      ':',
      '@',
      '&',
      '=',
      '+',
      '$',
      ',',
      '/',
      '?',
      '#',
      '[',
      ']',
      '>',
      '<',
    ];
    let arrayR = [];
    arrayC.forEach((element) => {
      arrayR.push(element.charCodeAt(0));
    });
    chars.forEach((element1, index1) => {
      arrayC.forEach((element2, index2) => {
        if (element2 === element1) {
          chars[index1] = `-${arrayR[index2]}-`;
        }
      });
    });
    return chars.join('');
  }

  functionUpgradeCard(id_plan: string, url: string) {
    let urlReturn = this.envUrl + url;
    let dataUpgrade: any = {
      id_plan: id_plan.toString(),
      url_return: urlReturn,
    };
    window.open(
      environment.apiUrlPay +
        'update-credit-card?q=' +
        this.CryptoGateway(dataUpgrade),
      '_self'
    );
  }
}
