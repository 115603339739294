import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from '@components/header/header.component';
import { MenuComponent } from '@components/menu/menu.component';
import { MenuMobileComponent } from '@components/menu-mobile/menu-mobile.component';
import { MenuUserComponent } from '@components/menu-user/menu-user.component';
import { DialogComponent } from '@components/dialog/dialog.component';
import { FooterComponent } from '@components/footer/footer.component';
import { SharedModule } from '@modules/shared.module';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthInterceptorService } from '@interceptors/auth-interceptor.service';
import { ClickOutsideDirective } from './shared/directives/clickOutside/clickOutside.directive';
import { CarButtonSumaryComponent } from '@components/car-button-sumary/car-button-sumary.component';
import { MatBadgeModule } from '@angular/material/badge';
import { LibTbModalNotificationModule, LibTbProgressBarModule, LibTbSearchModule } from 'tech-block-lib';
import { ShowOnlyOnHome } from './shared/directives/showOnlyOnHome/showOnlyOnHome.directive';
import { MainLayoutComponent } from '@layouts/main-layout/main-layout.component';
import { LayoutMiaComponent } from '@main-views/home/components/layout-mia/layout-mia.component';
import { LoadingComponent } from '@components/loading/loading.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    LayoutMiaComponent,
    HeaderComponent,
    MenuComponent,
    MenuMobileComponent,
    MenuUserComponent,
    DialogComponent,
    FooterComponent,
    ClickOutsideDirective,
    CarButtonSumaryComponent,
    ShowOnlyOnHome,
    LoadingComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    MatBadgeModule,
    ServiceWorkerModule.register('sw.js', { enabled: true }),
    LibTbModalNotificationModule,
    LibTbSearchModule,
    LibTbProgressBarModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-ES" }, //Format configuration in es-ES
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
