import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class PqrsService {
  url = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  getIdentificationType() {
    return this.httpClient.get<any>(this.url + 'identificationTypes');
  }

  getTypesTicket() {
    return this.httpClient.get<any>(this.url + 'ticketTypes');
  }

  getServiceTickets() {
    return this.httpClient.get<any>(this.url + 'servicesTickets');
  }

  getBanks() {
    return this.httpClient.get<any>(this.url + 'banks');
  }

  getTypesAccountBank() {
    return this.httpClient.get<any>(this.url + 'bankAccountTypes');
  }

  sendPqr(data: any) {
    return this.httpClient.post<any>(this.url + 'tickets', data);
  }

  searchPqr(value: string, _csrf: string) {
    return this.httpClient.get<any>(`${this.url}tickets/${value}?_csrf=${_csrf}`);
  }
}
