import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HeadersService {

  constructor( @Inject(PLATFORM_ID) private platformId: Object,) { }

  getHttpHeadersNotificationToken(): HttpHeaders {
    let token; 
    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem("token-node");
    }
    return new HttpHeaders({ 'x-access-token': token, 'Content-Type': 'application/json' });
  }
  
  getHttpHeadersRefreshToken(uid): HttpHeaders {
    return new HttpHeaders({ 'x-access-token': uid})
  }

  getHttpHeaderUpdatePassword(token): HttpHeaders {
    return new HttpHeaders({ 'x-access-token': token})
  }
}
