import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { CarSumaryServiceService } from '@main-views/car-sumary/service/car-sumary-service.service';
import { AuthService } from '@services/auth/auth.service';
import { HomeService } from '@services/home/home.service';
import { ConfirmationService } from 'primeng/api';
import { ILibTbModalNotification } from 'tech-block-lib';

declare const dataLayer: any;

@Component({
  selector: 'app-layout-mia',
  templateUrl: './layout-mia.component.html',
  styleUrls: ['./layout-mia.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          width: '360px',
          overflow: 'initial',
        })
      ),
      state(
        'close',
        style({
          width: '0px',
          overflow: 'hidden',
        })
      ),
      transition('close => open', [
        group([
          animate(
            '0.2s',
            style({
              width: '360px',
            })
          ),
          animate(
            '0s',
            style({
              overflow: 'initial',
            })
          ),
        ]),
      ]),
      transition('open => close', [
        group([
          animate(
            '0.2s',
            style({
              width: '0px',
            })
          ),
        ]),
      ]),
    ]),
    trigger('openCloseMobile', [
      state(
        'open',
        style({
          height: '360px',
          overflow: 'initial'
        })
      ),
      state(
        'close',
        style({
          height: '0px',
          overflow: 'hidden'
        })
      ),
      transition('close => open', [
        group([
          animate(
            '0.3s',
            style({
              height: '360px',
            })
          ),
          animate(
            '0s',
            style({
              overflow: 'initial',
            })
          ),
        ]),
      ]),
      transition('open => close', [
        group([
          animate(
            '0.3s',
            style({
              height: '0px',
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class LayoutMiaComponent implements OnInit {
  @Input() services: any = [];
  @Input() menuData: any = [];
  @Input() domain: string = "";
  
  enableChatAI: boolean = false;
  enableMicInput: boolean = false;
  enableThumbs: boolean = false;
  container: HTMLElement;
  dragTop: HTMLElement;
  body: HTMLElement;
  countProducts: number = 0;
  nameUser: string = 'Iniciar sesión';
  showChat: boolean = false;
  showChatMobile: boolean = false;
  userIsLogin: boolean = false;
  loginModal: any = {};

  customModal: ILibTbModalNotification = {
    key: 'modalLogin',
    closeOnEscape: true,
    showClose: true,
    dismissableMask: true,
    acceptButtonVisible: false,
    rejectButtonVisible: false,
    class: 'modal-login',
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document,
    private authService: AuthService,
    private homeService: HomeService,
    private confirmationService: ConfirmationService,
    private carSumaryServiceService: CarSumaryServiceService,
  ) { }

  ngOnInit(): void {
    this.container = document.getElementById('container-mobile');
    this.dragTop = document.getElementById('mobile-controller');
    this.body = document.querySelector('body');
    this.moveMobileChat();
    this.userIsLogin = this.authService.isLoggedIn();
    this.homeService.getDataHomeAll().subscribe((data) => {
      this.enableChatAI = data?.result?.section_chat_ai?.chat_ai;
      this.enableMicInput = data?.result?.section_chat_ai?.microphone_chat_ai;
      this.enableThumbs = data?.result?.section_chat_ai?.fl_thumb_up_button_ai;
    });

    if (this.userIsLogin) {
      if (isPlatformBrowser(this.platformId)) {
        const dataUserLocalSotrage = JSON.parse(
          atob(localStorage.getItem('dataUser'))
        );
        this.nameUser = `Hola ${dataUserLocalSotrage.first_name}`;
        if (dataUserLocalSotrage?.id) {
          this.carSumaryServiceService.carCount(dataUserLocalSotrage?.id).subscribe(
            (res: any) => {
              this.countProducts = res.result;
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let menuD = changes.menuData;
    if (menuD?.currentValue[0].options[1]) {
      this.loginModal = menuD.currentValue[0].options[1];
    }
  }

  /**
   * The function `eventUserMenu` pushes an event to the dataLayer with specific category, action, and
   * label values.
   * @param text - The "text" parameter is a string that represents the label for the event. It is used
   * to provide additional information or context about the event being tracked.
   */
  eventUserMenu(text) {
    //47F
    dataLayer.push({
      event: 'ga_event',
      category: 'home servicios',
      action: 'JPW - home menu flotante',
      label: text,
    });
  }

  /**
   * Función para mostrar u ocultar el chat en desktop
   */
  toggleChat(): void {
    this.showChat = !this.showChat;
    this.eventUserMenu('Chat MIA');
  }
  /**
   * Función para mostrar el chat
   */
  openModal(): void {
    this.showChat = true;
  }
  /**
   * Función para ocultar el chat
   */
  closeModal(): void {
    this.showChat = false;
  }
  /**
   * Función para mostrar u ocultar el chat en desktop
   */
  toggleChatMobile(): void {
    this.showChatMobile = !this.showChatMobile;
    this.setDataLayerChat();
  }
  /**
   * Función para mostrar el chat
   */
  openModalMobile(): void {
    this.showChatMobile = true;
  }
  /**
   * Función para ocultar el chat
   */
  closeModalMobile(): void {
    this.showChatMobile = false;
  }
  /**
   * Función para extender el chat o contraerlo en mobile
   */
  moveMobileChat(): void {
    let dragTopDown = false;

    if (this.dragTop) {
      this.dragTop.addEventListener('touchstart', () => {
        dragTopDown = true;
        this.body.style.overflow = 'hidden';
      });

      this.dragTop.addEventListener('touchend', () => {
        dragTopDown = false;
        this.body.style.overflow = 'auto';
        this.checkHeight(
          Number(this.container.style.height.replace('px', '')),
          this.container
        );
      });

      this.dragTop.addEventListener('touchmove', (e) => {
        if (!dragTopDown) {
          return;
        }
        let newHeight = window.innerHeight - e.touches[0].clientY - 60;
        let h = newHeight + 'px';
        this.container.style.height = h;
      });
    }
  }
  /**
   * Función para verificar la altura del chat en mobile y cerrarlo si es necesario o extenderlo
   * @param actualHeight - Altura actual del chat
   * @param container - Contenedor del chat
   */
  checkHeight(actualHeight: number, container: HTMLElement): void {
    if (actualHeight > 450) {
      container.style.height = 'calc(100vh - 175px)';
      return;
    }
    if (actualHeight < 250) {
      this.closeModalMobile();
      return;
    }
    container.style.height = '360px';
  }

  /**
   * The function sets the data layer for a shopping cart.
   */
  setDataLayerCar() {
    this.createDataLayer('Carrito');
  }

  /**
   * The function sets the data layer for chat MIA.
   */
  setDataLayerChat() {
    this.createDataLayer('Chat MIA');
  }

  /**
   * This function shows the user menu if they are logged in, otherwise it redirects them to the login
   * page and creates a data layer for login.
   */
  showMenuUser(): void {
    if (this.userIsLogin) {
      let header = this.document.getElementById('menu_user');
      header?.classList.add('show_user_m');
    } else {
      window.open(this.loginModal.url, '_self');
    }
    this.createDataLayer('Iniciar sesión');
  }

  /**
   * This function pushes data to the dataLayer array for tracking user events related to a header
   * menu.
   * @param {number} opcion - a number representing the option selected from a menu
   * @param {string} name - a string representing the name of the menu item that was clicked.
   */
  createDataLayer(name: string) {
    // TAG 47F
    dataLayer.push({
      event: 'ga_event',
      category: 'home servicios',
      action: 'JPW - home menu flotante',
      label: name,
    });
  }
}
