import { environment } from './environment';

export const tcDaviviendaText = {
  initialTcDavivienda: {
    title: 'Ingrese su documento para activar los beneficios',
    subtitle: '',
  },
  inactiveTcDavivienda: {
    title: 'Tengo una tarjeta de crédito Davivienda activa',
    subtitle: 'Ingrese su documento para activar los beneficios',
  },
  noTcDavivienda: {
    title: 'Tengo una tarjeta de crédito Davivienda activa',
    subtitle: 'Ingrese su documento para activar los beneficios',
  },
  textServicesTc: {
    textToSelectecServices: 'Seleccione el que desea activar:',
  },
  tcCardServices: [
    {
      name: 'Orientación veterinaria',
      description: 'Recomendaciones expertas en el cuidado de mascotas.',
      smallText: '¡Seleccionar y redimir!',
      redeemedAt: '',
      slug: 'orientacion-telefonica-veterinaria',
      image: { src: "../assets/img/tc-davivienda/Veterinaria.webp" },
      imageDisable: { src: "../assets/img/tc-davivienda/VeterinariaIN.webp" },
      imageWait: { src: "../assets/img/tc-davivienda/Veterinaria_proceso.webp" },
      enabled: true,
      slugServiceTC: 'orientacion-medica-veterinaria-davivienda',
      quantity: 1
    },
    {
      name: 'Grúa',
      description: '¿Algún contratiempo? Jelpit acompaña en carretera.',
      smallText: '¡Seleccionar y redimir!',
      redeemedAt: '',
      slug: 'grua-tc-davivienda',
      image: { src: "../assets/img/tc-davivienda/Grua.webp" },
      imageDisable: { src: "../assets/img/tc-davivienda/GruaIN.webp" },
      imageWait: { src: "../assets/img/tc-davivienda/Grua_proceso.webp" },
      enabled: true,
      slugServiceTC: 'grua-davivienda',
      quantity: 1
    },
    {
      name: 'Video doctor',
      description: 'Acompañamiento médico al instante y en cualquier lugar.',
      smallText: '¡Seleccionar y redimir!',
      redeemedAt: '',
      slug: '',
      image: { src: "../assets/img/tc-davivienda/videodoctor.webp" },
      imageDisable: { src: "../assets/img/tc-davivienda/videodoctorin.webp" },
      imageWait: { src: "../assets/img/tc-davivienda/videodoctor-proceso.webp" },
      enabled: true,
      slugServiceTC: 'video-doctor',
      quantity: 1
    },
    {
      name: 'Traslado de bicicleta',
      description: '¿Pinchazo o imprevisto? Tenemos servicio de transporte de bicis.',
      smallText: '¡Seleccionar y redimir!',
      redeemedAt: '',
      slug: '',
      image: { src: "../assets/img/tc-davivienda/transladobicicleta.webp" },
      imageDisable: { src: "../assets/img/tc-davivienda/transladobicicletain.webp" },
      imageWait: { src: "../assets/img/tc-davivienda/transladobicicleta-proceso.webp" },
      enabled: true,
      slugServiceTC: 'traslado-de-bicicleta-davivienda',
      quantity: 1
    },
    {
      name: 'Lavado de auto',
      description: 'Carro limpio por dentro y por fuera con Jelpit',
      smallText: '¡Seleccionar y redimir!',
      redeemedAt: '',
      slug: 'lavado-de-auto',
      image: { src: "../assets/img/tc-davivienda/lavadoauto.webp" },
      imageDisable: { src: "../assets/img/tc-davivienda/lavadoautoin.webp" },
      imageWait: { src: "../assets/img/tc-davivienda/lavadoauto-proceso.webp" },
      enabled: true,
      slugServiceTC: 'lavado-de-auto-davivienda',
      quantity: 1
    }
  ],
  hrefTermsAndConditions: '/legal/terminos-y-condiciones',
  privacyPolicies: '',
  proceddingPersonalData: '/legal/politica-de-privacidad',
  tcDaviviendaDocuments: {
    CC: {
      min: 5,
      max: 20,
      messageError:
        'El número de documento debe tener máximo veinte dígitos y un mínimo de 5 dígitos, sin puntos ni espacios y letras',
      type: 'number',
    },
    CE: {
      min: 5,
      max: 20,
      messageError:
        'El número de documento debe tener máximo veinte dígitos y un mínimo de 5 dígitos, sin puntos ni espacios y letras',
      type: 'number',
    },
    PP: {
      min: 5,
      messageError:
        'El número de documento debe tener un mínimo de 5 dígitos,sin caracteres especiales ni espacios en blanco',
      type: 'text',
    },
  },
  tcDaviviendaTypesDocuments: [
    {
      id: 1,
      name: 'Cédula de Ciudadanía',
    },
    {
      id: 2,
      name: 'Cédula de Extranjería',
    },
    {
      id: 5,
      name: 'Pasaporte',
    },
  ],
  textDavivienda:
    'Banco Davivienda S.A. actúa como prestador de productos y servicios financieros. Servicios Bolívar S.A, expertos en soluciones para el hogar, empresas y conjuntos.',
  errorNotRegister: 'No existe el registro',
  messageErrorRequired: 'Este campo es requerido.',
};
