import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { HomeService } from '@services/home/home.service';
import { ServicesJelpitService } from '@services/services-jelpit/services-jelpit.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  public apiUrl: string = environment.apiUrl;
  public urlImg: string = environment.urlImage;
  private renderer: Renderer2;
  public envUrl: string = '';

  constructor(
    @Inject(DOCUMENT) private dom,
    private router: Router,
    private meta: Meta,
    private title: Title,
    private rendererFactory: RendererFactory2,
    private homeService: HomeService,
    private jelpitService: ServicesJelpitService
  ) {
    this.envUrl = jelpitService.envUrl;
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  genrateLinkAlternate():void{
    const link: HTMLLinkElement = this.dom.querySelector('link[rel="alternate"]');
    if(link) link.href = this.createCanonicalURL();
  }

  generateTags(config) {
    this.genrateLinkAlternate();

    let urlReplace = '';
    if (this.router.url.indexOf('%C3%B1') !== -1) {
      urlReplace = this.router.url.indexOf('%C3%B1') !== -1 ? this.router.url.replace('%C3%B1', 'n') : this.router.url;
    } else {
      urlReplace = this.router.url.indexOf('%C3%91') !== -1 ? this.router.url.replace('%C3%91', 'n') : this.router.url;
    }
    const oGUrl = (urlReplace.indexOf('?utm') !== -1) ? urlReplace.split('?utm')[0] : urlReplace;
    this.meta.updateTag({ name: 'description', content: config.description });
    this.title.setTitle(config.title);
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });

    if (oGUrl !== '/') {
      this.meta.updateTag({ property: 'og:url', content: this.envUrl+ oGUrl });
    } else {
      this.meta.updateTag({ property: 'og:url', content: this.envUrl });
    }
    if (config.image != null) {
      this.meta.updateTag({ property: 'og:image', content: config.image });
    } else {
      this.meta.updateTag({ property: 'og:image', content: this.urlImg });
    }
    if (!config.canonical) {
      this.createCanonicalURL(urlReplace);
    }

  }

  createCanonicalURL(url?: string): string {
    let canURL = url === undefined ? `https${this.dom.URL.replace('https', '').replace('http', '')}` : url;
    if (canURL.indexOf('?') !== -1) {
      canURL = canURL.substring(0, canURL.indexOf('?'));
    }
    return canURL;
  }
  createCanonicalUrl(urlCanonical?:string ){
    if(!urlCanonical){
      urlCanonical=this.createCanonicalURL();
    }
    const link: HTMLLinkElement = this.dom.querySelector('link[rel="canonical"]');
    if (link) link.href = urlCanonical;
  }
  canonicalDuplicate(categorySlug:string, slug:string){
    this.homeService.getDataCategories().subscribe((res)=>{
      const categoryFilter= res.result.categories.filter(category=> category.slug === categorySlug)[0];
      const slugFilter =  categoryFilter.services.filter(service=> slug.includes(service.slug))[0].slug;
      const canonical = `${this.envUrl}/${slugFilter}`;
      this.createCanonicalUrl(canonical);
    });
  }



  makePreloadImg(images: any[]){
    const preconnect = this.dom.querySelector("link[id='preconnectUrl']")
    if (this.router.url === '/' && images) {
      const {origin: imageHost } = new URL(images[0].image_desktop_webp);
      this.renderer.setAttribute(preconnect, 'href', imageHost);
      this.generatePreloadTagsForCarouselITem(images[0])
    }
  }

  generatePreloadTagsForCarouselITem(image: any) {
    const sizes = [
      {
        size: 'mobile',
        query: '(max-width: 767px)',
        url: 'image_mobile_webp'
      },
      {
        size: 'desktop',
        query: '(min-width: 1280px)',
        url: 'image_desktop_webp'
      },
    ];
    sizes.forEach(size => {
      const tag: HTMLLinkElement = this.dom.createElement('link');
      tag.setAttribute('as', 'image');
      tag.setAttribute('rel', 'preload');
      tag.setAttribute('media', size.query);
      tag.setAttribute('href', image[size.url]);
      this.dom.head.appendChild(tag);
    })
  }

   /**
   * Function to dynamically update goals in the head
   * @param meta typeOf MetaSettings
   * @author nicorobles0
   */
   updateMetas(meta: MetaSettings[]) {
    Object.keys( meta ).forEach( (metaItem: any) => {
      // Only works with metaTagt name property
      this.meta.updateTag({ name: metaItem, content: meta[`${metaItem}`] });
    })
  }
}

interface MetaSettings {
  text?: string
}
