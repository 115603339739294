import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-car-button-sumary',
  templateUrl: './car-button-sumary.component.html',
  styleUrls: ['./car-button-sumary.component.scss'],
})
export class CarButtonSumaryComponent{
  urlRedirect: string;

  @Input() countProducts: number = 0;

  constructor(
    private routes: Router
  ) {}


  /**
   * The function navigates to the shopping cart page.
   */
  goTo() {
    this.routes.navigate(['/carrito-de-compras']);
  }

  /**
   * This function reloads a component if a certain validation condition is met.
   * @param {string} validate - The `validate` parameter is a string that is used to determine whether
   * to reload a component or not. If the value of `validate` is `'delete'`, then the component will be
   * reloaded.
   */
  reloadComponent(validate: string) {
    if (validate === 'delete') {
      let currentUrl = this.routes.url;
      this.routes.routeReuseStrategy.shouldReuseRoute = () => false;
      this.routes.onSameUrlNavigation = 'reload';
      this.routes.navigate([currentUrl]);
    }
  }
}
