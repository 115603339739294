import { Component, OnInit, Inject, ViewChild, ElementRef, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SetDataAddressService } from './../../services-components/set-data-address/set-data-address.service';
import { DOCUMENT, isPlatformBrowser } from "@angular/common";

declare const google: any;

@Component({
  selector: 'app-modal-confirm-location',
  templateUrl: './modal-confirm-location.component.html'
})
export class ModalConfirmLocationComponent implements OnInit {

  addressDataForm: FormGroup;
  submitted: boolean = false;
  showErrorAdrresFound: string;
  @ViewChild('txtAutocompleteMaps', { static: false }) txtAutocompleteMaps: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId: object,
    public dialogRef: MatDialogRef<ModalConfirmLocationComponent>,
    private _setDataAddressService: SetDataAddressService,
  ) { }

  ngOnInit(): void {
    this.createFormAddress();
    this.autoCompleteAddress();
    if (isPlatformBrowser(this.platformId)) {
      this.showErrorAdrresFound = localStorage.getItem('tempDataAddressFound');
    }
  }

  /**
   * The function creates a form for entering an address with a required address field and an optional
   * complement address field.
   */
  createFormAddress(): void {
    this.addressDataForm = this.formBuilder.group({
      address: new FormControl(this.data.addressName, Validators.compose([
        Validators.required
      ])),
      complementAddress: new FormControl('', Validators.compose([]))
    });
  }

  /**
   * The `autoCompleteAddress` function sets up an autocomplete feature for an address input field
   * using the Google Maps API.
   */
  autoCompleteAddress(): void {
    setTimeout(() => {
      const geocoder = new google.maps.Geocoder();

      if (this.txtAutocompleteMaps !== undefined) {
        this.txtAutocompleteMaps.nativeElement.value = this.data.addressName;
        const map = new google.maps.Map(this.document.getElementById('mapGoogle'), {
          center: { lat: this.data.address.latitude, lng: this.data.address.longitude },
          zoom: 17,
          // streetViewControl: false,
          mapTypeControl: false
        });
        const beachMarker = new google.maps.Marker({
          map: map,
          position: { lat: this.data.address.latitude, lng: this.data.address.longitude },
          icon: {
            url: './assets/img/detail_service/pin 2.svg',
            scaledSize: new google.maps.Size(45, 45)
          },
          draggable: true
        });

        let autocomplete = new google.maps.places.Autocomplete(this.txtAutocompleteMaps.nativeElement);
        autocomplete.addListener('place_changed', () => {
          let place = autocomplete.getPlace();
          if (!place.geometry) {
            return;
          }
          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);  // Why 17? Because it looks good.
          }
          beachMarker.setPosition(place.geometry.location);
        });

        google.maps.event.addListener(beachMarker, 'dragend', () => {
          geocoder.geocode({ latLng: beachMarker.getPosition() }, (responses) => {
            const tempInputAddress = this.document.getElementById('txtAutocompleteMaps');
            tempInputAddress.value = responses[0].formatted_address;
            tempInputAddress.dispatchEvent(new Event('change'));
          });
        });
      }
    }, 1000);
  }

  /**
   * The function sets the value of an address input field and updates the form control with the new
   * value after a delay of 700 milliseconds.
   * @param {string} value - The value parameter is a string that represents the address value that
   * needs to be set.
   */
  setAddresValue(value: string) {
    this.addressDataForm.get('address').setValue(value);
    this.txtAutocompleteMaps.nativeElement.value = value;
    let address = value;
    setTimeout(() => {
      address = this.txtAutocompleteMaps.nativeElement.value;
      this.addressDataForm.get('address').setValue(address);
    }, 700);
  }

  /**
   * The function sends address information if the form is valid, otherwise it returns without sending
   * the information.
   * @returns void, which means it does not return any value.
   */
  sendAddressInformation(): void {
    this.submitted = true;
    if (this.addressDataForm.invalid) {
      return;
    }
    this._setDataAddressService.setDataAddress(this.addressDataForm.getRawValue());
    this.dialogRef.close();
  }
}
