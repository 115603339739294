import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ModalConfirmLocationComponent } from '@components/modal-confirm-location/modal-confirm-location.component';
import { SetDataAddressService } from '@services-components/set-data-address/set-data-address.service';
import { AuthService } from '@services/auth/auth.service';
import { CurrentLocationService } from '@services/currentLocation/current-location.service';
import { ServicesJelpitService } from '@services/services-jelpit/services-jelpit.service';
import {
  IsAddresOnCity,
  IsValidAddres,
} from '@helpers/location/locationHelper';
import Utilities from '../../utils/utilities';
declare const google: any;
declare const dataLayer: any;

@Component({
  selector: 'app-modal-location-address',
  templateUrl: './modal-location-address.component.html',
  styleUrls: ['./modal-location-address.component.scss'],
})
export class ModalLocationAddressComponent implements OnInit {
  @ViewChild('txtAutocompleteMaps', { static: false })
  txtAutocompleteMaps: ElementRef;
  selectedCity: string;
  cities: any = [];
  enabledProgressBar = false;
  mapControlAutoCompleteAddress = new FormControl('');
  showErrorPosition = false;
  submitted = false;
  addressDataForm: FormGroup;
  editAddress = true;
  addressValidate = false;
  errorMessage: any;
  error = false;
  address: any;
  private oneValidate = false;
  action: any = {
    postNewAddress: false,
    editAddress: false,
    editCurrentAddress: false,
  };

  location: any = [];
  dataAddress: any = [];
  idCity: any;
  fieldAvailable: any = [];

  maxLengthAddress: any;
  /**
   * Datos para manejo del mapa
   */
  lat: number;
  long: number;
  latitude: number;
  longitude: number;
  zoom = 15;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    clickableIcons: false,
  };
  markerOptions: google.maps.MarkerOptions;
  circleOptions: google.maps.CircleOptions = {
    draggable: false,
    editable: false,
    fillColor: '#aa97de',
    strokeOpacity: 0,
  };
  /**
   * @description Icono para el marcador
   */
  iconMark: any;
  // Radius
  radius = 1500;
  radiusLat = 0;
  radiusLong = 0;
  /**
   * @description Variable para validar si esta en el rango de direccion
   */
  confirmDirection = false;
  /**
   * @description Variable para validar checkbox
   */
  confirmDirectionChecked = false;
  firstView = true;
  messageMarkerDrag: any = '';
  newDetail: boolean = false;
  /**
   * @description Variables de Google Maps
   *
   * @currentCityByGoogle Almacena el nombre exacto de la ciudad proporcionado por Google
   * @currentCityOnGoogleMap Almacena el nombre exacto de la ciudad en donde se fija el marker en el mapa
   * @autocomleteInput Almacena la refencia al Input tipo Autocomplete de Google
   */
  currentCityByGoogle: string = '';
  currentCityOnGoogleMap: string = '';
  autocomleteInput: any;
  private addressComponents: string = '';
  private formattedAddress: string = '';

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModalLocationAddressComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private currentLocationService: CurrentLocationService,
    private servicesJelpitService: ServicesJelpitService,
    private _setDataAddressService: SetDataAddressService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.iconMark = './assets/img/detail_service/pin 2.svg';
    let image: google.maps.Icon = {
      url: this.iconMark,
      scaledSize: new google.maps.Size(40, 60),
      size: new google.maps.Size(40, 60),
    };
    this.markerOptions = {
      draggable: true,
      icon: image,
    };
    if (isPlatformBrowser(this.platformId)) {
      this.fieldAvailable = JSON.parse(localStorage.getItem('fieldsAvaliable'));
      this.cities = JSON.parse(localStorage.getItem('availableCities'));
    }
    this.createFormAddress().then(() => {
      this.fill();
      this.txtAutocompleteMaps.nativeElement.onpaste = (e) =>
        e.preventDefault();
    });
    this.autoCompleteAddress();
    dataLayer.push({
      event: 'virtualPage',
      title: 'JPW -ubicacion detalle servicio',
      UrlVP: '/ubicacion-detalle',
    });
  }

  /**
   * The function creates a form for address data and populates it with additional data if available.
   * @returns The function `createFormAddress()` returns a Promise that resolves to a boolean value
   * (`true`).
   */
  createFormAddress(): Promise<any> {
    return new Promise((resolve) => {
      let additionalValidate;
      if (!this.data.newDetail) {
        additionalValidate = JSON.parse(
          localStorage.getItem('dataTempAddress')
        );
      } else {
        additionalValidate = JSON.parse(localStorage.getItem('directionTemp'));
      }
      if (
        !this.cities.filter(
          (item: any) => additionalValidate?.city?.id === item.id
        )[0]
      ) {
        additionalValidate = null;
      }
      const validAdditionalData =
        additionalValidate?.is_address_has_additional_data
          ? additionalValidate?.is_address_has_additional_data
          : false;
      this.addressDataForm = this.formBuilder.group({});
      this.addressDataForm.addControl(
        'aditional_data',
        new FormControl(validAdditionalData, Validators.required)
      );
      this.validateElement(additionalValidate, validAdditionalData);
      if (additionalValidate) {
        additionalValidate.confirm_my_location =
          additionalValidate.confirm_my_location ?? false;
        this.confirmDirectionChecked =
          additionalValidate.confirm_my_location ?? false;
        this.addressComponents = additionalValidate.addressComponents || '';
        this.formattedAddress = additionalValidate.formattedAddress || '';
      }
      localStorage.setItem('directionTemp', JSON.stringify(additionalValidate));
      localStorage.setItem(
        'dataTempAddress',
        JSON.stringify(additionalValidate)
      );
      this.changeAditionalData(validAdditionalData);
      this.mapPosition();
      resolve(true);
    });
  }

  /**
   * The function `validateElement` adds form controls to an address form based on the type of element
   * and applies validation rules.
   * @param additionalValidate - additionalValidate is an object that contains additional validation
   * data for the form fields.
   * @param validAdditionalData - The parameter `validAdditionalData` is a boolean value that indicates
   * whether the additional data is valid or not. It is used to determine whether to apply additional
   * validation rules to the form controls or not.
   */
  validateElement(additionalValidate, validAdditionalData) {
    let addressData = this.validateEmptyAddress(additionalValidate);
    this.fieldAvailable.forEach((element) => {
      switch (element.type) {
        case 'city': {
          let nameCity = this.filterCity(additionalValidate);
          this.selectedCity = nameCity;
          this.addressDataForm.addControl(
            element.type,
            new FormControl(
              nameCity,
              Validators.compose(
                this.createValidator(element.required_form, [
                  Validators.required,
                ])
              )
            )
          );
          break;
        }
        case 'text':
          if (element.id === 13 || element.id === 'address') {
            this.addressDataForm.addControl(
              element.type,
              new FormControl(addressData.address, [
                Validators.required,
                this.validateAddress(),
                this.validateTextAddress(),
              ])
            );
          }
          break;
        case 'neighborhood_address':
          this.addressDataForm.addControl(
            element.type,
            new FormControl(
              addressData.neighborhood_address,
              Validators.compose(
                this.createValidator(element.required_form, [
                  Validators.required,
                  Validators.pattern('^[a-zA-ZÀ-ÿ0-9 ]+$'),
                ])
              )
            )
          );
          break;
        case 'complement_address':
          if (validAdditionalData) {
            this.addressDataForm.addControl(
              element.type,
              new FormControl(addressData.complement_address)
            );
          } else {
            this.addressDataForm.addControl(
              element.type,
              new FormControl(
                addressData.complement_address,
                Validators.compose(
                  this.createValidator(element.required_form, [
                    Validators.required,
                    Validators.pattern('^[a-zA-ZÀ-ÿ0-9 ]+$'),
                  ])
                )
              )
            );
          }
          break;
        case 'indication_address':
          this.addressDataForm.addControl(
            element.type,
            new FormControl(
              addressData.indication_address,
              Validators.compose(
                this.createValidator(element.required_form, [
                  Validators.required,
                  Validators.pattern('^[a-zA-ZÀ-ÿ0-9 ]+$'),
                ])
              )
            )
          );
          break;
      }
    });
  }

  /**
   * The function `validateEmptyAddress` takes an object `additionalValidate` as input and returns an
   * object `outputAddress` with empty string values for its properties if `additionalValidate` is
   * falsy, or with the corresponding values from `additionalValidate` if it is truthy.
   * @param additionalValidate - An object containing the following properties:
   * @returns an object called `outputAddress` with four properties: `address`, `neighborhood_address`,
   * `complement_address`, and `indication_address`.
   */
  validateEmptyAddress(additionalValidate) {
    let outputAddress = {
      address: '',
      neighborhood_address: '',
      complement_address: '',
      indication_address: '',
    };
    if (additionalValidate) {
      if (additionalValidate.address)
        outputAddress.address = additionalValidate.address;
      if (additionalValidate.neighborhood_address)
        outputAddress.neighborhood_address =
          additionalValidate.neighborhood_address;
      if (additionalValidate.complement_address)
        outputAddress.complement_address =
          additionalValidate.complement_address;
      if (additionalValidate.indication_address)
        outputAddress.indication_address =
          additionalValidate.indication_address;
    }
    return outputAddress;
  }

  /**
   * The function `filterCity` filters a list of cities based on a provided validation object and
   * returns the name of the filtered city.
   * @param additionalValidate - The `additionalValidate` parameter is an object that may contain a
   * `city` property.
   * @returns the name of the city that matches the provided city ID or object in the
   * `additionalValidate` parameter.
   */
  filterCity(additionalValidate) {
    let nameCity = '';
    if (additionalValidate?.city) {
      if (typeof additionalValidate.city === 'object') {
        if (this.cities[0].hasOwnProperty('name')) {
          nameCity = this.cities.filter(
            (item: any) => item.id === additionalValidate.city.id
          )[0].name;
          this.idCity = this.cities.filter(
            (item: any) => item.id === additionalValidate.city.id
          )[0].id;
        } else {
          nameCity = this.cities.filter(
            (item: any) => item.id === additionalValidate.city.id
          )[0].value;
          this.idCity = this.cities.filter(
            (item: any) => item.id === additionalValidate.city.id
          )[0].id;
        }
      } else if (this.cities[0].hasOwnProperty('name')) {
        nameCity = this.cities.filter(
          (item: any) => item.id === additionalValidate.city
        )[0].name;
        this.idCity = additionalValidate.city;
      } else {
        nameCity = this.cities.filter(
          (item: any) => item.id === additionalValidate.city
        )[0].value;
        this.idCity = additionalValidate.city;
      }
    }
    return nameCity;
  }

  /**
   * The `fill()` function checks the value of certain flags and fills the address data form
   * accordingly.
   */
  fill() {
    if (this.action.editAddress === true) {
      /*Vengo de modalSave y quiero guardar una nueva direccion */
      this.fillAddressDataForm(
        this.dataAddress
      ); /* Editar la direccion actual seleccionada */
    } else if (this.action.editCurrentAddress === true) {
      if (isPlatformBrowser(this.platformId)) {
        if (!this.data.newDetail) {
          this.fillAddressDataForm(
            JSON.parse(localStorage.getItem('dataTempAddress'))
          );
        } else {
          this.fillAddressDataForm(
            JSON.parse(localStorage.getItem('directionTemp'))
          );
        }
      }
    }
  }

  /**
   * The function `fillAddressDataForm` populates a form with address data, including city, latitude,
   * longitude, neighborhood, text, indication, and complement.
   * @param {any} dataFormAddress - The parameter `dataFormAddress` is an object that contains address
   * data. It may have the following properties:
   */
  fillAddressDataForm(dataFormAddress: any) {
    if (dataFormAddress != undefined) {
      if (dataFormAddress.city) {
        this.selectedCity = dataFormAddress.city.name;
        this.changeCity(this.selectedCity);
        this.getIdCity(this.selectedCity);
        setTimeout(() => {
          this.updateAutocompleteBounds();
        }, 1000);
      }
      this.location.latitude = dataFormAddress.latitude;
      this.location.longitude = dataFormAddress.longitude;
      this.fieldAvailable.forEach((element) => {
        switch (element.type) {
          case 'city':
            this.addressDataForm.get('city').setValue(this.selectedCity);
            break;
          case 'neighborhood_address':
            this.addressDataForm
              .get('neighborhood_address')
              .setValue(dataFormAddress.neighborhood_address);
            break;
          case 'text':
            this.action.editAddress === true
              ? this.addressDataForm
                .get('text')
                .setValue(dataFormAddress.address)
              : this.addressDataForm.get('text').setValue(this.address);
            break;
          case 'indication_address':
            this.addressDataForm
              .get('indication_address')
              .setValue(dataFormAddress.indication_address);
            break;
          case 'complement_address':
            this.addressDataForm
              .get('complement_address')
              .setValue(dataFormAddress.complement_address);
            break;
          default:
            break;
        }
      });
      this.mapPosition();
    } else {
      this.action.editAddress === true
        ? this.addressDataForm.get('text').setValue(dataFormAddress.address)
        : this.addressDataForm.get('text').setValue(this.address);
      this.action.postNewAddress = true;
      this.action.editAddress = false;
    }
  }

  /**
   * The function `getIdCity` searches for a city in an array of objects and assigns the corresponding
   * city ID to a variable.
   * @param {any} value - The value parameter is the value that you want to match against the name or
   * value property of each element in the cities array.
   */
  getIdCity(value: any): void {
    this.cities.forEach((element) => {
      if (element.hasOwnProperty('name')) {
        if (element.name === value) {
          this.idCity = element.id;
        }
      } else if (element.value === value) {
        this.idCity = element.id;
      }
    });
  }

  /**
   * The function "changeCity" is used to handle the event when a city is selected or changed, updating
   * the selected city, enabling a text input field, getting the ID of the selected city, storing the
   * selected city in local storage (if running in a browser), resetting certain form fields, and
   * updating the autocomplete bounds.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * changeCity function. It could be an event object from a user action, such as a click or a change
   * event.
   */
  changeCity(event: any): void {
    if (event.value) {
      this.selectedCity = event.value;
    } else {
      this.selectedCity = event;
    }
    this.addressDataForm.controls.text.enable();
    this.getIdCity(this.selectedCity);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('temporarySelectedCity', this.selectedCity);
    }
    if (this.addressDataForm.get('text')) {
      this.addressDataForm.get('text').setValue('');
    }
    if (this.addressDataForm.get('neighborhood_address')) {
      this.addressDataForm.get('neighborhood_address').setValue('');
    }
    if (this.addressDataForm.get('complement_address')) {
      this.addressDataForm.get('complement_address').setValue('');
    }
    if (this.addressDataForm.get('indication_address')) {
      this.addressDataForm.get('indication_address').setValue('');
    }
    this.oneValidate = false;
    this.updateAutocompleteBounds();
  }

  /**
   * The function `buildAdress` takes an event as input, updates some variables and performs some
   * validations based on the event's target value.
   * @param {any} event - The event parameter is of type any, which means it can be any type of value.
   * It is likely an event object that is passed to the function when an event is triggered, such as a
   * keypress or input event.
   */
  buildAdress(event: any): void {
    this.confirmDirection = false;
    let addressToAutocomplete: any;
    if (event.target.value !== '') {
      let auxEvent = event.target.value.charAt(event.target.value.length - 1);
      if (
        (!this.oneValidate && this.selectedCity !== undefined) ||
        (this.selectedCity !== undefined &&
          event.target.value.length < this.maxLengthAddress)
      ) {
        this.oneValidate = true;
        event.target.value = '';
        if (auxEvent === ' ' || auxEvent === ',') {
          addressToAutocomplete = this.selectedCity + ', ' + event.target.value;
        } else {
          addressToAutocomplete =
            this.selectedCity + ', ' + event.target.value + auxEvent;
        }
        this.maxLengthAddress = addressToAutocomplete.length;
        this.registerAdress(addressToAutocomplete);
      }
      let eve = event.target.value;
      if (eve.length <= this.maxLengthAddress) {
        this.addressValidate = true;
      } else {
        this.addressValidate = false;
      }
    }
  }

  /**
   * The function `registerAdress` sets the value of a form field, updates a text input field, and
   * performs some additional operations related to address validation and map positioning.
   * @param {any} value - The `value` parameter is the address value that needs to be registered.
   */
  registerAdress(value: any): void {
    this.addressDataForm.get('text').setValue(value);
    this.txtAutocompleteMaps.nativeElement.value = value;
    let address = value;
    setTimeout(() => {
      address = this.txtAutocompleteMaps.nativeElement.value;
      this.addressDataForm.get('text').setValue(address);
      if (address.length > this.maxLengthAddress) this.addressValidate = false;
      if (isPlatformBrowser(this.platformId))
        localStorage.removeItem('dataTempCoordenates');
      this.mapPosition();
    }, 900);
  }

  /**
   * The function `autoCompleteAddress` sets up an autocomplete feature for an input field using the
   * Google Maps Places Autocomplete API.
   */
  autoCompleteAddress(): void {
    setTimeout(() => {
      const input = this.txtAutocompleteMaps;
      if (input !== undefined) {
        const options: any = {
          componentRestrictions: { country: 'co' },
          types: ['address'],
          strictBounds: true,
        };
        this.autocomleteInput = new google.maps.places.Autocomplete(
          input.nativeElement,
          options
        );
        this.autocomleteInput.addListener(
          'place_changed',
          () => {
            this.addressDataForm.updateValueAndValidity();
            const place = this.autocomleteInput.getPlace();
            if (!place.geometry) {
              if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem('tempDataAddressFound', 'false');
              }
            } else if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem('tempDataAddressFound', 'true');
            }
          },
          false
        );
      }
    }, 1000);
  }

  /**
   * The `markerDragEnd` function updates the latitude and longitude of a marker on a map, calculates
   * the distance between the marker and a given location, and stores the temporary coordinates in
   * local storage if the distance is valid.
   * @param {any}  - The  parameter is an object that contains information about the event
   * that triggered the marker drag. In this case, it is expected to have a property called "latLng"
   * which represents the new latitude and longitude coordinates of the marker after it has been
   * dragged.
   */
  markerDragEnd($event: any) {
    const geocoder = new google.maps.Geocoder();
    this.lat = $event.latLng.lat();
    this.long = $event.latLng.lng();
    this.location.lat = this.lat;
    this.location.lng = this.long;
    const distance = this.getDistanceBetween(
      this.lat,
      this.long,
      this.latitude,
      this.longitude
    );
    if (distance) {
      geocoder.geocode({ latLng: $event.latLng }, (responses) => {
        const latLong = responses[0].geometry.location;
        const tempCoordenates = {
          lat: latLong.lat(),
          lgn: latLong.lng(),
        };
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem(
            'dataTempCoordenates',
            JSON.stringify(tempCoordenates)
          );
        }
      });
      this.confirmDirection = true;
      this.confirmDirectionChecked = true;
      this.messageMarkerDrag = '';
    } else {
      this.confirmDirectionChecked = false;
      this.confirmDirection = false;
      this.messageMarkerDrag = 'Ajusta la ubicación correcta en el mapa';
      localStorage.removeItem('dataTempCoordenates');
    }
  }

  /**
   * The function calculates the distance between two sets of latitude and longitude coordinates using
   * the Google Maps API and returns true if the distance is within a specified radius.
   * @param lat1 - The latitude of the first location.
   * @param long1 - The parameter "long1" represents the longitude of the first location.
   * @param lat2 - The parameter "lat2" represents the latitude of the second location.
   * @param long2 - The parameter "long2" represents the longitude of the second location.
   * @returns a boolean value. It returns true if the distance between the two given coordinates (lat1,
   * long1) and (lat2, long2) is less than or equal to the value of "this.radius". Otherwise, it
   * returns false.
   */
  getDistanceBetween(lat1, long1, lat2, long2) {
    let from = new google.maps.LatLng(lat1, long1);
    let to = new google.maps.LatLng(lat2, long2);
    if (!google.maps.geometry) return true;
    if (
      google.maps.geometry.spherical.computeDistanceBetween(from, to) <=
      this.radius
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * The `mapPosition()` function retrieves the current location based on an address or selected city
   * and updates the latitude, longitude, and other related properties accordingly.
   */
  mapPosition() {
    this.confirmDirection = true;
    const address = this.addressDataForm.get('text').value || this.selectedCity;
    this.currentLocationService.getCurrentLocation(address).subscribe(
      (response: any) => {
        this.location = response;
        this.currentCityOnGoogleMap =
          this.location?.results?.formatted_address || '';
        this.addressDataForm.controls.text.setValue(
          this.txtAutocompleteMaps.nativeElement.value.trim()
        );
        if (this.txtAutocompleteMaps !== undefined) {
          this.txtAutocompleteMaps.nativeElement.value =
            this.addressDataForm.get('text').value;
          this.latitude = this.location.lat;
          this.longitude = this.location.lng;
          this.radiusLat = this.latitude;
          this.radiusLong = this.longitude;
          this.lat = this.latitude;
          this.long = this.longitude;
          this.addressComponents = this.location?.results?.address_components;
          this.formattedAddress = this.location?.results?.formatted_address;
          this.confirmDirection = true;
          let distance = this.getDistanceBetween(
            this.lat,
            this.long,
            this.latitude,
            this.longitude
          );
          if (distance) {
            this.confirmDirection = true;
            this.confirmDirectionChecked = false;
            this.messageMarkerDrag = '';
          } else {
            this.confirmDirectionChecked = false;
            this.confirmDirection = false;
            this.messageMarkerDrag = 'Ajusta la ubicación correcta en el mapa';
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * The function `getCurrentPosition` retrieves the current geolocation of the user, updates the data
   * based on the current position, and creates a data layer for analytics purposes.
   */
  //START-SCAN
  getCurrentPosition(): void {
    this.enabledProgressBar = true;
    navigator.geolocation.getCurrentPosition((position: any) => {
      this.location.lat = position.coords.latitude;
      this.location.lng = position.coords.longitude;
      let latlng = {
        lat: parseFloat(this.location.lat),
        lng: parseFloat(this.location.lng),
      };
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: latlng,
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            this.updateDataOnCurrentPosition(results);
            this.enabledProgressBar = false;
          } else {
            this.showErrorPosition = true;
            this.enabledProgressBar = false;
          }
        }
      );
    });

    // Tag 9B
    this.createDataLayerAditional({
      event: 'ga_event',
      category: 'detalle servicio',
      action: 'JPW - detalles servicio mapa',
      label: 'Utilizar mi ubicación actual',
      nombreServicio: this.data.basicProductInfo.name,
      codigoServicio: this.data.basicProductInfo.id,
      categoria: this.data.basicProductInfo.category,
      audiencia: 'personas',
    });
  }
  //END-SCAN

  /**
   * The function updates data based on the current position and checks if the selected city matches
   * the location.
   * @param results - An array of results obtained from a geocoding API call. The results contain
   * information about the current position, such as the formatted address and address components.
   */
  updateDataOnCurrentPosition(results) {
    if (results[1]) {
      let result;
      results[1].address_components.forEach((item: any) => {
        for (let s of item.types) {
          if (s === 'administrative_area_level_2' || s === 'locality') {
            result = item.long_name;
          }
        }
      });
      if (result.includes(this.selectedCity.split(' ')[0])) {
        this.addressDataForm.get('text').setValue(results[1].formatted_address);
        this.addressDataForm.get('neighborhood_address').setValue('');
        this.latitude = this.location.lat;
        this.longitude = this.location.lng;
        this.radiusLat = this.latitude;
        this.radiusLong = this.longitude;
        this.lat = this.latitude;
        this.long = this.longitude;
        this.addressComponents = results[1]?.address_components;
        this.formattedAddress = results[1]?.formatted_address;
        this.confirmDirection = true;
      } else {
        this.confirmDirectionChecked = false;
        this.confirmDirection = false;
        this.messageMarkerDrag =
          'Tu ubicación no concuerda con la ciudad seleccionada';
      }
    }
  }

  /**
   * The function opens a dialog box with a map and a confirmation button, passing the selected address
   * and its coordinates as data.
   */
  openDialogCompleteAddress(): void {
    this.servicesJelpitService
      .getCoordinates(this.mapControlAutoCompleteAddress.value)
      .then((responseCoordinates: any) => {
        this.dialog.open(ModalConfirmLocationComponent, {
          panelClass: ['full-location', 'modal-map'],
          width: '520px',
          data: {
            address: responseCoordinates,
            addressName: this.mapControlAutoCompleteAddress.value,
          },
        });

        this.dialogRef.close();
      });
  }

  /**
   * The function `buildAddressNoLoggin` takes an `idCity` parameter, filters the `cities` array to
   * find the city with the matching id, and returns an object containing the id and name of the city.
   * @param {any} idCity - The `idCity` parameter is the identifier of a city.
   * @returns The function `buildAddressNoLoggin` returns an object containing the id and name of a
   * city.
   */
  buildAddressNoLoggin(idCity: any): any {
    let element = this.cities.filter(function (item) {
      return item.id == idCity;
    });
    let objectCity: any;
    if (element[0]?.hasOwnProperty('name')) {
      objectCity = {
        id: idCity,
        name: element[0].name,
      };
    } else {
      objectCity = {
        id: idCity,
        name: element[0].value,
      };
    }
    return objectCity;
  }

  /**
   * The function `topostNewAddress` posts a new address to a service and sets the data address based
   * on the response.
   * @param {any} address - The address parameter is of type any, which means it can accept any data
   * type.
   */
  topostNewAddress(address: any): void {
    this.servicesJelpitService.postUseraddress(address).subscribe(
      (response: any) => {
        this._setDataAddressService.setDataAddress(response.result);
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('selectionAddress', '0');
        }
      },
      (error: Error) => {
        console.log('Error al hacer post new address', error);
      }
    );
  }

  /**
   * The function `sendAddressInformation` is used to validate and save address information.
   * @returns If the `addressDataForm` is valid, the function will return `void` (nothing). If the
   * `addressDataForm` is invalid or the `addressValidate` is true, the function will return early and
   * not execute the remaining code.
   */
  sendAddressInformation(): void {
    if (this.addressDataForm.valid) {
      this.data.validate = true;
      this.validateCleanForm();

      this.submitted = true;
      if (this.addressDataForm.invalid || this.addressValidate === true) {
        return;
      }

      // Tag 9C
      this.createDataLayerAditional({
        event: 'ga_event',
        category: 'detalle servicio',
        action: 'JPW - confirmar ubicacion',
        label: 'CONFIRMAR DIRECCIÓN',
        nombreServicio: this.data.basicProductInfo.name,
        codigoServicio: this.data.basicProductInfo.id,
        categoria: this.data.basicProductInfo.category,
        audiencia: 'personas',
      });

      let addressForm: any = {};
      this.fieldAvailable.forEach((element) => {
        switch (element.type) {
          case 'text':
            addressForm.address = this.addressDataForm.get('text').value;
            break;
          case 'complement_address':
            addressForm.complement_address =
              this.addressDataForm.controls.complement_address.value;
            break;
          case 'neighborhood_address':
            addressForm.neighborhood_address =
              this.addressDataForm.controls.neighborhood_address.value;
            break;
          case 'city':
            addressForm.city =
              this._authService.isLoggedIn() === true
                ? this.idCity
                : this.buildAddressNoLoggin(this.idCity);
            break;
          case 'indication_address':
            addressForm.indication_address =
              this.addressDataForm.controls.indication_address.value;
            break;
          default:
            break;
        }
      });

      const address = {
        ...addressForm,
        latitude: this.location.lat.toString(),
        longitude: this.location.lng.toString(),
        is_address_has_additional_data:
          this.addressDataForm.get('aditional_data').value,
        confirm_my_location: this.confirmDirectionChecked,
        address_components: this.addressComponents,
        formatted_address: this.formattedAddress,
      };
      this.saveNewAddress(address);
    } else {
      this.goBack();
    }
  }

  /**
   * The function `validateCleanForm()` checks if certain form fields are empty or contain only
   * whitespace, and sets their values to empty strings if necessary. It also checks if the `text`
   * field is equal to a single whitespace character and updates its value accordingly.
   */
  validateCleanForm() {
    let controls = [
      'neighborhood_address',
      'indication_address',
      'complement_address',
    ];
    controls.forEach((control) => {
      if (
        this.addressDataForm.get(control) &&
        (this.addressDataForm.get(control).value === null ||
          this.addressDataForm.get(control).value.trim().length === 0)
      )
        this.addressDataForm.get(control).setValue('');
    });

    if (this.addressDataForm.get('text').value === ' ') {
      this.addressDataForm.controls.text.setValue(
        this.txtAutocompleteMaps.nativeElement.value.trim()
      );
      this.addressValidate = true;
    } else {
      this.addressValidate = false;
    }
  }

  /**
   * The function `saveNewAddress` checks if a new address should be saved, and if so, it either posts
   * the address or sets it in a service depending on whether the user is logged in or not. If an
   * existing address is being edited, it either saves the address details or sets them in a service
   * depending on the user's login status.
   * @param address - The "address" parameter is an object that represents the new address to be saved.
   * It likely contains properties such as street, city, state, and zip code.
   */
  saveNewAddress(address) {
    if (this.action.postNewAddress === true) {
      /* guardar nueva direccion*/
      if (this._authService.isLoggedIn() === true) {
        this.topostNewAddress(address);
        this.dialogRef.close();
      } else {
        this._setDataAddressService.setDataAddress(address);
        this.dialogRef.close();
      }
    } else if (
      this.action.editAddress === true ||
      this.action.editCurrentAddress === true
    ) {
      if (this._authService.isLoggedIn() === true) {
        this.saveAddressNewDetail(address);
      } else {
        this.dialogRef.close();
        this._setDataAddressService.setDataAddress(address);
      }
    }
  }

  /**
   * The function `saveAddressNewDetail` saves a new address detail by checking if the platform is a
   * browser, retrieving data from local storage, and making an HTTP request to update the user's
   * address.
   * @param address - The "address" parameter is an object that represents the address details. It
   * likely contains properties such as street, city, state, postal code, and country.
   * @returns The function does not explicitly return anything.
   */
  saveAddressNewDetail(address) {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.data.newDetail) {
        if (!this.dataAddress.id) {
          this.dataAddress = JSON.parse(
            localStorage.getItem('dataTempAddress')
          );
        }
      } else if (!this.dataAddress.id) {
        this.dataAddress = JSON.parse(localStorage.getItem('directionTemp'));
      }
    }

    if (this.dataAddress.id === undefined) {
      this.topostNewAddress(address);
      this.dialogRef.close();
      return;
    }
    localStorage.setItem('dataTempAddress', JSON.stringify(address));
    localStorage.setItem('directionTemp', JSON.stringify(address));
    this.servicesJelpitService
      .putUseraddress(address, this.dataAddress.id)
      .subscribe(
        (response: any) => {
          this.error = false;
          this.dialogRef.close();
          this._setDataAddressService.setDataAddress(response.result);
        },
        (error: Error) => {
          this.error = true;
          this.errorMessage = error['error']['messages'];
        }
      );
  }

  /**
   * The function creates a validator array based on the required form and a list of validations.
   * @param {number} required_form - A number indicating the form that is required. If this parameter
   * is falsy (e.g. 0, null, undefined), an empty array will be returned. Otherwise, the validations
   * array will be returned.
   * @param {ValidatorFn[]} validations - An array of functions that perform validation on a form. Each
   * function takes in a form value as input and returns an error message if the validation fails, or
   * null if the validation passes.
   * @returns an array of ValidatorFn functions.
   */
  createValidator(
    required_form: number,
    validations: ValidatorFn[]
  ): ValidatorFn[] {
    if (!required_form) {
      return [];
    }
    return [...validations];
  }

  /**
   * The function checks if a form field is required based on its validator.
   * @param dataField - The `dataField` parameter is a string that represents the name of a field in a
   * form.
   * @returns a boolean value. It returns true if the form field has a required validator, and false if
   * it does not.
   */
  isRequiredField(dataField) {
    const form_field = this.addressDataForm.get('' + dataField + '');
    if (!form_field?.validator) {
      return false;
    }
    const validator = form_field.validator({} as AbstractControl);
    return validator?.required;
  }

  /**
   * The function checks if the addressDataForm is invalid and scrolls to the first error if it is.
   */
  isErrorForm() {
    this.submitted = true;
    if (this.addressDataForm.invalid) {
      this.scrollToError();
    }
  }

  /**
   * The scrollToError function finds the first element with an error in a form and scrolls to it.
   */
  scrollToError(): void {
    const firstElementWithError: HTMLElement = document.querySelector(
      '.ng-invalid[formControlName]'
    );
    let u = new Utilities();
    u.getTopOffset(firstElementWithError);
  }

  /**
   * The function "changeAditionalData" updates the validation rules and value of a form field based on
   * a boolean event.
   * @param {boolean} event - A boolean value indicating whether additional data is required or not.
   */
  changeAditionalData(event: boolean) {
    const inputDataForm = this.addressDataForm.get('complement_address');
    if (!event) {
      inputDataForm.setValidators(Validators.required);
      inputDataForm.updateValueAndValidity();
    } else {
      inputDataForm.clearValidators();
      inputDataForm.setValue('');
      inputDataForm.updateValueAndValidity();
    }
  }

  goBack() {
    this.firstView = true;
    this.autoCompleteAddress();
    this.isErrorForm();
  }

  /**
   * The function checks if the address form is valid and not in a validation state, and if so, it sets
   * a flag to indicate that the first view is complete and triggers a Google Analytics event.
   * Otherwise, it calls another function to handle form errors.
   */
  continue() {
    if (this.addressDataForm.valid && !this.addressValidate) {
      this.firstView = false;
      // Tag 9A
      this.createDataLayer(
        'ga_event',
        'detalle servicio',
        'JPW - detalle servicio ubicacion',
        'CONTINUAR'
      );
    } else {
      this.isErrorForm();
    }
  }

  /**
   * @description Crear data layer
   */
  createDataLayer(
    event: string,
    category: string,
    action: string,
    label: string
  ) {
    dataLayer.push({
      event: event,
      category: category,
      action: action,
      label: label,
    });
  }

  /**
   * @description Crear data layer adicionales
   */
  createDataLayerAditional(data: {
    event: string;
    category: string;
    action: string;
    label: string;
    nombreServicio: string;
    codigoServicio: string;
    categoria: string;
    audiencia: string;
  }) {
    dataLayer.push({
      event: data.event,
      category: data.category,
      action: data.action,
      label: data.label,
      'nombre servicio': data.nombreServicio,
      'codigo servicio': data.codigoServicio,
      categoria: data.categoria,
      audiencia: data.audiencia,
    });
  }

  /**
   * The function `validateAddress()` is a TypeScript function that validates an address by checking if
   * it is on the current city according to Google Maps.
   * @returns The function `validateAddress()` returns a validation function that takes a control as an
   * argument and returns either a `ValidationErrors` object or `null`.
   */
  validateAddress() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const isValid: boolean = IsAddresOnCity(
        this.currentCityByGoogle,
        this.currentCityOnGoogleMap
      );
      return !isValid ? { validAddress: true } : null;
    };
  }

  /**
   * The function `validateTextAddress` is a TypeScript function that returns a validation function for
   * a text address field, which checks if the address is valid based on the city and text values.
   * @returns The function `validateTextAddress()` returns a validation function.
   */
  validateTextAddress() {
    return (control: AbstractControl): ValidationErrors | null => {
      return !IsValidAddres(
        this.addressDataForm?.get('city')?.value,
        this.addressDataForm?.get('text')?.value
      )
        ? { ValidAddressText: true }
        : null;
    };
  }

  /**
   * The function updates the autocomplete bounds based on the selected city.
   */
  updateAutocompleteBounds() {
    this.currentLocationService
      .getCityByGoogle(this.selectedCity)
      .subscribe(({ formatted_address, geometry }): any => {
        this.currentCityByGoogle = formatted_address;
        const newBounds = this.currentLocationService.getBounds(geometry);
        this.autocomleteInput?.unbind('bounds');
        this.autocomleteInput?.setBounds(newBounds);
        this.mapPosition();
      });
  }
}
