<div class="banner-detail" *ngIf="showBanner" [ngClass]="{'shorBanner': shortBanner, 'city-banner': cityBanner}">
    <picture class="img-mobile">
        <source height="1440" width="660" *ngIf="infoBanner.desktop_jpg2000_image !== ''" media="(min-width:1280px)" type="image/jp2" [srcset]="infoBanner.desktop_jpg2000_image" />
        <source height="170" width="360" *ngIf="infoBanner.desktop_jpg2000_image !== ''" media="(max-width: 767px)" type="image/jp2" [srcset]="infoBanner.mobile_jpg2000_image">

        <source height="1440" width="660" *ngIf="infoBanner.imageDesktopWebp !== ''" media="(min-width:1280px)" type="image/webp" [srcset]="infoBanner.imageDesktopWebp" />
        <source height="170" width="360" *ngIf="infoBanner.imageMobileWebp !== ''" media="(max-width: 767px)" type="image/webp" [srcset]="infoBanner.imageMobileWebp">

        <img defaultImage="./assets/img/gif/loading-small.gif" [lazyLoad]="infoBanner.imageDesktop" [alt]="infoBanner.alt">
    </picture>
    <div class="container_banner">
        <div class="icon_service" *ngIf="infoBanner.icon">
            <picture>
                <source media="(max-width: 767px)" [srcset]="infoBanner.icon" height="60" width="60">
                <img [src]="infoBanner.icon" [alt]="infoBanner.altIcon">
            </picture>
        </div>
        <h1 class="title">{{infoBanner.title}}</h1>
        <div class="description" *ngIf="infoBanner.description" [innerHTML] = "infoBanner.description"></div>
    </div>
    <div class="cinta"></div>
</div>