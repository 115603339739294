import { Component, Input } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
  selector: 'app-card-testimonial',
  templateUrl: './card-testimonial.component.html',
  styleUrls: ['./card-testimonial.component.scss'],
})
export class CardTestimonialComponent {
  @Input()
  testimonialInfo: any = [];

  sliceTestimonial: number = 4;

  public masonryOptions: NgxMasonryOptions = {
    gutter: 20,
  };

  /**
   * The function sets the value of "sliceTestimonial" to 10.
   */
  showMore() {
    this.sliceTestimonial = 10;
  }
}
