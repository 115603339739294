import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Input,
  Inject,
  PLATFORM_ID,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';

declare const dataLayer: any;

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent implements OnChanges {
  private utm = 'utm_source=jelpit&utm_medium=header&utm_campaign=boton_flotante';
  private administrationFeeUrlMaster = "https://web-conjuntos.jelpit.com/pagar-mi-administracion#/";
  public administrationFeeUrl: string = `${environment.administrationFeeUrl ?? this.administrationFeeUrlMaster}${environment.production ? "?"+this.utm : ""}`;

  listServicesMenu: any = [];
  listJelpitpacksMenu: any = [];
  listJelpitResidentialMenu: any = [];
  alliesMenu: any = {};
  menuNames: any = {};
  show_menu_m: boolean;
  @Input() dataMenu: any = [];

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    let menuD = changes.dataMenu;
    if (menuD?.currentValue[0]) {
      this.buildMenuData(menuD.currentValue);
    }
  }

  /**
   * The function "buildMenuData" takes in a data object and assigns specific elements from the object
   * to different variables based on their names.
   * @param data - The `data` parameter is an array of objects. Each object represents a category in a
   * menu. Each object has a `name` property which represents the name of the category, and a
   * `sub_categories` property which is an array of objects representing sub-categories within the
   * category. Each sub-category object
   */
  buildMenuData(data) {
    data.forEach(element => {
      switch (element.name) {
        case 'Conjuntos': {
          this.listJelpitResidentialMenu = element;
          break;
        }
        case 'Personas': {
          this.menuNames = element.menu_names;
          element.sub_categories.forEach(type => {
            switch (type.name) {
              case 'Jelpit Packs': {
                this.listJelpitpacksMenu = type;
                break;
              }
              case 'Servicios': {
                this.listServicesMenu = type;
                break;
              }
              case 'Aliados': {
                this.alliesMenu = type;
                break;
              }
            }
          });
          break;
        }
      }
    });
  }

  closeMenu(tagLabel?) {
    let header = this.document.getElementById('menu_item');
    header?.classList.remove('menu_opened');
    this.eventMenuTag(tagLabel);
  }

  /**
   * This function sets a data type in local storage based on a given item and closes a menu.
   * @param item - The "item" parameter is a number that determines which type of data should be set in
   * the local storage. If it is equal to 2, the data type will be set to "jelpitpacks". Otherwise, it
   * will be set to "servicios".
   * @param {string} textLink - textLink is a string parameter that represents the text link that was
   * clicked to trigger the setType() function. It is used to close the menu after the data type has
   * been set.
   */
  setType(item) {
    if (isPlatformBrowser(this.platformId)) {
      if (item === 2) {
        localStorage.setItem('dataType', 'jelpitpacks');
      } else {
        localStorage.setItem('dataType', 'servicios');
      }
    }
  }

  /**
   * The function `eventMenuTag` pushes an event to the dataLayer with specific category, action, and
   * label values.
   * @param text - The "text" parameter in the "eventMenuTag" function represents the label or text
   * associated with the menu item that triggered the event. It is used as the value for the "label"
   * property in the dataLayer.push() method.
   */
  eventMenuTag(text) {
    //47A
    dataLayer.push({
      event: 'ga_event',
      category: 'home menu',
      action: 'JPW - home menu',
      label: text,
    });
  }

  /**
   * The function `eventResidential` pushes an event to the dataLayer with specific category, action,
   * and label values.
   * @param text - The "text" parameter is a string that represents the label for the event. It is used
   * to provide additional information or context about the event being tracked.
   */
  eventResidential(text) {
    //47E
    dataLayer.push({
      event: 'ga_event',
      category: 'home menu',
      action: 'JPW - home menu conjuntos',
      label: text,
    });
  }

  /**
   * The function creates a data layer floating button and pushes an event to the data layer with
   * specific category, action, and label values.
   */
  createDataLayerFloatingButton() {
    dataLayer.push({
      event: 'ga_event',
      category: 'home header',
      action: 'JPW - header boton flotante',
      label: 'pagar administracion',
    });
  }

  /**
   * The function redirects the user to a specific URL based on the element and father parameters, and
   * also updates the data layer and closes the menu.
   * @param element - The "element" parameter is an object that represents a menu item. It contains
   * properties such as "name", "url", and "slug".
   * @param [father] - The "father" parameter is a string that represents the category or parent
   * element of the current element. It is used to determine the additional URL path that needs to be
   * appended to the redirect URL.
   */
  redirectUrl(element, father?) {
    let additionalUrl = '';
    if (father === 'Servicios')
      additionalUrl = 'servicios/';
    else if (father === 'Jelpit Packs') {
      additionalUrl = 'paquetes-servicios/';
    }
    this.closeMenu(father);
    if (element.url) {
      window.location.href = element.url;
    } else {
      this.router.navigate([`${additionalUrl}${element.slug}`]);
    }
  }

  /**
   * The function "showMenu" toggles the visibility of a menu item by adding or removing a CSS class.
   */
  showMenu() {
    let header = this.document.getElementById('menu_item');
    header.classList.add('menu_opened');
    this.show_menu_m = !this.show_menu_m;
  }

  /**
   * The function toggles the 'dropdown_open' class on an HTML element.
   * @param {HTMLElement} data - The parameter `data` is an HTMLElement, which represents an HTML
   * element in the DOM (Document Object Model). It can be any HTML element, such as a div, span,
   * button, etc.
   */
  toggleList(data: HTMLElement) {
    let open = data.classList.contains('dropdown_open');
    if (open) data.classList.remove('dropdown_open');
    else data.classList.add('dropdown_open');
  }
}
