import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from "@angular/platform-browser";

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }
  /**
   * The function `transform` in TypeScript takes a string value and a type, then returns a sanitized
   * version based on the specified type using Angular's security sanitizer.
   * @param {string} value - The `value` parameter in the `transform` function is a string that
   * represents the content you want to transform into a safe format based on the specified `type`.
   * @param {string} type - The `type` parameter in the `transform` function specifies the type of
   * security context to be applied when sanitizing the `value`. It can have one of the following
   * values:
   * @returns The `transform` function returns a value that has been sanitized based on the specified
   * type. The return value can be of type `SafeHtml`, `SafeStyle`, `SafeScript`, `SafeUrl`, or
   * `SafeResourceUrl`, depending on the type parameter provided to the function.
   */
  //START-SCAN
  transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this._sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }
  }
  //END-SCAN

}