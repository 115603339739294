export const IsAddresOnCity = (city: string, address: string): boolean => {
  const splitCity: string[] = sanitizeText(city).split(',');
  const sanitizeAddress: string = sanitizeText(address);
  if (sanitizeAddress.includes(splitCity[0])) {
    return true;
  } else {
    return false;
  }
};

export const IsSavedAddresOnCity = (city: string, address: string): boolean => {
  const parseCity = city.trim().replace(' ', ',');
  const splitCity: string[] = sanitizeText(parseCity).split(',');
  const sanitizeAddress: string = sanitizeText(address);
  const sanitizeCity: string = sanitizeText(city);
  if (sanitizeAddress.includes(splitCity[0])  || sanitizeAddress.includes(sanitizeCity)) {
    return true;
  } else {
    return false;
  }
};

export const IsValidAddres = (city: string, address: string): boolean => {
  let isError: boolean | null = null;
  const actualCity: string = city || '';
  let actualAddress: string = address || '';
  actualAddress = actualAddress?.replace(/\s\s+/g, ' ');
  actualAddress = actualAddress?.replace(/[^a-zA-Z0-9\#\-\.\s]/g, '');
  actualAddress = actualAddress?.replace(/\.\.+/g, '');
  actualAddress = actualAddress?.replace(/\#\#+/g, '');
  actualAddress = actualAddress?.replace(/\-\-+/g, '');
  isError = actualAddress === actualCity ? true : false;
  isError = actualAddress.length > actualCity.length + 3 ? true : false;  
  return isError;
};

const sanitizeText = (text: string): string => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLocaleLowerCase()
    .trim();
};
