<img
  src="../../../../assets/img/tc-davivienda/Fondo.png"
  alt="Davivienda"
  class="background"
/>
<header>
  <div class="header_logo">
    <img
      class="logo"
      src="../../../../assets/img/tc-davivienda/Logo_davivienda_casa.svg"
      alt="davivienda logo"
    />
    <img
      class="name"
      src="../../../../assets/img/tc-davivienda/davivienda_name.png"
      alt="davivienda"
    />
  </div>
  <lib-tb-button [custom]="buttonConfig"></lib-tb-button>
</header>
<div class="text-contc">
  <div>
    <img
      src="../../../../assets/img/tc-davivienda/Kv.png"
      alt=""
      class="icon-banner"
    />
  </div>
  <div class="title-cont">
    <h1 class="title2">
      ¡Hola,<br /><span>{{ tcInfoUser.name }}!</span>
    </h1>
    <hr class="separator" />
    <h3 class="helper_text">
      Para
      <mark class="encased">activar los beneficios gratuitos</mark> que<br />
      Davivienda y Servicios Bolivar tienen para usted, seleccione la
      <span class="bold">Tarjeta de Crédito</span>
    </h3>
  </div>
</div>
<app-credit-card-carousel
  [cards]="carouselConfig"
  (selectedCard)="setActualCard($event)"
></app-credit-card-carousel>
<div class="tc-content">
  <div class="cards-services">
    <h2 class="title-2">TIENES {{ numberServices }} SERVICIOS POR REDIMIR</h2>
    <p class="desc-2">{{ textToSelectecServices }}</p>
    <div class="card-container">
      <div
        class="card"
        [ngClass]="{
          'disable-card': card.quantity <= 0
        }"
        *ngFor="let card of selectedCard?.services"
        (click)="sendService(card)"
        onkeypress=""
      >
        <img
          class="card-background"
          [src]="card.active_image"
          alt="Servicios Jelpit"
        />
        <img
          class="card-footer"
          src="../../../../assets/img/tc-davivienda/Vector 4.svg"
          alt="Servicios Jelpit"
        />
        <div class="card-content">
          <div class="quantity">
            <p>Asistencias: {{ card.quantity }}</p>
          </div>
          <h3 class="card_title">{{ card.name }}</h3>
          <div class="bottom">
            <h4 class="card_description">
              {{ card.description }}
            </h4>
            <p class="text_button">Quiero redimirlo</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="additional-info">
  <div
    class="pqrs desktop"
    onkeypress=""
    [innerHTML]="tcInfoUser.pqrs | safe : 'html'"
    (click)="tagAyuda('pqrs desktop')"
  ></div>
  <div
    class="pqrs mobile"
    onkeypress=""
    [innerHTML]="tcInfoUser.pqrsMobile | safe : 'html'"
    (click)="tagAyuda('pqrs mobile')"
  ></div>
  <div
    class="technicalSupport"
    onkeypress=""
    [innerHTML]="doubts | safe : 'html'"
    (click)="tagAyuda('technicalSupport')"
  ></div>
  <div class="buyServices" onkeypress="" (click)="tagAyuda('buyServices')">
    <p
      class="content"
      [innerHTML]="tcDaviviendaHome?.buy_services | safe : 'html'"
    ></p>
    <div class="button" routerLink="/servicios">
      <i class="fa-solid fa-hand-point-right"></i>
      <p>¡Aquí!</p>
    </div>
  </div>
</div>
<footer class="footer">
  <div class="disclamer-davivienda">
    <div class="cobranding_logo">
      <img
        src="../../../../assets/img/tc-davivienda/Logo Davivienda-Servicios Bolivar.svg"
        alt="Davivienda"
        class="davivienda"
      />
    </div>
    <div class="text-davivienda">
      <p>
        {{ textFooterDavivienda }}
      </p>
    </div>
    <div class="footer-disclamer">
      <p>
        {{
          tcDaviviendaHome?.footer ??
            "Recuerde que por su seguridad el Banco Davivienda nunca solicita a través de este medio información confidencial o financiera como usuarios y claves de acceso a nuestros canales, ni números de productos como cuentas, números de tarjetas de crédito o similares."
        }}
      </p>
      <img
        src="../../../../assets/img/tc-davivienda/cobranding 1.png"
        alt="Cobranding Davivienda"
        class="cobranding"
      />
    </div>
  </div>
</footer>
<lib-tb-modal-notification [custom]="modalConfig"></lib-tb-modal-notification>
