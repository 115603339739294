import { Component, ElementRef, HostListener, Input, ViewChild, AfterViewChecked, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CreditCard } from '../model/tc-davivienda';

declare const dataLayer: any;
@Component({
  selector: 'app-credit-card-carousel',
  templateUrl: './credit-card-carousel.component.html',
  styleUrls: ['./credit-card-carousel.component.scss']
})
export class CreditCardCarouselComponent implements AfterViewChecked {
  @Input() cards: CreditCard[];
  @Output() selectedCard = new EventEmitter<CreditCard>();

  @ViewChild('carousel', { static: false }) carousel: ElementRef;
  @ViewChild('controls', { static: false }) controls: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  innerWidth: number;
  actualPosition: number;
  private swipeCoord?: [number, number];
  private swipeTime?: number;
  private maxPosition: number;
  private firstTime: boolean = true;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewChecked(): void {
    if (this.cards && this.firstTime) {
      let value = this.cards;
      this.maxPosition = value.length;
      this.innerWidth = window.innerWidth;
      this.controls.nativeElement.children[0].checked = true;
      this.carousel.nativeElement.style.setProperty('--position', '1');
      this.updatePosition();
      this.cdr.detectChanges();
    }
  }

  /**
   * The function `checkOverflow` determines if the total width of the cards exceeds the width of the
   * viewport.
   * @returns The `checkOverflow()` function is returning a boolean value. It returns `true` if the
   * total width of the cards (calculated as the number of cards multiplied by 377) is greater than or
   * equal to the inner width of the window. Otherwise, it returns `false`.
   */
  checkOverflow(): boolean {
    let cardsWidth = this.cards?.length * 377;
    if (cardsWidth >= innerWidth)
      return true;
    else
      return false;
  }

  /**
   * The "nextCard" function updates the position and sets the input if the current position is less
   * than the maximum position.
   */
  nextCard() {
    this.updatePosition();
    if (this.actualPosition < this.maxPosition) {
      this.setInput(this.actualPosition);
    }
  }

  /**
   * The "previousCard" function updates the position and sets the input to the previous card if the
   * current position is greater than 1.
   */
  previousCard() {
    this.updatePosition();
    if (this.actualPosition > 1) {
      this.setInput(this.actualPosition - 2);
    }
  }

  /**
   * The setInput function sets the position property of a carousel element based on the input id and
   * updates the position.
   * @param {number} id - The `id` parameter in the `setInput` method is a number that is used to set
   * the position of the carousel element.
   */
  setInput(id: number) {
    this.carousel.nativeElement.style.setProperty('--position', `${id + 1}`);
    this.updatePosition();
  }

  /**
   * The `swipe` function in TypeScript handles touch events to determine swipe direction and trigger
   * actions based on the swipe gesture.
   * @param {TouchEvent} e - The parameter `e` in the `swipe` function is of type `TouchEvent`, which
   * represents an event that occurs when a touch point is placed on the touch surface. It contains
   * information about the touch event, such as the coordinates of the touch point and the time when
   * the event occurred.
   * @param {string} when - The `when` parameter in the `swipe` function indicates whether the touch
   * event is the start or end of a swipe gesture. It helps differentiate between the initial touch
   * event (start) and the final touch event (end) to determine the direction and speed of the swipe.
   */
  swipe(e: TouchEvent, when: string): void {
    e.stopPropagation();
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000
        && Math.abs(direction[0]) > 30
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        if (swipe === 'next')
          this.nextCard();
        else if (swipe === 'previous')
          this.previousCard();
      }
    }
  }

  /**
   * The `updatePosition` function updates the current position of a carousel element and emits the
   * selected card based on the updated position.
   */
  updatePosition() {
    this.actualPosition = Number(this.carousel?.nativeElement.style.getPropertyValue('--position'));
    this.selectedCard.emit(this.cards[this.actualPosition - 1]);
    this.firstTime = false;
    this.tagCard();
  }
  //39H
  tagCard(){
    dataLayer.push({
      'event':'ga_event',
      'category':'tarjeta davivienda',
      'action':'JPW- cambiar tarjeta',
      'label':'cambio tarjeta'
    })
  }
}
