<div class="modal-location-address">
<div class="dialog-address">
  <div class="progress-item">
    <div class="progress-bar-green"></div>
  </div>
  <div mat-dialog-content class="content-dialog">
    <div class="content-title">
      <div class="icon-left" *ngIf="!firstView">
        <span class="icon-circle-arrow-left btn-left" onkeypress="" (click)="goBack()"></span>
      </div>
      <div style="width: 100%">
        <h2 mat-dialog-title class="title">¿Dónde necesitas el servicio?</h2>
      </div>
      <div class="icon-right">
        <span class="icon-close btn-right" mat-dialog-close></span>
      </div>
    </div>
    <div class="content-text" *ngIf="firstView">
      <form [formGroup]="addressDataForm" autocomplete="off">
        <div class="grid">
          <ng-container
            [ngSwitch]="dataField.type"
            *ngFor="let dataField of fieldAvailable"
          >
            <!-- Type city -->
            <ng-container *ngSwitchCase="'city'">
              <div class="form_item grid-12 mb-0 custom-form">
                <mat-form-field
                  appearance="outline"
                  [ngClass]="{
                    field_error:
                      submitted &&
                      addressDataForm.get('' + dataField.type + '').errors
                  }"
                >
                  <mat-label>{{
                    isRequiredField(dataField.type)
                      ? dataField.name
                      : dataField.name + " (opcional)"
                  }}</mat-label>
                  <mat-select
                    formControlName="city"
                    (selectionChange)="changeCity($event)"
                    name="ciudad"
                    [(ngModel)]="selectedCity"
                  >
                    <mat-option value=""> Selecciona la ciudad </mat-option>
                    <mat-option
                      [value]="option.name?option.name:option.value"
                      *ngFor="let option of cities"
                      >{{ option.name?option.name:option.value }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <p
                  class="error"
                  *ngIf="
                    submitted &&
                    addressDataForm.get('' + dataField.type + '').errors
                  "
                >
                  Campo requerido
                </p>
              </div>
            </ng-container>

            <!-- Type barrio -->
            <ng-container *ngSwitchCase="'neighborhood_address'">
              <div class="form_item grid-12 mb-0 custom-form">
                <mat-form-field
                  appearance="outline"
                  [ngClass]="{
                    field_error:
                      submitted &&
                      addressDataForm.get('' + dataField.type + '').errors
                  }"
                >
                  <mat-label>{{
                    isRequiredField(dataField.type)
                      ? dataField.name
                      : dataField.name + " (opcional)"
                  }}</mat-label>
                  <input
                    maxlength="50"
                    formControlName="neighborhood_address"
                    type="text"
                    [placeholder]="dataField.placeholder"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    matInput
                  />
                </mat-form-field>
                <p
                  class="error"
                  *ngIf="
                    submitted &&
                    addressDataForm.get('' + dataField.type + '').errors
                      ?.required
                  "
                >
                  Campo requerido
                </p>
                <p
                  class="error"
                  *ngIf="
                    submitted &&
                    addressDataForm.get('' + dataField.type + '').errors
                      ?.pattern
                  "
                >
                  No se aceptan caracteres especiales.
                </p>
              </div>
            </ng-container>

            <!-- Type text -->
            <ng-container *ngSwitchCase="'text'">
              <ng-container *ngIf="dataField.id === 13 || dataField.id === 'address'">
                <div class="form_item grid-12 custom-form">
                  <mat-form-field
                    ngClass="edit-field"
                    appearance="outline"
                    [ngClass]="{
                      field_error:
                        submitted &&
                        (addressDataForm.get('' + dataField.type + '').errors ||
                          addressValidate)
                    }"
                  >
                    <mat-label>{{
                      isRequiredField(dataField.type)
                        ? dataField.name
                        : dataField.name + " (opcional)"
                    }}</mat-label>
                    <input
                      id="txtAutocompleteMaps"
                      formControlName="text"
                      (change)="registerAdress($event.target.value)"
                      (keyup)="buildAdress($event)"
                      type="text"
                      #txtAutocompleteMaps
                      [placeholder]="dataField.placeholder"
                      autocorrect="off"
                      autocapitalize="off"
                      spellcheck="false"
                      matInput
                      style="position: relative;"
                    />
                  </mat-form-field>
                  <p
                    class="error"
                    *ngIf="
                      submitted &&
                      (addressDataForm.get('' + dataField.type + '').errors?.required ||
                        addressValidate || 
                        addressDataForm.get('' + dataField.type + '').errors?.ValidAddressText)
                    "
                  >
                    Por favor ingresa una dirección válida.
                  </p>
                  <p
                    class="error"
                    *ngIf="
                      submitted &&
                      (addressDataForm.get('' + dataField.type + '').errors?.validAddress)
                    "
                  >
                    La dirección no pertenece a la ciudad seleccionada
                  </p>
                </div>
              </ng-container>
            </ng-container>

            <!-- Type complemento  -->
            <ng-container *ngSwitchCase="'complement_address'">
              <div class="form_item grid-12 custom-form">
                <mat-form-field
                  appearance="outline"
                  [ngClass]="{
                    field_error:
                      submitted &&
                      addressDataForm.get('' + dataField.type + '').errors
                  }"
                >
                  <mat-label>{{
                    isRequiredField(dataField.type)
                      ? dataField.name
                      : dataField.name + " (opcional)"
                  }}</mat-label>
                  <input
                    maxlength="60"
                    formControlName="complement_address"
                    type="text"
                    [placeholder]="dataField.placeholder"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    [readonly]="addressDataForm.get('aditional_data').value"
                    matInput
                  />
                </mat-form-field>
                <p
                  class="error"
                  *ngIf="
                    submitted &&
                    addressDataForm.get('' + dataField.type + '').errors
                      ?.pattern
                  "
                >
                  No se aceptan caracteres especiales.
                </p>
              </div>
            </ng-container>

            <!-- checkbox complement_address -->
            <ng-container *ngSwitchCase="'complement_address'">
              <div class="form_item grid-12 custom-form">
                <mat-checkbox
                  class="example-margin"
                  formControlName="aditional_data"
                  (change)="changeAditionalData($event.checked)"
                  >La dirección no tiene datos adicionales</mat-checkbox
                >
              </div>
            </ng-container>

            <!-- Type indication  -->
            <ng-container *ngSwitchCase="'indication_address'">
              <div class="form_item grid-12">
                <h2 class="subtitle">¡Ayúdanos a llegar más fácil!</h2>
              </div>
              <div class="form_item grid-12 custom-form">
                <mat-form-field appearance="outline"
                [ngClass]="{
                  field_error:
                    submitted &&
                    addressDataForm.get('' + dataField.type + '').errors
                }">
                  <mat-label>{{
                    isRequiredField(dataField.type)
                      ? dataField.name
                      : dataField.name + " (opcional)"
                  }}</mat-label>
                  <input
                    maxlength="60"
                    formControlName="indication_address"
                    type="text"
                    [placeholder]="dataField.placeholder"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    matInput
                  />
                </mat-form-field>
                <p
                  class="error"
                  *ngIf="
                    submitted &&
                    addressDataForm.get('' + dataField.type + '').errors
                      ?.pattern
                  "
                >
                  No se aceptan caracteres especiales.
                </p>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </form>
      <mat-dialog-actions>
        <button [disabled]="!confirmDirection" class="btn_green" (click)="continue()">{{ !confirmDirection ? 'ESPERA...' : 'CONTINUAR' }} </button>
      </mat-dialog-actions>
    </div>

    <div *ngIf="!firstView">
      <div class="location-item">
        <a (click)="getCurrentPosition()" class="my-location"
          ><span class="icon-gps"></span>
          <strong> Utilizar mi ubicación actual</strong></a
        >
      </div>
      <div class="map-element">
        <p class="op-text">O</p>
        <p class="label-map"><i class="large material-icons">info</i>
          Mueve el ícono para ubicar la dirección correcta
        </p>
        <div class="message-item" *ngIf="messageMarkerDrag !== ''">
          <div class="content">
            <div class="message-error">
              <i class="large material-icons">info_outline</i>
              <p>
                {{ messageMarkerDrag }}
              </p>
            </div>
          </div>
        </div>
        <div class="map">
          <div class="form_item grid-12 custom-form" *ngIf="lat">
            <div class="content-address-map">
              <p>{{addressDataForm.get('text').value}}</p>
            </div>
            <google-map
              height="30vh"
              width="100%"
              [zoom]="zoom"
              [center]="{lat: latitude, lng: longitude}"
              [options]="mapOptions"
            >
              <map-marker
                [position]="{lat: lat, lng: long}"
                [options]="markerOptions"
                (mapDragend)="markerDragEnd($event)"
              >
              </map-marker>
              <map-circle
                [center]="{lat: radiusLat, lng: radiusLong}"
                [radius]="radius"
                [options]="circleOptions"
              >
              </map-circle>
            </google-map>
          </div>
        </div>
        <!-- checkbox datos adicionales -->
        <ng-container>
          <div class="form_item grid-12 custom-form">
            <mat-checkbox
              class="example-margin"
              [(ngModel)]="confirmDirectionChecked"
              [disabled]="!confirmDirection"
              >Confirmo que mi ubicación en el mapa está bien.</mat-checkbox
            >
          </div>
        </ng-container>
      </div>
      <mat-dialog-actions>
        <button
          [disabled]="!confirmDirectionChecked"
          class="btn_green"
          (click)="sendAddressInformation()"
        >
          CONFIRMAR DIRECCIÓN
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
</div>
