<div class="menu_user">
    <div class="header_menu">
        <div class="logo">
            <a routerLink="/" queryParamsHandling="preserve" (click)="closeMenu()" class="logo_color">
                <picture>
                    <source media="(max-width: 767px)" attr.lazyLoad="../../../../assets/img/brand/logo_jelpit_header.svg" height="65" width="150">
                    <img defaultImage="./assets/img/gif/loading-small.gif" lazyLoad="../../../../assets/img/brand/logo_jelpit_header.svg" alt="jelpit" height="65" width="150">
                </picture>
            </a>
        </div>
        <div class="close_icon">
            <a class="icon-close" (click)="closeMenu()"></a>
        </div>
    </div>
    <div class="content">
        <p>Mi cuenta</p>
        <ul>
            <li>
                <a routerLink="/mi-perfil" queryParamsHandling="preserve"
                (click)="closeMenu('Mi perfil')" data-qa-id="JP-P-SH-MU-button_user_menu_profile">
                    <span class="icn icon-user-profile"></span>Mi perfil</a>
            </li>
            <li>
                <a routerLink="/mis-solicitudes" queryParamsHandling="preserve"
                (click)="closeMenu('Mis solicitudes')" data-qa-id="JP-P-SH-MU-button_user_menu_requests" >
                    <span class="icn icon-user-request"></span>Mis solicitudes</a>
            </li>
            <li>
                <a routerLink="/mis-suscripciones" queryParamsHandling="preserve"
                (click)="closeMenu('Mis suscripciones')" data-qa-id="JP-P-SH-MU-button_user_menu_subscriptions">
                    <span class="icn icon-user-suscription"></span>Mis suscripciones</a>
            </li>
            <li>
                <a (click)="closeSession('Cerrar sesión')" data-qa-id="JP-P-SH-MU-button_user_menu_sign_off" >
                  <span class="icn icon-user-close"></span>Cerrar sesión
                </a>
            </li>
        </ul>
    </div>
</div>
