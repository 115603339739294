<div class="container_testimonials" *ngIf="testimonialInfo.length > 0">
    <div class="container">
        <div class="top_info">
            <h2 class="title">Lo que dicen nuestros clientes</h2>
        </div>
        <div class="container_elements">
            <ngx-masonry [options]="masonryOptions" [ordered]="true">
                <div class="items_testimonial" ngxMasonryItem
                    *ngFor="let item of testimonialInfo | slice:0:sliceTestimonial"
                    [ngClass]="{'hasImagen': item.service_image}">
                    <div class="content">
                        <div class="info_user">
                            <div class="user_img">
                                <picture>
                                    <source media="(max-width: 767px)" [attr.lazyLoad]="item.image" height="64" width="64">
                                    <img class="imagen" defaultImage="./assets/img/gif/loading-small.gif" [lazyLoad]="item.image" [alt]="item.alt_image" />
                                </picture>
                            </div>
                            <div class="name_user">
                                <p class="name">{{item.name}}</p>
                                <span *ngIf="item.service">{{item.service}}</span>
                            </div>
                        </div>
                        <div class="text_testimonial">
                            <div class="info">
                                <span class="title">{{item.title}}</span>
                                <div class="text" [innerHTML]="item.text"></div>
                                <div class="img_service" *ngIf="item.service_image">
                                    <picture>
                                        <source media="(max-width: 767px)" [attr.lazyLoad]="item.service_image">
                                        <img defaultImage="./assets/img/gif/loading-small.gif" [lazyLoad]="item.service_image" [alt]="item.service_alt_image" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-masonry>
        </div>
        <div class="enlace_more" *ngIf="testimonialInfo.length > 4">
            <a class="link_purple" (click)="showMore()">Cargar más experiencias <span
                    class="icon-arrow_down"></span></a>
        </div>
    </div>
</div>
