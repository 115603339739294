import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { AiMessageBody, FacilitiesProduct, Message, UserOpinionBody } from '@interfaces/ai';
import { ChatAiService } from '@services/chat-ai/chat-ai.service';
import { CognitoService } from '@services/cognito/cognito.service';
import { ILibTbInputText } from 'tech-block-lib';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Component({
  selector: 'app-chat-ai',
  templateUrl: './chat-ai.component.html',
  styleUrls: ['./chat-ai.component.scss'],
})
export class ChatAiComponent implements OnInit {
  private prompts = [
    { default: 'promptRouter' },
    { faq_no_mem: 'wchat' },
    { link: 'ichatLink' },
    { faq: 'chat' },
    { v2: 'ichatLinkv2' },
    { routerv2_1: 'v2.1/promptRouter' },
    { facilities: 'chatFacilities' },
  ];
  private prompt: string;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showChat: boolean = false;
  showSpeech2Text: boolean = false;
  loadMessages: boolean = false;
  chatForm: FormGroup = new FormGroup({
    inputMessage: new FormControl('', Validators.compose([])),
  });

  get accessToken(): string {
    return this.accessTokenSubject.value;
  }

  chatContainer: ElementRef;

  @Input() setLoadMessages: boolean = false;
  @Input() enableSpeech2text: boolean = false;
  @Input() enableThumbButtons: boolean = false;

  @Output() interactionChat = new EventEmitter<any>();

  @ViewChild('chat', { static: false }) set chat(chat: ElementRef) {
    if (chat) {
      this.chatContainer = chat;
    }
  }

  inputConfig: ILibTbInputText = {
    type: 'text',
    name: 'inputMessage',
    class: 'chat_input',
    placeholder: 'Habla conmigo',
    icon: 'fa-regular fa-paper-plane-top',
    iconPosition: 'right',
    formGroup: this.chatForm,
    autocomplete: false,
    libTbClickIcon: (e) => { this.handleIconEvent(e) },
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.setLoadMessages?.currentValue !== undefined) {
      if (changes?.setLoadMessages?.currentValue) {
        this.loadMessages = changes?.setLoadMessages?.currentValue;
        this.scrollToBottom();
      } else {
        this.loadMessages = changes?.setLoadMessages?.currentValue;
      }
    }
  }

  messages: Message[] = [
    {
      text: '<p><strong>¡Soy MIA, tu experta en servicios!</strong> Encuentra soluciones a tu medida con ayuda de la inteligencia artificial.</p>',
      sender: 'bot',
      time: moment().format('HH:mm'),
    },
  ];

  fastOptions = [
    {
      text: 'Quiero pagar mi administración',
    },
    {
      text: 'Necesito asesoría',
    },
  ];
  constructor(
    private chatAiService: ChatAiService,
    private route: ActivatedRoute,
    private cognitoService: CognitoService
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe({
      next: (param) => {
        let value = param.get('template');
        if (value) {
          let filtered = this.prompts.filter((elm) => {
            if (Object.keys(elm)[0] === value) {
              return Object.values(elm)[0];
            }
          });
          if (filtered.length > 0) {
            this.prompt = Object.values(filtered[0])[0];
          } else this.prompt = this.prompts[0].default;
        } else this.prompt = this.prompts[0].default;
      },
    });
    this.getToken();
  }

  getToken() {
    this.cognitoService.getAccesCognito().subscribe({
      next: (response) => {
        this.accessTokenSubject.next(response.access_token);
      },
    });
  }

  /**
   * Función para enviar un mensaje
   * @param message - Mensaje a enviar
   */
  sendMessage(message?: string): void {
    let retryAttempts = 0;
    if (!message && !this.chatForm.controls.inputMessage.value) {
      this.interactionChat.emit(true);
      this.loadMessages = true;
      this.scrollToBottom();
      return;
    }
    message = message ?? this.chatForm.controls.inputMessage.value;
    this.chatForm.controls.inputMessage.setValue('');
    this.messages.push({
      text: message,
      sender: 'user',
      time: moment().format('HH:mm'),
    });
    this.interactionChat.emit(true);
    this.loadMessages = true;
    this.scrollToBottom();
    this.messages.push({
      text: `<img src="assets/img/gif/loading-small.gif" [alt]="loading" height="20" width="20" />`,
      sender: 'bot',
      time: '',
    });
    this.scrollToBottom();
    let chatMessage = this.buildMessage(message);
    this.chatAiService
      .postMessageToAi(chatMessage, this.prompt, this.accessToken)
      .pipe(
        catchError((error) => {
          if ((error.status === 401 || error.status === 0) && retryAttempts < 3) {
            return this.cognitoService.getAccesCognito().pipe(
              tap(newToken => this.accessTokenSubject.next(newToken.access_token)),
              switchMap(() => {
                retryAttempts++;
                return this.postRetryMessageToAi(message, this.prompt);
              })
            )
          }
          return throwError(error);
        })
      )
      .subscribe({
        next: (response) => {
          this.messages.pop();
          if (response.response)
            this.messages.push({
              text: `<p>${response.response}</p>`,
              sender: 'bot',
              time: moment().format('HH:mm'),
              uid: response.message_uid
            });
          if (response.data) {
            this.buildStylishData(response.data);
          }
          this.setUid(response.conversation_uid);
          this.scrollToBottom();
        },
        error: (err) => {
          console.error('Error: ', err);
          this.messages.pop();
          this.messages.push({
            text: `<p>Algo salió mal, inténtalo de nuevo :(</p>`,
            sender: 'bot',
            time: moment().format('HH:mm'),
          });
          this.scrollToBottom();
        },
      });
  }

  /**
   * The function `postRetryMessageToAi` sends a message to an AI chat service with a given prompt and
   * access token.
   * @param {string} message - The `message` parameter is a string that represents the user's message
   * or input to the AI chatbot. It can be any text that the user wants to send to the chatbot for
   * processing.
   * @param {string} prompt - The "prompt" parameter is a string that represents the prompt or question
   * that you want to send to the AI. It is used to provide context or guidance to the AI when
   * generating a response.
   * @returns an Observable<any>.
   */
  private postRetryMessageToAi(message: string, prompt: string): Observable<any> {
    let chatMessage = this.buildMessage(message);
    return this.chatAiService.postMessageToAi(chatMessage, prompt, this.accessToken);
  }

  /**
   * Function to update the calification of a message
   * @param calification - calification to update
   * @param indexMessage - index of message to update
   */
  setCalification(calification: number, indexMessage: number, messageUid: string) {
    if (calification === this.messages[indexMessage].calification) {
      this.messages[indexMessage].calification = null;
      return;
    }
    this.messages[indexMessage].calification = calification;
    if (messageUid) {
      this.sendUserOpinion(messageUid, !!calification);
    }
  }

  /**
   * The function sends the user's opinion (like or dislike) on a specific message to the chat AI
   * service.
   * @param {string} messageUid - The messageUid parameter is a string that represents the unique
   * identifier of a message. It is used to identify the specific message for which the user is
   * providing their opinion.
   * @param {boolean} opinion - The `opinion` parameter is a boolean value that represents the user's
   * opinion on a message. It indicates whether the user likes (`true`) or dislikes (`false`) the
   * message.
   */
  sendUserOpinion(messageUid: string, opinion: boolean) {
    let body: UserOpinionBody = {
      message_uid: messageUid,
      like: opinion
    };
    this.chatAiService.postUserOpinion(body, this.accessToken).subscribe();
  }

  /**
   * The scrollToBottom function scrolls the chat container to the bottom with a smooth animation.
   * @returns void, which means it does not return any value.
   */
  scrollToBottom(): void {
    if (!this.chatContainer) {
      return;
    }
    setTimeout(() => {
      this.chatContainer.nativeElement.scroll({
        top: this.chatContainer.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
  }

  /**
   * The function `buildMessage` takes a message as input and returns an object with the message and a
   * conversation UID.
   * @param {string} message - The `message` parameter is a string that represents the user's input or
   * question.
   * @returns an object of type AiMessageBody. The object has two properties: "question" which is set
   * to the value of the "message" parameter, and "conversation_uid" which is set to the value of the
   * "uid" variable retrieved from localStorage.
   */
  buildMessage(message: string): AiMessageBody {
    let uid = localStorage.getItem('chatBotUid');
    return {
      question: message,
      conversation_uid: uid,
    };
  }

  /**
   * The function sets a unique identifier (uid) in the local storage if it is provided and different
   * from the current uid stored in the local storage.
   * @param {string} uid - The `uid` parameter is a string that represents a unique identifier for a
   * user.
   */
  setUid(uid: string) {
    let localUid = localStorage.getItem('chatBotUid');
    if (uid && uid !== localUid) {
      localStorage.setItem('chatBotUid', uid);
    }
  }

  /**
   * Builds and adds stylish data to the chat messages.
   * @param object - The object containing the data.
   */
  buildStylishData(object): void {
    if (object.paymentLinkInfo) {
      const richText = this.buildPaymentLinkInfo(object.paymentLinkInfo);
      this.messages.push({
        text: `<p>${richText}</p>`,
        sender: 'bot',
        time: moment().format('HH:mm'),
      });
    }
    if (object.facilities_products && object.facilities_products.length > 0) {
      const richText = this.buildFacilitiesProducts(object.facilities_products);
      this.messages.push({
        text: `${richText}`,
        sender: 'bot',
        time: moment().format('HH:mm'),
      });
    }
  }
  /**
    * The function "buildPaymentLinkInfo" takes an object as input and returns a string containing formatted
    * payment link information.
    * @param object - The `object` parameter is an object that contains information about payment links.
    * It has an array of objects. Each object in the
    * `paymentLinkInfo` array represents a payment link and has properties such as `name`, `url`, and
    * `city
    * @returns The function `buildPaymentLinkInfo` returns a string.
    */
  buildPaymentLinkInfo(object): string {
    if (object.length === 1) {
      return `<div class="rich_item">
                <p class="city">${object[0].city}</p>
                <div class="rich_item_content">
                  <div class="item_name">
                    <p>${object[0].name}</p>
                    <div class="agreement_number">
                      <p class="subtitle">No. de convenio:</p>
                      <p>${object[0].agreement_number}</p>
                    </div>
                  </div>
                  <a class="rich_item_button" href="${object[0].url}&utm_source=Mia_Web&utm_medium=web" target="_blank">
                    <i class="fa-regular fa-link"></i>
                    <p>Ir a</p>
                  </a>
                </div>
              </div>`;
    } else {
      let response: string = '';
      object.forEach((element) => {
        response += `<li>
                      <div class="rich_item">
                        <p class="city">${element.city}</p>
                        <div class="rich_item_content">
                          <div class="item_name">
                            <p>${element.name}</p>
                            <div class="agreement_number">
                              <p class="subtitle">No. de convenio:</p>
                              <p>${element.agreement_number}</p>
                            </div>
                          </div>
                          <a class="rich_item_button" href="${element.url}&utm_source=Mia_Web&utm_medium=web" target="_blank">
                            <i class="fa-regular fa-link"></i>
                            <p>Ir a</p>
                          </a>
                        </div>
                      </div>
                    </li>`;
      });
      return `<ul>${response}</ul>`;
    }
  }
  /**
   * Builds a string representation of facilities products.
   *
   * @param object - An array of FacilitiesProduct objects.
   * @returns A string representing the HTML structure of the facilities products.
   */
  buildFacilitiesProducts(object: FacilitiesProduct[]): string {
    let response: string = '';
    object.forEach((element) => {
      response += `<div class="card-item">
                      <h3 class="card-item__title">${element.chapter}</h3>
                      <p class="card-item__subtitle">${element.item_type} - ${element.client}</p>
                      <p class="card-item__content">${element.item}</p>
                      <p class="card-item__price">$${element.price}</p>
                  </div>`;
    });
    return `<div class="container-products">${response}</div>`;
  }

  /**
   * The function "showVoiceRecording" sets the value of "showSpeech2text" to true.
   */
  showVoiceRecording() {
    this.showSpeech2Text = true;
    this.inputConfig.icon = "fa-light fa-square";
    this.inputConfig.placeholder = "Escuchando";
  }

  /**
   * The function "hideVoiceRecording" sets the value of "showSpeech2text" to false.
   */
  hideVoiceRecording() {
    this.showSpeech2Text = false;
    this.inputConfig.icon = "fa-regular fa-paper-plane-top";
    this.inputConfig.placeholder = "Habla conmigo";
  }

  /**
   * The function `handleIconEvent` checks the class name of an event's source element and either hides
   * a voice recording or sends a message based on the class name.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. It contains information about the event, such as the target element that triggered the
   * event.
   */
  handleIconEvent(event) {
    let className: string = event.srcElement.className;
    if (className.includes('fa-square')) {
      this.hideVoiceRecording();
    }
    else {
      this.sendMessage();
    }
  }
}
