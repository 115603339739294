import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-tips-pqr',
  templateUrl: './modal-tips-pqr.component.html',
  styleUrls: ['./modal-tips-pqr.component.scss']
})
export class ModalTipsPqrComponent {

  constructor(
    public dialogRef: MatDialogRef<ModalTipsPqrComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  close(){
    this.dialogRef.close();
  }
}
