import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class CryptoJSAesEncrypt {
  /**
   * The function uses CryptoJS library to encrypt plain text using AES algorithm with a randomly
   * generated salt and initialization vector (IV), and returns the encrypted data in a Base64-encoded
   * JSON string.
   * @param plain_text - The plain_text parameter is the message or data that needs to be encrypted
   * using AES encryption.
   * @returns a Base64-encoded string that represents a JSON object containing the encrypted
   * ciphertext, salt, and initialization vector (IV) of the input plain text using AES encryption with
   * a randomly generated salt and IV, and a key derived from a password using PBKDF2 with SHA-512
   * hashing algorithm, 64-bit key size, and 999 iterations.
   */
  CryptoJSAesEncrypt(plain_text) {
    let salt = CryptoJS.lib.WordArray.random(256);
    let iv = CryptoJS.lib.WordArray.random(16);

    let key = CryptoJS.PBKDF2('bebfa47a9bf87f22d49ee5193305c736', salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: 64 / 8,
      iterations: 999,
    });

    let encrypted = CryptoJS.AES.encrypt(plain_text, key, {
      mode: CryptoJS.mode.CTR,
      iv: iv,
      padding: CryptoJS.pad.NoPadding,
    });

    let data = {
      value: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iv: CryptoJS.enc.Hex.stringify(iv),
    };

    return btoa(JSON.stringify(data));
  }
}
