import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowSnackbarService {

  private sendShowSnackbarSubject = new Subject<object>();
  sendShowSnackbarObservable = this.sendShowSnackbarSubject.asObservable();

  showSnackbar(text: string, show?: boolean) {
    if (show === undefined) {
      show = true;
    };

    this.sendShowSnackbarSubject.next({
      text,
      show
    });
  }
}
