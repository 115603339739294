import { Component, OnInit } from '@angular/core';
import { CsrfTokenService } from '@services/csrf-token/csrf-token.service';
import { PqrsService } from '@services/jelpit-pqrs/pqrs.service';

@Component({
  selector: 'app-follow-up-pqrs',
  templateUrl: './follow-up-pqrs.component.html',
  styleUrls: ['./follow-up-pqrs.component.scss'],
})
export class FollowUpPqrsComponent implements OnInit{
  /**
   * @description Variable para el numero de radicado
   */
  settled = '';

  /**
   * @description variable para saber si esta buscando
   */
  searchSettled = false;

  /**
   * @description Variable para saber si encontro o no
   */
  confirmSearch = false;

  status='';

  searchText = '';
  crfToken: string | null = '';

  constructor(private servicePqr: PqrsService,
              private csrfTokenService: CsrfTokenService) {}

  ngOnInit(): void {
    this.crfToken = this.csrfTokenService.generateCsrfToken(40);
  }

  search() {
    if (this.searchText !== '') {
      this.servicePqr.searchPqr(this.searchText.toUpperCase(), this.crfToken).subscribe(
        (response) => {
          this.settled = response.result?.code;
          this.status = response.result?.state?.name;
          this.searchSettled = true;
          this.confirmSearch = true;
        },
        (error: any) => {
          this.settled = this.searchText;
          this.searchSettled = true;
          this.confirmSearch = false;
        }
      );
    }
  }

  goBack() {
    this.confirmSearch = false;
    this.searchSettled = false;
  }
}
