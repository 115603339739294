import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-banner-detail',
  templateUrl: './banner-detail.component.html',
  styleUrls: ['./banner-detail.component.scss']
})
export class BannerDetailComponent  {

  @Input()
  infoBanner: any = {};
  @Input()
  shortBanner: boolean;

  @Input()
  cityBanner: boolean;

  showBanner: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.infoBanner) {
      if (Object.entries(changes.infoBanner.currentValue).length !== 0) {
        this.showBanner = true;
      }
    }
  }
}
