<div class="container">
  <div class="container__chat-view" *ngIf="loadMessages" #chat>
    <div *ngFor="let message of messages; index as i">
      <div
        *ngIf="message.sender === 'bot'"
        class="container__chat-view__message"
      >
        <p class="container__chat-view__message__time-ai">
          {{ message.time }}
        </p>
        <div class="container__chat-view__message__text-ai message-box">
          <div class="message-box__body">
            <img
              src="assets/img/iconos/icono_profile_mia.png"
              alt="icon mia"
              class="message-box__body__image"
            />
            <div [innerHTML]="message.text"></div>
          </div>
          <div
            class="message-box__footer"
            *ngIf="
              !message.text.includes('loading-small.gif') &&
              enableThumbButtons &&
              message.uid
            "
          >
            <p>¿Fue útil?</p>
            <button (click)="setCalification(1, i, message.uid)">
              <i
                [ngClass]="{
                  'fa-light': message.calification !== 1,
                  'fa-solid': message.calification === 1
                }"
                class="fa-thumbs-up"
              ></i>
            </button>
            <button (click)="setCalification(0, i, message.uid)">
              <i
                [ngClass]="{
                  'fa-light': message.calification !== 0,
                  'fa-solid': message.calification === 0
                }"
                class="fa-thumbs-down"
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div
        *ngIf="message.sender === 'user'"
        class="container__chat-view__message"
      >
        <p class="container__chat-view__message__time-user">
          {{ message.time }}
        </p>
        <div
          class="container__chat-view__message__text-user"
          [innerHTML]="message.text"
        ></div>
      </div>
      <div class="container__fast-options" *ngIf="i === 0">
        <button
          class="container__fast-options__button"
          *ngFor="let option of fastOptions"
          (click)="sendMessage(option.text)"
        >
          {{ option.text }}
        </button>
      </div>
    </div>
  </div>
  <form
    class="container__chat"
    [formGroup]="chatForm"
    (ngSubmit)="sendMessage()"
    autocomplete="off"
  >
    <img
      *ngIf="showSpeech2Text"
      class="audio_animation"
      src="../../../../assets/img/home/Audio animation.png"
      alt="Audio"
    />
    <lib-tb-input-text [custom]="inputConfig"></lib-tb-input-text>
    <button
      class="btn-chat container__chat__button-mic"
      type="button"
      *ngIf="enableSpeech2text"
      (click)="showVoiceRecording()"
    >
      <i class="fa-light fa-microphone"></i>
    </button>
  </form>
</div>
