import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router, Event as NavigationEvent, NavigationStart } from '@angular/router';
import { tcDaviviendaText } from '@environment/tc-davivienda-text';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { CreditCard, ServicesDisable, TcDavivienda, TcDaviviendaHome, UserServicesTC } from '../model/tc-davivienda';
import { TcDaviviendaService } from '../service/tc-davivienda.service';
import { filter } from 'rxjs/operators';
import { CryptoJSAesDecrypt } from '@cryptoJs/decrypt.service';
import { ServicesJelpitService } from '@services/services-jelpit/services-jelpit.service';
import { ILibTbButton, ILibTbModalNotification } from 'tech-block-lib';
import { ConfirmationService } from 'primeng/api';
declare const dataLayer: any;

@Component({
  selector: 'app-use-davivienda-cards',
  templateUrl: './use-davivienda-cards.component.html',
  styleUrls: ['./use-davivienda-cards.component.scss'],
})
export class UseDaviviendaCardsComponent implements OnInit {

  private timer;

  buttonConfig: ILibTbButton = {
    label: 'Cerrar sesión',
    class: 'header_button',

    libTbClick: () => this.closeSession(),
  };
  carouselConfig: CreditCard[];
  modalConfig: ILibTbModalNotification = {
    img: {
      src: '../../../../assets/img/tc-davivienda/IconoReloj.png'
    },
    title: 'El tiempo de su sesión ha expirado, cerrándose de forma segura.',
    dismissableMask: true,
    showClose: true,
    key: 'modalExit',
    acceptButtonVisible: false,
    rejectButton: {
      label: 'Iniciar sesión',
      libTbClick: () => {
        this.confirmationService.close();
        this.closeSession();
      },
      class: 'modal_notification_button'
    },
    rejectButtonVisible: true,
    class: 'modal_notification'
  };


  tcDaviviendaHome: TcDaviviendaHome;
  textToSelectecServices: string = tcDaviviendaText.textServicesTc.textToSelectecServices;
  textFooterDavivienda: string = tcDaviviendaText.textDavivienda;
  numberServices: number = 0;
  selectedCard: CreditCard;
  urlBase: string = '';
  tcInfoUser: UserServicesTC = <any>{};
  subscriptions: Subscription[] = [];
  doubts: string = "";

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: Router,
    private service: TcDaviviendaService,
    private decrypt: CryptoJSAesDecrypt,
    private jelpitService: ServicesJelpitService,
    private confirmationService: ConfirmationService
  ) {
    this.validateSesion();
    this.urlBase = `${this.jelpitService.envUrl}/`;
    /**Se valida la accion de boton goBack del navegador para cerrar la sesion */
    this.subscriptions.push(this.route.events
      .pipe(
        filter(
          (event: NavigationEvent) => {
            return (event instanceof NavigationStart);
          }))
      .subscribe(
        (event: NavigationStart) => {
          if (event.url == '/alianzas/davivienda/beneficios-tarjeta-credito') {
            localStorage.removeItem('userSesionTC');
            localStorage.removeItem('usedTC');
          }
        }));
  }

  ngOnInit(): void {
    this.service.getHome().subscribe({
      next: response => {
        this.tcDaviviendaHome = response.result
      }
    });
  }

  /**
   * The closeSession function removes specific items from localStorage and navigates to a specific
   * route.
   */
  closeSession() {
    localStorage.removeItem('userSesionTC');
    localStorage.removeItem('usedTC');
    this.route.navigate(['/alianzas/davivienda/beneficios-tarjeta-credito']);
  }

  /**
   * The `validateSession` function checks and validates the user session based on certain conditions
   * and actions.
   */
  validateSesion() {
    moment.tz.setDefault("America/Bogota");
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('userSesionTC')) {
        this.tcInfoUser = JSON.parse(localStorage.getItem('userSesionTC'));
        let validation = this.validateServices(this.tcInfoUser.assistances);
        if (validation === "ACTIVO") {
          let userDate = moment(this.tcInfoUser.date).tz('America/Bogota').format();
          let currentDate = moment.tz('America/Bogota').format();
          let duration = moment.duration(moment(currentDate).diff(userDate));
          this.timer = setTimeout(() => {
            this.confirmationService.confirm({
              key: 'modalExit',
              reject: () => {
                this.confirmationService.close();
                this.closeSession();
              },
            });
          }, 300000);
          if (duration.asMinutes() >= 5) {
            this.closeSession();
          }
          this.UpdateServiceTC(this.tcInfoUser);
        } else {
          this.closeSession();
        }
      } else {
        this.closeSession();
      }
    }
  }

  /**
   * The function `validateServices` checks the status of multiple credit cards and returns "ACTIVO" if
   * at least one card is active, otherwise "INACTIVO".
   * @param {CreditCard[]} cards - CreditCard[] - an array of CreditCard objects
   * @returns The function `validateServices` is returning a string value. The function iterates over
   * an array of `CreditCard` objects and checks if any of the cards have a status of "ACTIVO". If at
   * least one card has an "ACTIVO" status, the function will return "ACTIVO". Otherwise, it will
   * return "INACTIVO".
   */
  validateServices(cards: CreditCard[]): string {
    let response = "INACTIVO";
    cards.forEach((card) => {
      if (card.status === "ACTIVO")
        response = "ACTIVO";
    });
    return response;
  }

  /**
   * The function `setActualCard` sets the selected credit card, calculates the total number of
   * services associated with the card, and updates the `numberServices` property accordingly.
   * @param {CreditCard} event - The `event` parameter in the `setActualCard` function is of type
   * `CreditCard`.
   */
  setActualCard(event: CreditCard) {
    this.selectedCard = event;
    this.numberServices = 0;
    event.services.forEach((service) => {
      this.numberServices += service.quantity;
    });
  }

  /**
   * The function `setCards` filters out active credit cards from a given array and stores them in
   * `carouselConfig`.
   * @param {CreditCard[]} cards - The `cards` parameter is an array of `CreditCard` objects.
   */
  setCards(cards: CreditCard[]) {
    this.carouselConfig = [];
    cards.forEach((card) => {
      if (card.status === "ACTIVO") {
        this.carouselConfig.push(card);
      }
    });
  }

  /**
   * The function `UpdateServiceTC` updates user information and handles responses from a service call.
   * @param {UserServicesTC} dataUser - The `dataUser` parameter in the `UpdateServiceTC` function is
   * of type `UserServicesTC`. It contains the following properties:
   */
  UpdateServiceTC(dataUser: UserServicesTC) {
    const data: TcDavivienda = {
      document_type: dataUser.data.document_type,
      document_number: dataUser.data.document_number,
    };
    this.service.consultUser(data)
      .subscribe({
        next: (res) => {
          let serviceResponse = JSON.parse(this.decrypt.cryptoJSAesDecrypt(res.result.value));
          this.tcInfoUser.assistances = serviceResponse?.assistances;
          this.tcInfoUser.technicalSupport = serviceResponse?.technicalSupport ?? serviceResponse.technicalSupport;
          this.tcInfoUser.technicalSupport = this.tcInfoUser.technicalSupport?.replace('<p>', '');
          this.doubts += this.tcInfoUser.technicalSupport;
          this.tcInfoUser.pqrs = serviceResponse?.pqrs ?? serviceResponse.pqrs;
          this.tcInfoUser.pqrsMobile = this.tcInfoUser.pqrs;
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('userSesionTC', JSON.stringify(this.tcInfoUser));
          }
          this.setCards(this.tcInfoUser.assistances);
        },
        error: (errorResponse) => {
          if (errorResponse?.error) {
            if (
              errorResponse?.error.messages ===
              tcDaviviendaText.errorNotRegister
            ) {
              const data = {
                name: '',
                service: [],
                status: 'noExistingTC',
                date: moment().tz('America/Bogota').format(),
                data: {
                  document_type: dataUser.data.document_type,
                  document_number: dataUser.data.document_number,
                }
              };
              localStorage.setItem('userSesionTC', JSON.stringify(data));
            }
          }
        },
      })
  }

  /**
   * The function `sendService` checks if a service quantity is greater than 0, opens a URL if a slug
   * is provided, and pushes data to the dataLayer.
   * @param {ServicesDisable} service - ServicesDisable {
   * @returns If the `service.quantity` is less than or equal to 0, the function will return without
   * performing any further actions.
   */
  sendService(service: ServicesDisable) {
    if (service.quantity <= 0)
      return;
    if (service.slug.length) {
      localStorage.setItem('usedTC', JSON.stringify(this.selectedCard));
      window.open(service.slug, '_blank');
    }
    //39D
    dataLayer.push({
      'event': 'ga_event',
      'category': 'tarjeta davivienda',
      'action': 'JPW- agendar servicio',
      'label': service.name
    })
  }


  /**
   * The function `tagDaviviendaEnviarDeNuevo` sends a Google Analytics event when a Davivienda card is
   * scheduled for a new transaction.
   * @param {any} card - The `card` parameter in the `tagDaviviendaEnviarDeNuevo` function represents
   * the card that is being sent again for scheduling in the Davivienda system. It is used as a label
   * in the Google Analytics event tracking to identify which card is being processed.
   */
  tagDaviviendaEnviarDeNuevo(card: any) {
    //39E
    dataLayer.push({
      'event': 'ga_event',
      'category': 'tarjeta davivienda',
      'action': 'JPW- agendar de nuevo',
      'label': card,
    })
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  tagAyuda(texto) {
    //39I
    let element = document.getElementsByClassName(texto)[0];
    if (element) {
      let value = element.textContent
      dataLayer.push({'event':'ga_event','category':'tarjeta davivienda','action':'JPW- ayuda footer','label':value})
    }
  }
}
