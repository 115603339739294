import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input()
  numberPagination: number;
  selectActivePage: number = 1;
  @Output()
  sendEndPageStartPage: EventEmitter<any> = new EventEmitter<any>();
  private dataForPageTemp: number;
  private numberForPage: number;

  changePage(page): void {
    const divScroll = document?.getElementById('cont-scroll');
    divScroll?.scrollTo(((page - 2) * 35 ), 0);
    this.selectActivePage = page;
    this.sendEmitterData();
  }

  pageLess(): void {
    if (this.selectActivePage > 1) {
      this.selectActivePage--;
    }
    this.sendEmitterData();
  }

  pageMore(): void {
    if (this.selectActivePage < this.numberPagination) {
      this.selectActivePage++;
    }
    this.sendEmitterData();
  }

  @Input()
  set dataForPage(value) {
    this.numberForPage = value;
    this.dataForPageTemp = Math.ceil(this.numberPagination / value);
  }

  get dataForPage() {
    return this.dataForPageTemp;
  }

  sendEmitterData(): void {
    this.sendEndPageStartPage.emit({
      paginationStart: ((this.numberForPage * this.selectActivePage) - this.numberForPage),
      paginationEnd: this.numberForPage * this.selectActivePage
    });
  }
}
