import {
  Injectable,
  Inject,
  PLATFORM_ID,
  SecurityContext,
} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer } from '@angular/platform-browser';
import { SafePipe } from '@pipes/safe.pipe';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService {
  private safePipe: SafePipe = new SafePipe(this.sanitizer);

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookieService: CookieService,
    private sanitizer: DomSanitizer,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (isPlatformBrowser(this.platformId)) {
      const valorAuth = localStorage.getItem('token-session');
      if (!request.headers.has('Authorization')) {
        if (valorAuth) {
          request = this.addToken(request, valorAuth);
        }
      }
      if (request.method === 'POST' || request.method === 'PUT') {
        request = this.addCsrfToken(request);
      }
    }

    if (!request.headers.has('mime')) {
      if (!request.headers.has('Content-Type')) {
        request = this.contentType(request);
      }
    }
    if (request.method === 'GET') {
      this.sanitizeParams(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private contentType(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
      },
    });
  }

  private addCsrfToken(request: HttpRequest<any>): HttpRequest<any> {
    const cookie = this.cookieService.get('XSRF-TOKEN');
    return request.clone({
      setHeaders: {
        'X-XSRF-TOKEN': cookie,
      },
    });
  }

  private sanitizeParams(request: HttpRequest<any>): HttpRequest<any> {
    const sanitizeUrl: string = this.sanitizer
      .sanitize(
        SecurityContext.RESOURCE_URL,
        this.safePipe.transform(request.url, 'resourceUrl')
      )
      .replace(/[)\]";}]/g, '');
    return request.clone({
      url: sanitizeUrl,
    });
  }
}
