import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { Microformats } from '../../services/microformats/microformats.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { HomeService } from '@services/home/home.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: Array<Object> = [];
  public jsonLD: SafeHtml;
  public children: any;
  module: any;
  private events: Subscription;
  private serviceArray: Array<string> = [];
  private packArray: Array<string> = [];
  private falgService: boolean;
  private falgPack: boolean;
  private lastUlr: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private homeService: HomeService,
    private microformats: Microformats,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.lastUlr = this.router.url.split('/')[this.router.url.split('/').length - 1].split('?')[0];
    this.verifySlug(this.lastUlr).subscribe((res) => {
      this.falgService = res.slugService;
      this.falgPack = res.slugPack
    });
  }

  ngOnInit(): void {
    this.refrestUrlSincronal();
    setTimeout(() => {
      this.loadParamsUrl().then((solve) => {
        this.checkerMicroFormats(solve);
      });
    }, 2000);
  }

  loadParamsUrl() {
    return new Promise((resolve) => {
      this.breadcrumbs = [];
      let temtRouteBreadCrumb = this.router.url.split('/');
      const cleanURL = this.lastUlr.split("-").join(" ");

      if (isPlatformBrowser(this.platformId)) {
        if (cleanURL !== localStorage.getItem('lastService') && cleanURL !== 'carrito de compras' && localStorage.getItem('lastService') !== 'false') {
          localStorage.setItem('breadCrumbs', 'false');
        }

        const isPack = localStorage.getItem('breadCrumbs') === 'pack';
        const isService = localStorage.getItem('breadCrumbs') === 'service';

        if (this.falgService || this.falgPack) {
          localStorage.setItem('lastService', cleanURL);
        }
        if (isPack && this.falgPack) {
          this.breadcrumbs.push({ path: "paquetes servicios" });
        }
        else if (isService && this.falgService) {
          this.breadcrumbs.push({ path: "servicios" });
        }
        else if (cleanURL === 'carrito de compras' && localStorage.getItem('lastService') !== 'false') {
          if (isPack) {
            this.breadcrumbs.push({ path: "paquetes servicios" });
          }
          else if (isService) {
            this.breadcrumbs.push({ path: "servicios" });
          }
          this.breadcrumbs.push({ path: localStorage.getItem('lastService') });
        }
      }
      temtRouteBreadCrumb.forEach((element, index) => {
        let tempElement = +element;
        if (!+tempElement) {
          if (element !== "" && element !== "combos" && element !== 'ciudad' && element !== 'legal') {
            let tempElementPush = {} as any;
            if (element === "detalle-suscripciones") {
              tempElementPush.path = `${element.split("-").join(" ")}/${temtRouteBreadCrumb[index + 1]}`
            } else {
              tempElementPush.path = element.split('?')[0].split("-").join(" ");
            }
            this.breadcrumbs.push(tempElementPush);
          }
        }
      })
      resolve(this.breadcrumbs);
    });
  }

  refrestUrlSincronal() {
    this.router.events
      .subscribe((event: NavigationStart) => {
        this.loadParamsUrl().then((solve) => {
          this.checkerMicroFormats(solve);
        });
      });
  }

  checkerMicroFormats(objectRouter: any) {
    if (this.breadcrumbs.length >= 1) {
      this.breadcrumbs = [];
    }
    let params = '';
    let objectBreadcrum = [];
    if (objectRouter !== undefined) {
      this.pushObjectCrumm([{ 'label': 'Inicio', 'url': '/', 'id': 'trackInitial' }]);
      let urlConcatTemp = "";
      objectRouter.forEach((element) => {
        if (element.path !== undefined) {
          this.module = { name: element.path };
          if (element.path !== '/') {
            if (element.path?.indexOf(/\u200B/g) !== -1) {
              params = element.path?.replace(/\u200B/g, '');
            }
            if (element.path?.indexOf('-') !== -1) {
              params = element.path?.replace('-', ' ');
            }
            else {
              params = element.path;
            }
            urlConcatTemp = this.buildUrlLastService(urlConcatTemp, element);
            let labelName = this.buildPoliticsLabel(element, params);

            this.pushObjectCrumm([{ 'label': labelName, 'url': urlConcatTemp?.replace(/ /g, '-'), 'id': 'trackBreadcrum' }]);
            objectBreadcrum.push({ 'name': params, 'slug': element.path });
          } else {
            this.module = { name: 'Inicio' };
          }
        }
        else {
          this.module = { name: 'Inicio' };
        }
      });
      this.createMIcroformat(this.module, objectBreadcrum);
    }
  }
  /**
   * Crea el label para la vista de politicas y cookies de la plataforma
   * @param element elemento url
   * @param params params dentro del url
   * @returns label construido
   */
  buildPoliticsLabel(element, params): any {
    if (element.path?.includes('politica') && element.path?.includes('privacidad')) {
      return 'Política de privacidad';
    } else if (element.path?.includes('politica') && element.path?.includes('servicios')) {
      return 'Política de servicios';
    } else if (element.path?.includes('politica') && element.path?.includes('cookies')) {
      return 'Política de cookies';
    } else if (element.path?.includes('terminos') && element.path?.includes('condiciones')) {
      return 'Términos y condiciones';
    } else {
      return (params !== undefined && params !== null) ? params.split("/")[0] : '';
    }
  }
  /**
   * Metodo que verifica si se guarda el ultimo servicio en el breadcrumb o no
   * @param urlConcatTemp url temporal construida
   * @param element
   * @returns la url construida
   */
  buildUrlLastService(urlConcatTemp, element): string {
    let url = this.router.url.split('/');
    let lastService = localStorage.getItem('lastService');
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('breadCrumbs') !== 'false') {
        urlConcatTemp = `${element.path}`;
      }
      else if (lastService !== 'false' && url.includes(lastService)) {
        urlConcatTemp = localStorage.getItem('lastService');
      } else if (urlConcatTemp !== '') {
        urlConcatTemp += `/${element.path}`;
      } else {
        urlConcatTemp += `${element.path}`;
      }
    }
    return urlConcatTemp;
  }

  createMIcroformat(moduleName, array) {
    if (moduleName.name === 'Inicio')
      this.jsonLD = this.microformats.crumbBreadHome(moduleName.name, null);
    else
      this.jsonLD = this.microformats.crumbBreadcrum(array, null);
  }

  pushObjectCrumm(object: any) {
    object.forEach(element => {
      this.breadcrumbs.push({
        label: this.sanitizeLabel(element.label),
        icon: false,
        url: element.url,
        id: element.id
      });
    });
  }

  sanitizeLabel(label: string): string {
    return label.split('%')[0]
  }

  verifySlug(slug: string): Observable<any> {
    let booleanObject = {
      slugService: false,
      slugPack: false,
    }
    const resp = new Subject<any>();
    this.serviceArray = [];
    this.packArray = [];

    this.homeService.getDataCategories().subscribe((response: any) => {
      const arrayS = response.result.categories;

      arrayS.forEach((element: any) => {
        if (element.services.length > 0) {
          element.services.forEach(element2 => {
            this.serviceArray.push(element2.slug);
          });
        }
      });

      const answService = this.serviceArray.filter((elem: string) => elem == slug);
      booleanObject.slugService = answService.length > 0;

      this.homeService.getDataCategoriesJelpit().subscribe((response: any) => {
        const arrayP = response.result.categories;

        arrayP.forEach((element: any) => {
          if (element.services.length > 0) {
            element.services.forEach(element2 => {
              this.packArray.push(element2.slug);
            });
          };
        });

        const answPack = this.packArray.filter((elem: string) => elem == slug);
        booleanObject.slugPack = answPack.length > 0;

        resp.next(booleanObject);
      });
    });
    return resp.asObservable();
  }

  ngOnDestroy() {
    if (this.events !== undefined) {
      this.events.unsubscribe();
    }
  }
}
