import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { environment } from '@environment/environment';
declare let window: any;
@Injectable({
  providedIn: 'root',
})
export class ScriptsService {
  //Flag used to know if the user had an interaction with the SPA.
  private isMouseMove: boolean = false;
  //Service used to add script to the DOM
  private renderer2: Renderer2;
  isProduction: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document,
    private rendererFactory: RendererFactory2
  ) {
    this.isProduction = environment.portal === 'wwwjelpitcom' ? true : false;
    this.renderer2 = this.rendererFactory.createRenderer(null, null);

    //Add onBounce script only on PRE-PRODUCTIVE and PRODUCTIVE enviroments.
    if (
      environment.portal === 'prejelpitcom' ||
      environment.portal === 'wwwjelpitcom'
    )
      this.setScriptOnBounce();

    //Get the current URL to know if th euser is on the home
    //and lazy load the external scripts.
    let splitURL: string[] = window.location.href.split('/');
    let urlRoute: string = splitURL[splitURL.length - 1];
    if (urlRoute !== '') {
      this.loadScripts();
    }
    window.addEventListener('load', (event) => {
      setTimeout(() => {
        this.loadScripts();
      }, 5500);
    });
  }

  /**
   * If the mouse has not moved, then set the mouse move to true, set the GTagManager, iframe the
   * GoogleTagManager, generate the script for Survicate
   */
  loadScripts() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isMouseMove) {
        return;
      }
      this.isMouseMove = true;
      this.loadJSGoogleMaps();
      this.setGTagManager();
      this.setGTagManagerOther();
      this.iframeGoogleTagManager();
      this.generateScriptSurvicate();
      //this.loadPixelTikTok();
      this.createClarityScript();
      this.loadSdkOnLineInsurance();
      this.isProduction && this.createDatadogScript();
    }
  }
  /**
   * Function to render dataDog script on head
   */
  private createDatadogScript() {
    const existDatadogScript: boolean = !!this.document.getElementById('dataDog');
    if (!existDatadogScript) {
      const script: HTMLScriptElement = this.document.createElement('script');
      script.id = 'dataDog';
      script.type = 'text/javascript';
      script.src = 'https://www.datadoghq-browser-agent.com/datadog-rum-v4.js';
      script.crossOrigin = 'anonymous';
      script.integrity =
        'sha384-AN44pzJdfg/R/WQY2lwZ9Mzzu/brAJeuUsvxtjESP07/Gxht6HRx03ODQXfWRi+o';
      script.referrerPolicy = 'strict-origin-when-cross-origin';
      script.onload = () => {
        if (window.DD_RUM) {
          window.DD_RUM.init({
            applicationId: '2b76d29f-5e5f-4958-acf6-78168d5a38ec',
            clientToken: 'pubb45cb3d521bdd2ddfd59c33930543378',
            site: 'datadoghq.com',
            service: 'jelpit',
            env: 'prod',
            sampleRate: 30,
            replaySampleRate: 30,
            trackInteractions: true,
            defaultPrivacyLevel: 'mask-user-input',
          });
          window.DD_RUM.startSessionReplayRecording();
        }
      };
      this.document.head.appendChild(script);
    }
  }
  /**
   * It creates a script element, sets the type and source, and appends it to the body of the document
   */
  private setSearchBanner() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = environment.urlSearcherBanner;
    s.defer = true;
    s.setAttribute('rel', 'preload');
    this.renderer2.appendChild(this.document.body, s);
  }

  /**
   * We're creating a script element, setting its type, src, referrerPolicy, and async attributes, and
   * then appending it to the head of the document
   */
  private setScriptOnBounce() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://6f0ff7a757304149935f76d3455da73f.js.ubembed.com/';
    s.referrerPolicy = 'strict-origin-when-cross-origin';
    s.async = true;
    s.integrity = "sha384-WzoM56+ryw2adUGMWzSCBGkfW+DXs8m659dHjn0dQJ6Z8abiVusl+EXuqWiCRK+l";
    s.crossOrigin = 'anonymous';
    s.setAttribute('rel', 'preload');
    this.renderer2.appendChild(this.document.head, s);
  }

  /**
   * We create a script element, set its type, defer, rel, and innerHTML, and then append it to the
   * head of the document
   */
  private setGTagManager() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.defer = true;
    s.setAttribute('rel', 'preload');
    s.innerHTML = `(function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
      j.defer = true;
      j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', '${environment.google_tag_manager_key}')`;
    this.renderer2.appendChild(this.document.head, s);
  }

  /**
   * We create a script element, set its type, defer, rel, and innerHTML, and then append it to the
   * head of the document
   */
  private setGTagManagerOther() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.defer = true;
    s.setAttribute('rel', 'preload');
    s.innerHTML = `(function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.defer = true;
        j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${environment.google_tag_manager_key_other}')`;
    this.renderer2.appendChild(this.document.head, s);
  }

  /**
   * We create a noscript element, an iframe element, set the iframe's src to the Google Tag Manager
   * URL, set the iframe's defer attribute to true, set the iframe's rel attribute to preload, set the
   * iframe's display style to none, set the iframe's visibility style to hidden, set the iframe's
   * height to 0, set the iframe's width to 0, append the noscript element to the body, and then append
   * the iframe element to the noscript element
   */
  private iframeGoogleTagManager() {
    let iframe;
    let noscript;
    noscript = this.document.createElement('noscript');
    iframe = this.document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.google_tag_manager_key}`;
    iframe.defer = true;
    iframe.setAttribute('rel', 'preload');
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    iframe.height = '0';
    iframe.width = '0';
    this.document.body.appendChild(noscript)?.appendChild(iframe);
  }

  /**
   * We're creating a script element, setting the src attribute to the Google Maps API, and appending
   * it to the body of the document
   */
  private loadJSGoogleMaps(): void {
    let script = this.renderer2.createElement('script');
    script.defer = true;
    script.setAttribute('rel', 'preload');
    script.src =
      'https://maps.googleapis.com/maps/api/js?key=' +
      environment.googleKey +
      '&libraries=places&callback=';
    this.renderer2.appendChild(this.document.body, script);
  }

  /**
   * The function loads the SDK for online insurance from a specified URL and appends it to the
   * document body.
   */
  private loadSdkOnLineInsurance(): void {
    let script = this.renderer2.createElement('script');
    script.defer = true;
    script.setAttribute('rel', 'preload');
    script.src = `${environment.secureInsuranceBolivar.baseUrl}/seguros-en-linea/libreria/sdk/sdk.js`;
    this.renderer2.appendChild(this.document.body, script);
  }

  /**
   * It creates a script tag, sets the type to text/javascript, sets the defer attribute to true, and
   * sets the text to a function that sets the visitor traits
   */
  private generateScriptSurvicate(): void {
    let dataUserLocalSotrage: any = {};

    if (localStorage.getItem('dataUser')) {
      dataUserLocalSotrage = JSON.parse(atob(localStorage.getItem('dataUser')));
    } else {
      dataUserLocalSotrage = {
        email: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        second_last_name: '',
        phone: '""',
        document_number: '""',
      };
    }
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.setAttribute('rel', 'preload');
    s.defer = true;
    s.text = `(function(w) {
      function setAttributes() {
        w._sva.setVisitorTraits({
          email: "${dataUserLocalSotrage.email}",
          first_name: "${dataUserLocalSotrage.first_name} ${dataUserLocalSotrage.middle_name === null
        ? ''
        : dataUserLocalSotrage.middle_name
      }",
          last_name: "${dataUserLocalSotrage.last_name} ${dataUserLocalSotrage.second_last_name === null
        ? ''
        : dataUserLocalSotrage.second_last_name
      }",
          phone: ${dataUserLocalSotrage.phone},
          document_number: ${dataUserLocalSotrage.document_number}
        });
      }
      if (w._sva) {
        setAttributes();
      } else {
        w.addEventListener("SurvicateReady", setAttributes);
      }
    })(window);`;
    this.renderer2.appendChild(this.document.body, s);
  }

  /**
   * We create a script element, set its type to text/javascript, set its defer attribute to true, set
   * its rel attribute to preload, and then set its innerHTML to the code we want to run
   */
  private loadPixelTikTok(): void {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.defer = true;
    s.setAttribute('rel', 'preload');
    s.innerHTML = `!function (w, d, t) {
      w.TiktokAnalyticsObject=t;
      var ttq=w[t]=w[t]||[];
      ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
      ttq.setAndDefer=function(t,e){
        t[e]=function(){
          t.push([e].concat(Array.prototype.slice.call(arguments,0)))
        }
      };
      for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
      ttq.instance=function(t){
        for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n)ttq.setAndDefer(e,ttq.methods[n]);
        return e
      },
      ttq.load=function(e,n){
        var i="https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
        var o=document.createElement("script");
        o.type="text/javascript",
        o.async=!0,
        o.src=i+"?sdkid="+e+"&lib="+t;
        var a=document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(o,a)
      };
      ttq.load('CCH3BVJC77UE82V3QACG'); ttq.page();
    }(window, document, 'ttq');`;
    this.renderer2.appendChild(this.document.head, s);
  }
  /**
   * Function to render clarity script
   */
  private createClarityScript(): void {
    const clarityExist: boolean = this.document.getElementById('clarityScript')
      ? true
      : false;
    if (clarityExist === false) {
      const key: string = this.isProduction ? 'h4i8y1mwao' : 'hneu625axe';
      const script: HTMLScriptElement = this.document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'clarityScript';
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.integrity =
        'sha384-eTlG44mr/hpXTv4iB0Ue898H8tzkclIXw3jfagemNYWZukiarUgysdo0I3tAVcsN';
      script.referrerPolicy = 'strict-origin-when-cross-origin';
      script.innerHTML = `(function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "${key}");`;
      this.document.head.appendChild(script);
    }
  }
}
