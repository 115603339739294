import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class CurrentLocationService {

  googleKey = environment.googleKey;
  private geocoder: any;
  
  constructor() { /* This is an empty constructor it dosn't need any logic */  }

  getCurrentLocation(location: any): Observable<any> {
    this.geocoder = new google.maps.Geocoder();
    return new Observable(observer => {
      this.geocoder.geocode({ 'address': location }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          observer.next({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            results: results[0] || {}
          });
        } else {
          observer.next({ lat: 4.6594225, lng: -74.1067231 });
        }
        observer.complete();
      });
    });
  }

  getCityByGoogle(cityName: any): Observable<any> {
    this.geocoder = new google.maps.Geocoder();
    return new Observable(observer => {
      this.geocoder.geocode({ 'address': `${cityName}, Colombia` }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          observer.next(results[0]);
        } else {
          observer.next({});
        }
        observer.complete();
      });
    });
  }

  //START-SCAN
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        this.geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
        this.geocoder.geocode({ latLng: latlng }, (responses) => {
          resolve(responses[0].formatted_address);
        });
      }, (err) => {
        reject(err);
      });
    });
  }
  //END-SCAN
  
  /**
   * Función para retornar bounds de google según respuesta geometry
   * @param geometry Respuesta de geolocalizador.
   * @returns Google BOunds
   */
  getBounds(geometry: any){
    return new google.maps.LatLngBounds(
      {
        lat: geometry?.bounds?.getSouthWest()?.lat() || -66.846312,
        lng: geometry?.bounds?.getSouthWest()?.lng() || -81.8317,
      },
      {
        lat: geometry?.bounds?.getNorthEast()?.lat() || 13.5178,
        lng: geometry?.bounds?.getNorthEast()?.lng() || -4.22711,
      }
    );
  }
}
