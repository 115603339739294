<div class="main-container">
  <ng-container *ngIf="!searchSettled">
    <h1 class="main-title">Consulte su solicitud</h1>
    <p>Ingrese el número de radicado y revise el estado de su PQRS.</p>
  </ng-container>

  <!-- campo de buscar -->
  <ng-container *ngIf="!searchSettled">
    <mat-form-field appearance="outline" class="custom-input">
      <mat-label>Número de radicado</mat-label>
      <input type="hidden" [value]="crfToken" name="_csrf" >
      <input
        matInput
        #input
        placeholder="Número de radicado"
        [(ngModel)]="searchText"
        autocomplete="off"
      />
    </mat-form-field>
    <button mat-raised-button class="custom-button" [ngClass]="{'custom-button-ready':searchText!==''}" (click)="search()">
      Consultar
    </button>
  </ng-container>

  <ng-container *ngIf="searchSettled && confirmSearch">
    <mat-card class="example-card">
      <mat-card-content>
        <div class="main-title">Resultados de su solicitud</div>
        <p class="section-contents">
          Radicado <strong>{{ settled }}</strong>
        </p>
        <img src="../../../../assets/img/iconos/Fill 1.png" alt="icono confirm" class="confirm-icon" />
        <div class="sub-title">Su solicitud se encuentra en {{status}}</div>
        <p class="section-contents">
          Todavía estamos revisando su solicitud. Cuando tengamos una respuesta
          se la<br>comunicaremos al correo registrado.<br><br>Para más informacion
          comuníquese con nosotros <a href="mailto: contactanos@jelpit.com">aquí</a>.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button class="custom-back-button" mat-button (click)="goBack()">Volver</button>
      </mat-card-actions>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="searchSettled && !confirmSearch">
    <mat-card class="example-card">
      <mat-card-content>
        <div class="main-title">Resultados de su solicitud</div>
        <p class="section-contents">
          Radicado <strong>{{ settled }}</strong>
        </p>
        <img src="../../../../assets/img/iconos/icono_no_incluye.svg" alt="icono error" class="confirm-icon" />
        <div class="sub-title">No encontramos solicitudes registradas con este radicado</div>
        <p class="section-contents">
          Si el problema persiste, comuníquese con nosotros <a href="mailto: contactanos@jelpit.com">aquí</a>.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button class="custom-back-button" mat-button (click)="goBack()">Volver</button>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</div>
