import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-services-advantages',
  templateUrl: './services-advantages.component.html',
  styleUrls: ['./services-advantages.component.scss']
})
export class ServicesAdvantagesComponent {

  @Input()
  dataAdvantages: any = {};

  @Input()
  citiesDetail: boolean;

}
