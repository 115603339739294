import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SafePipe } from './../pipes/safe.pipe';
import es from '@angular/common/locales/es';
registerLocaleData(es);

//components
import { BreadcrumbComponent } from './../layouts/breadcrumb/breadcrumb.component';
import { BannerDetailComponent } from './../components/banner-detail/banner-detail.component';
import { SnackbarComponent } from './../components/snackbar/snackbar.component';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { ServicesAdvantagesComponent } from '@components/services-advantages/services-advantages.component';
import { ListCitiesComponent } from '@components/list-cities/list-cities.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { FormPqrsComponent } from '@components/form-pqrs/form-pqrs.component';
import { FollowUpPqrsComponent } from '@components/follow-up-pqrs/follow-up-pqrs.component';
import { ModalErrorPqrComponent } from '@components/modal-error-pqr/modal-error-pqr.component';
import { ModalTipsPqrComponent } from '@components/modal-tips-pqr/modal-tips-pqr.component';
import { SwiperModule } from 'swiper/angular';
import { MatIconModule } from '@angular/material/icon';
import { CardTestimonialComponent } from '@components/card-testimonial/card-testimonial.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ModalLocationAddressComponent } from '@components/modal-location-address/modal-location-address.component';
import { ChatAiComponent } from '@components/chat-ai/chat-ai.component';
import { LibTbInputTextModule } from 'tech-block-lib';
import { ModalOutJelpitComponent } from '@components/modal-out-jelpit/modal-out-jelpit.component';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';
import { LibTbModalNotificationModule } from 'tech-block-lib';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    BannerDetailComponent,
    SafePipe,
    SnackbarComponent,
    PaginationComponent,
    ServicesAdvantagesComponent,
    ListCitiesComponent,
    FormPqrsComponent,
    FollowUpPqrsComponent,
    ModalErrorPqrComponent,
    ModalTipsPqrComponent,
    CardTestimonialComponent,
    ModalLocationAddressComponent,
    ChatAiComponent,
    ModalOutJelpitComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    LazyLoadImageModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatIconModule,
    GoogleMapsModule,
    LibTbInputTextModule,
    InputTextModule,
    CheckboxModule,
    ButtonModule,
    KeyFilterModule,
    ConfirmDialogModule,
    LibTbModalNotificationModule
  ],
  exports: [
    BreadcrumbComponent,
    BannerDetailComponent,
    SafePipe,
    LazyLoadImageModule,
    HttpClientModule,
    SnackbarComponent,
    MatInputModule,
    MatSnackBarModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    PaginationComponent,
    ServicesAdvantagesComponent,
    ListCitiesComponent,
    FormPqrsComponent,
    FollowUpPqrsComponent,
    ModalErrorPqrComponent,
    ModalTipsPqrComponent,
    MatIconModule,
    CardTestimonialComponent,
    ChatAiComponent,
    ModalOutJelpitComponent
  ],
  providers: [
    ConfirmDialog
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
