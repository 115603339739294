import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { DataDetailServiceResponse, GetDataDetailService } from '../../interfaces/Services';
import { map } from 'rxjs/operators';
import { UserData, UserDataResponseService } from '@interfaces/SecureInsurance';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  urlBase: string = environment.apiUrl;
  urlCDN: string = environment.cdnFilesStatic;

  constructor(
    private httpClient: HttpClient,
    private httpClient2: HttpClient,
    handler: HttpBackend
  ) {
    this.httpClient2 = new HttpClient(handler);
  }

  getDataMenu(): Observable<any> {
    let url: string = `${this.urlBase}menu`;
    return this.httpClient2.get<any>(url);
  }

  getResidentialMenu(): Observable<any> {
    let url: string = `${this.urlBase}getAdditionalOption`;
    return this.httpClient.get<any>(url);
  }

  getDataCategories(): Observable<any> {
    const url = `${this.urlBase}categories`;
    return this.httpClient.get<any>(url);
  }

  getDataCategoriesJelpit(): Observable<any> {
    const url = `${this.urlBase}categories?category=jelpitpacks`;
    return this.httpClient.get<any>(url);
  }

  getDataCategory(id: any): Observable<any> {
    const url = `${this.urlBase}category/${id}`;
    return this.httpClient.get<any>(url);
  }

  getDataService(id: any): Observable<any> {
    const url = `${this.urlBase}service/${id}`;
    return this.httpClient.get<any>(url);
  }

  getDataHomeAll(): Observable<any> {
    let url: string = `${this.urlBase}home`;
    return this.httpClient2.get<any>(url);
  }

  getDataSliderHome(type: string): Observable<any> {
    let url: string = `${this.urlBase}slider/${type}`;
    return this.httpClient2.get<any>(url);
  }

  getIpPublic(): Observable<any> {
    const url = 'https://api.ipify.org?format=json';
    return this.httpClient2.get<any>(url);
  }

  getDataIp(ip: string): Observable<any> {
    const url = `${this.urlBase}ip/${ip}`;
    return this.httpClient.get<any>(url);
  }

  setDataIp(ip: any): Observable<any> {
    const url = `${this.urlBase}ip`;
    return this.httpClient.post<any>(url, ip);
  }

  getDataArticles(): Observable<any> {
    const url = `${this.urlBase}blog/posts?perPage=5`;
    return this.httpClient.get<any>(url);
  }

  getDataFooter(): Observable<any> {
    const url = `${this.urlBase}footer`;
    return this.httpClient.get<any>(url);
  }

  /**
   * getLinkBuilding
   */
  public getLinkBuilding() {
    const url = `${this.urlBase}linksBuilders`;
    return this.httpClient.get<any>(url);
  }


  public postNewVisitAudience(audience: string) {
    const url = `${this.urlBase}audiences/visit`;
    return this.httpClient.post(url, { "audience_token": audience });
  }

  public getExistyService(slug: string) {
    let url: string = '';
    if (localStorage.getItem('userSesionTC')) {
      let usedTC = JSON.parse(localStorage.getItem('usedTC'));
      if (usedTC) {
        url = `${this.urlBase}verifyService/${slug}/?id=${usedTC.id}`;
      } else{
        url = `${this.urlBase}verifyService/${slug}`;
      }
    } else {
      url = `${this.urlBase}verifyService/${slug}`;
    }
    return this.httpClient.get<any>(url);
  }

  /*
    get data redesign detail service
  */
  getNewDetailDataService(id: any): Observable<GetDataDetailService> {
    const url = `${this.urlBase}v1/services/${id}`;
    return this.httpClient.get<DataDetailServiceResponse>(url)
      .pipe(
        map(resp => resp.result)
      )
  }

  /**
   * The function `getUserData` retrieves user data based on a document number using an HTTP GET
   * request.
   * @param {string} documentNumber - The parameter `documentNumber` is a string that represents the
   * document number of a user.
   * @returns The `getUserData` function returns an Observable of type `UserData`.
   */
  getUserData(documentNumber: string): Observable<UserData> {
    const url = `${this.urlBase}getUserInfo`;
    return this.httpClient.get<UserDataResponseService>(url, { params: { document: documentNumber } })
      .pipe(
        map(resp => resp.result)
      )
  }

}
