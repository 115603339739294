import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environment/environment';
import { HomeService } from '@services/home/home.service';
import { LoadingService } from '@services/loading/loading.service';
import { finalize } from 'rxjs/operators';

declare const dataLayer: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  year = new Date().getFullYear();

  public co_footer_url: string = "";
  public need_help: string = "";
  public need_help_mobile: string = "";
  public technical_support: string = "";
  public loading: boolean = true;
  public path: string = "";
  public generalPath: string = environment.generalPath;
  public splitedPath: Array<string> = [];
  private setNeedHelp: string = "";
  private assistanceNeedHelp: string = "";
  private setTechnicalSupport: string = "";
  private assistanceTechnicalSupport: string = "";
  public communicationAssistanceUrl = environment.comunicaciones_url;
  public residentialsComunicationsUrl = environment.residentialsComunicationsUrl;
  
  private styleVars: any = {
    properties: [
      "--c-lib-tb-primaryBase",
      "--c-lib-tb-primaryBase2",
      "--c-lib-tb-tertiaryD200",
      "--c-lib-tb-secondaryD300",
      "--c-lib-tb-secondaryBase",
      "--c-lib-tb-bgPrimaryBase",
      "--c-lib-tb-tertiaryBase",
      "--c-lib-tb-successBase",
      "--c-lib-tb-bgTertiaryBase",
      "--c-lib-tb-tertiaryL400",
      "--filter-bgTertiaryBase",
      "--c-lib-tb-bgSecondaryD100",
      "--c-lib-tb-infoL400",
      "--c-lib-tb-infoL300",
      "--c-lib-tb-infoBase",
      "--c-lib-tb-primaryBasebtn",
      "--c-lib-tb-bgSecondaryBase",
      "--c-lib-tb-errorBase",
      "--c-secondBase",
      "--m-lib-footter"
    ],
    initialValues: [
      "#2e0063",
      "#2e0063",
      "#461464",
      "#50b44f",
      "#82e778",
      "#2e0063",
      "#75298C",
      "#28a745",
      "#75298c",
      "#f1eaf3",
      "invert(0) sepia(0) saturate(100%) hue-rotate(0) brightness(100%) contrast(100%)",
      "#58c657",
      "#e5f2ff",
      "#b2d8ff",
      "#007eff",
      "#2e0063",
      "#82E778",
      "#dc3545",
      "#82e778",
      "25px"
    ],
    changeValues: [
      "rgba(1,109,56)",
      "rgba(51,51,51)",
      "#FEC201FF",
      "rgb(1,109,56)",
      "rgb(1,109,56)",
      "rgb(1,109,56)",
      "rgb(1,109,56)",
      "rgb(1,109,56)",
      "rgb(1,109,56)",
      "rgba(1,109,56,0.55)",
      "invert(26%) sepia(76%) saturate(616%) hue-rotate(103deg) brightness(100%) contrast(59%)",
      "#FEC201FF",
      "rgba(1,109,56, 0.2)",
      "rgba(1,109,56, 0.4)",
      "rgb(1,109,56)",
      "#FFFFFF",
      "rgba(1,109,56)",
      "#82E778",
      "#FFFFF",
      "0px"
    ]
  }
  public enableName: boolean = false;
  constructor(
    private homeService: HomeService,
    private _loading: LoadingService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.splitedPath = this.generalPath.split(',');
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        this.path = window.location.hostname;
        this.updateStyles();
      }
    })
  }

  ngOnInit(): void {
    this.loadData();

    if (isPlatformBrowser(this.platformId) && localStorage.getItem('whatIsJelpit')) {
      localStorage.removeItem('whatIsJelpit');
      setTimeout(() => {
        this.scrollToWhatIsJelpit();
      }, 1500);
    }

  }

  private loadData() {
    this._loading.setLoading(true, 'footer');
    this.homeService.getDataFooter()
      .pipe(finalize(() => {
        this.loading = false;
        this._loading.setLoading(false, 'footer');
      }))
      .subscribe((data: any) => {
        this.co_footer_url = data.result.co_footer_url;
        this.need_help = data.result.need_help;
        this.need_help_mobile = data.result.need_help.replace('? ', '? <br />');
        this.technical_support = data.result.technical_support;
        this.setNeedHelp = data.result.need_help;
        this.assistanceNeedHelp = data.result.tcNeedHelp;
        this.setTechnicalSupport = data.result.technical_support;
        this.assistanceTechnicalSupport = data.result.tcTechnicalSupport;

      });
  }

  scrollToTop(): any {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  //START-SCAN
  scrollToWhatIsJelpit(): void {
    if (isPlatformBrowser(this.platformId) && document.location.pathname !== "/") {
      localStorage.setItem('whatIsJelpit', "true");
      window.location.href = "" + document.location.search;
    } else {
      let el = document.getElementById('whatIsJelpit');
      el.scrollIntoView({ block: "center", behavior: "smooth" });
    }

  }
  //END-SCAN

  /**
   * The function `updateStyles` updates the favicon and CSS properties based on certain conditions.
   */
  updateStyles(): void {
    let favIconLink = document.querySelector("link[rel~='icon']");
    let themeColor = document.querySelector("meta[name~='theme-color']");
    if (this.path?.includes("jelpit")) {
      favIconLink.setAttribute('href', 'favicon.ico');
      themeColor.setAttribute("content", this.styleVars.initialValues[0]);
      this.enableName = true;
      for(let i = 0; i<this.styleVars.properties.length; i++){
        document.documentElement.style.setProperty(this.styleVars.properties[i], this.styleVars.initialValues[i]);
      }
      setTimeout(() => {
        this.need_help= this.setNeedHelp;
        this.need_help_mobile = this.setNeedHelp.replace('? ', '? <br />');
        this.technical_support = this.setTechnicalSupport;
      }, 2000);
    } else {
      favIconLink.setAttribute('href', 'faviconV2.ico');
      themeColor.setAttribute("content", this.styleVars.changeValues[0]);
      this.enableName = false;
      for(let i = 0; i<this.styleVars.properties.length; i++){
        document.documentElement.style.setProperty(this.styleVars.properties[i], this.styleVars.changeValues[i]);
      }
      const summaryMobile = document.querySelector("app-modal-purchase-summary-mobile");
      if(summaryMobile){
        document.documentElement.style.setProperty('--p-lib-footer','100px');
      }
      setTimeout(() => {
        this.need_help= this.assistanceNeedHelp;
        this.need_help_mobile = this.assistanceNeedHelp.replace('? ', '? <br />');
        this.technical_support = this.assistanceTechnicalSupport;
      }, 2000);
    }
  }

  createDataLayerYoutube() {
    // TAG 7B
    dataLayer.push({
      'event': 'ga_event',
      'category': 'home footer',
      'action': 'JPW- contactenos',
      'label': 'youtube'
    });
  }

  createDataLayerLinkedin() {
    // TAG 7B
    dataLayer.push({
      'event': 'ga_event',
      'category': 'home footer',
      'action': 'JPW- contactenos',
      'label': 'linkedin'
    });
  }


  createDataLayerFacebook() {
    // TAG 7B
    dataLayer.push({
      'event': 'ga_event',
      'category': 'home footer',
      'action': 'JPW- contactenos',
      'label': 'Facebook'
    });
  }


  createDataLayerInstagram() {
    // TAG 7B
    dataLayer.push({
      'event': 'ga_event',
      'category': 'home footer',
      'action': 'JPW- contactenos',
      'label': 'Instagram'
    });
  }

  createDataLayerTikTok() {
    // TAG 7B
    dataLayer.push({
      'event': 'ga_event',
      'category': 'home footer',
      'action': 'JPW- contactenos',
      'label': 'TikTok'
    });
  }

}
