import { Component, PLATFORM_ID, Inject, HostListener } from '@angular/core';
import { environment } from '../environments/environment';
import { ShowSnackbarService } from './shared/services-components/show-snackbar/show-snackbar.service';
import { AccountService } from './shared/services/user-transversal/account.service';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from './shared/services/auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { ScriptsService } from './shared/services/scripts/scripts.service';
import { delay, filter } from 'rxjs/operators';
import { LoadingService } from '@services/loading/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  loading: boolean = false;
  path: string = "";
  assistancePages: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private scriptsService: ScriptsService,
    private _showSnackbarService: ShowSnackbarService,
    private accountService: AccountService,
    private authService: AuthService,
    private router: Router,
    private _loading: LoadingService
  ) {
    this.listenToLoading();
    this.setValidateTokenLogin();
    const events = router.events.pipe(filter(event => event instanceof NavigationEnd));
    events.subscribe((e: NavigationEnd) => {
      this.path =window.location.hostname;
      this.authService.createDataLayer();
    });
  }

  ngOnInit() {
    if (environment.maintenance) {
      this.router.navigate(['/maintenance']);
    }

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('portal', environment.portal);
    }
    const firstLogin = localStorage.getItem('firstLogin') ?? 'true';
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        if (firstLogin === 'true') {
          this.welcomeSnackbar();
          localStorage.setItem('firstLogin', 'false');
        }
      }
    }, 2000);
  }
  /**
   * metodo que valida si mostrar o no el modal de bienvenida en la aplicacion.
   * el unico caso excluyente es que se encuentre en el ecosistema home de davivienda.
   */
  welcomeSnackbar() {
    if (!window.location.href.includes('servicios-complementarios')) {
      this._showSnackbarService.showSnackbar('Bienvenido', true);
    }
  }

  @HostListener('document:touchstart', ['$event'])
  onTouchMove(e: any) {
    this.scriptsService.loadScripts();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    this.scriptsService.loadScripts();
  }

  setValidateTokenLogin(): void {
    if (this.authService.isLoggedIn()) {
      this.accountService.setValidateTokenLogin().subscribe(
        (data: any) => {
          if (data.code === 401) {
            if (isPlatformBrowser(this.platformId)) {
              localStorage.clear();
            }
          }
        },
        (error: any) => {
          if (isPlatformBrowser(this.platformId)) {
            localStorage.clear();
          }
        }
      );
    }
  }

  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        if(this.path.includes("jelpit")){
          this.assistancePages = false;
        }else {
          this.assistancePages = true;
        }
        this.loading = loading;
      });
  }
}
