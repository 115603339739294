import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
} from '@angular/router';
import { SeoService } from '@services/seo/seo.service';

@Injectable({ providedIn: 'root' })
export class MetaGuard implements CanActivate, CanActivateChild {
  constructor(private seoService: SeoService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const metaSettings = route.data?.meta;
    if (metaSettings) {
      this.seoService.updateMetas(metaSettings);
    }
    return true;
  }
  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.canActivate(route);
  }
}
