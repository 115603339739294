import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CsrfTokenService {

  constructor(private cookieService: CookieService) { }
  /**
   * Function to generate crsf hash 
   * @param length max charactes size of hash
   * @returns generated hash
   */
  generateCsrfToken(length: number): string {
    const generatedCsrf = this.getCsrfTokenCookie();
    if (generatedCsrf !== null) {
      return generatedCsrf;
    }
    const characters: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let csrf: string = '';
    const crypto = window.crypto;
    for (let i = 0; i < length; i++) {
      const array = new Uint8Array(1);
      const randomNumberGenerated = Number(crypto?.getRandomValues(array)) / 255;
      csrf += characters.charAt(Math.floor(randomNumberGenerated * (characters.length - 1)));
    }
    this.setCookieCsrfToken(csrf);
    return csrf;
  }
  /**
   * Function to set crsf hash to session storage
   * @param token crsf token generated
   */
  setCookieCsrfToken(token: string) {
    this.cookieService.set('XSRF-TOKEN', token, {
      secure: true,
      sameSite: 'Lax',
    });
  }
  /**
   * Function to return csrf hash if exist
   * @returns string || null
   */
  getCsrfTokenCookie(): string | null {
    return this.cookieService.get('XSRF-TOKEN') || null;
  }
}
