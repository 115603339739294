import { Component, Inject } from '@angular/core';
import { AuthService } from './../../services/auth/auth.service';
import { MenuUserService } from './../../services/component-services/menu-user/menu-user.service';
import { DOCUMENT } from '@angular/common';

declare const dataLayer: any;
@Component({
  selector: 'app-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss']
})
export class MenuUserComponent {

  hasSuscription:boolean = false;

  constructor(
    private _closeMenu: MenuUserService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document,
  ) {

  }

  /**
   * This function closes a menu and pushes a 3D event with an optional label.
   * @param {string} [label] - The `label` parameter is an optional string parameter that is passed to
   * the `pushEvent3D` method. It is used to identify the event that is being pushed to the 3D event
   * system. If no label is provided, the method will still be called but with an undefined value for
   */
  closeMenu(label?: string) {
    this.pushEvent3D(label);
    let header = this.document.getElementById('menu_user');
    header?.classList.remove('show_user_m');
    this._closeMenu.closeMenu();
  }

  /**
   * The function logs out the user and pushes a 3D event with an optional label.
   * @param {string} [label] - The label parameter is an optional string that is used as a parameter
   * for the pushEvent3D method. It is not required for the method to function properly, but can be
   * used to provide additional information or context for the event being pushed.
   */
  closeSession(label?: string): void {
    this.pushEvent3D(label);
    this.authService.logout();
  }

  /**
   * This function pushes an event to the data layer with a specific category, action, and label.
   * @param {string} label - The label parameter is a string that represents additional information
   * about the event being tracked. It can be used to provide more context about the user's interaction
   * with the website or app. In this specific example, the label parameter is being passed as an
   * argument to the pushEventDataLayer function, which is then used
   */
  pushEventDataLayer(label: string){
    dataLayer.push({
      'event':'ga_event',
      'category':'home header',
      'action': 'JPW - mi cuenta',
      'label':label
    });
  }

  /**
   * This function pushes 3D event data to a data layer with a given label.
   * @param {string} label - The label parameter is a string that represents the label or name of the
   * event that is being pushed to the data layer. This function is likely used in a web analytics
   * tracking implementation to track user interactions on a website or application.
   */
  pushEvent3D(label: string){
    if (label) {
      this.pushEventDataLayer(label);
    }
  }

}
