<section class="list-cities">
    <div class="container">
        <h2 class="title"  *ngIf="infoCities.title">{{infoCities.title}}</h2>
        <mat-accordion multi>
            <mat-expansion-panel *ngFor="let city of infoCities.links; let i = index; trackBy:trackByRenderService">
                <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'" (click) ="dataLayerExpand(city.title, infoCities.title)">
                    <h4 class="subtitle">{{city.title}}</h4>
                    <div class="color" [style.background] ="city.color_hex"></div>
                </mat-expansion-panel-header>
                <ul>
                    <li *ngFor="let link of city.links">
                        <a style="cursor: pointer;" (click) ="createDataLayer(link.title, infoCities.title); goTo(link.link)">{{link.title}}</a>
                    </li>
                </ul>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>
