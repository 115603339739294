import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ServicesJelpitService } from '@services/services-jelpit/services-jelpit.service';
import { SafePipe } from '@pipes/safe.pipe';

@Injectable({
  providedIn: 'root'
})
export class Microformats {
  private safePipe: SafePipe = new SafePipe(this.sanitizer);

  public proyectUrl: string = '';
  public type: string = environment.type;
  public addressCountry: string = environment.addressCountry;
  public postalCode: string = environment.postalCode;
  public streetAddress: string = environment.streetAddress;
  public addressLocality: string = environment.addressLocality;
  public urlImg: string = '';

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private jelpitService: ServicesJelpitService
  ) {
    this.proyectUrl = jelpitService.envUrl;
    this.urlImg = jelpitService.envUrl;
  }

  organization(host?: string) {
    const json = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      'name': 'jelpit.com',
      'url': this.proyectUrl,
      'logo': this.urlImg + '/assets/img/brand/logo_jelpit_color.svg',
      'email': '',
      'telephone': '',
      'address':
      {
        '@type': this.type,
        'addressCountry': this.addressCountry,
        'postalCode': this.postalCode,
        'streetAddress': this.streetAddress,
        'addressLocality': this.addressLocality
      },
      'sameAs': [
        'https://www.facebook.com/soyjelpit',
        'https://www.instagram.com/soyjelpit',
        "https://twitter.com/soyjelpit?lang=es",
        "https://www.youtube.com/channel/UCq3Dez4wL-0VXf18QFFECTg",
        "https://co.linkedin.com/company/jelpit",
      ]

    }
    return this.getSafeHTML(json);
  }

  crumbBreadHome(paramsJson?: string, host?: string) {
    const json = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': {
        '@type': 'ListItem',
        'position': 1,
        'item': {
          '@id': this.proyectUrl,
          'name': 'Inicio'
        }
      }
    }
    return this.getSafeHTML(json);
  }

  website(host?: string) {
    const json = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      'name': 'Jelpit.com',
      'url': this.proyectUrl
    }
    return this.getSafeHTML(json);
  }

  getSafeHTML(value: {}) {
    const json = value ? JSON.stringify(value, null, 2) : '';
    const html = `<script type="application/ld+json"> ${json} </script>`;
    return this.safePipe.transform(html, 'html');
  }

  microformatFAQ(data: any) {
    let faq = [];
    data.forEach(pqr => {
      faq.push({
        "@type": "Question",
        "name": pqr.name,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": pqr.description.replace(/<[^>]*>?/gm, '')
        }
      });
    });
    const json = {
      "@context": "https://schema.org",
      "@type": "FAQPage", "mainEntity": faq
    }
    return this.getSafeHTML(json);
  }

  crumbBreadcrum(object: any, host: string) {
    let count = 1;
    let objectBreadcrum = [{
      '@type': 'ListItem',
      'position': 1,
      'item': {
        '@id': this.proyectUrl,
        'name': 'Inicio'
      }
    }];
    for (let index = 0; index < object.length; index++) {
      count = count + 1
      const element = object[index];
      if (object.length > 1) {
        if ((object.length - 1) === index || index > 0) {
          objectBreadcrum.push({
            '@type': 'ListItem',
            'position': count,
            'item': {
              '@id': this.proyectUrl + '/' + object[index - 1].slug + '/' + element.slug,
              'name': element.name
            }
          });
        } else {
          objectBreadcrum.push({
            '@type': 'ListItem',
            'position': count,
            'item': {
              '@id': this.proyectUrl + '/' + element.slug,
              'name': element.name
            }
          });
        }
      } else {
        objectBreadcrum.push({
          '@type': 'ListItem',
          'position': count,
          'item': {
            '@id': this.proyectUrl + '/' + element.slug,
            'name': element.name
          }
        });
      }
    }
    const json = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': objectBreadcrum
    }
    return this.getSafeHTML(json);
  }

  microformatProduct(data: any, price: string, cities: string) {
    const dateTemp = new Date();
    const descriptionTemp = data.summary.replace(/<[^>]*>?/gm, '');
    const json = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      'name': data.name,
      'image': data.icon? data.icon : '',// null genera error
      'description': descriptionTemp,
      'sku': '1000',
      'mpn': cities,
      "brand": {
        "@type": "Thing",
        "name": "Jelpit"
      },
      "review": {
        "@type": "Review",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "4.4",
          "bestRating": "5"
        },
        "author": {
          "@type": "Organization",
          "name": "Jelpit"
        }
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.4",
        "reviewCount": "89"
      },
      "offers": {
        "@type": "Offer",
        "url": `${this.proyectUrl}${this.router.url}`,
        "priceCurrency": "COP",
        "price": price,
        "priceValidUntil": `${dateTemp.getFullYear()}-${this.addZero(dateTemp.getMonth() + 1)}-${this.addZero(dateTemp.getDate())}`,
        "itemCondition":
          "La fecha del servicio estará sujeta a disponibilidad",
        "availability": "https://schema.org/OnlineOnly"
      }
    }
    return this.getSafeHTML(json);
  }

  addZero(i): void {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
}
