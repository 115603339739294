import { Component, OnInit } from '@angular/core';
import { ShowSnackbarService } from './../../services-components/show-snackbar/show-snackbar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  flagShowSnackbar: boolean = false;
  textShowSnackbar: string = "";
  private snackbarSubscription: Subscription;

  constructor(
    private _showSnackbarService: ShowSnackbarService
  ) { }

  ngOnInit(): void {
    this.snackbarSubscription = this._showSnackbarService.sendShowSnackbarObservable.subscribe((data: any) => {
      setTimeout(() => {
        this.flagShowSnackbar = false;
        this.textShowSnackbar = "";
      }, 3000);

      this.flagShowSnackbar = data.show;
      this.textShowSnackbar = data.text;
    });
  }

  ngOnDestroy(): void {
    this.snackbarSubscription.unsubscribe();
    this.flagShowSnackbar = false;
    this.textShowSnackbar = "";
  }
}
