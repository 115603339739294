<footer class="footer" id="footer-jelpit" *ngIf="!loading" [ngClass]="{'assistance': !this.path.includes('jelpit')}">
  <div class="top_footer">
    <div class="container">
      <div class="footer-top-content" [ngClass]="{'assistance': !this.path.includes('jelpit')}">
        <button class="ancla-btn" [ngClass]="{'assistancebtn': !this.path.includes('jelpit')}" (click)="scrollToTop()">
          <img
            src="/assets/img/iconos/arrow_up.png"
            alt="icono subir"
            height="40"
            width="40"
          />
        </button>
        <div class="footer-logo" [ngClass]="{'assistance': !this.path.includes('jelpit')}">
          <img
            src="{{ this.path.includes('jelpit') ? '../../../../assets/img/Brand.svg' : '../../../../assets/img/ServiciosBolivarBrand.png' }}"
            alt="{{ this.path.includes('jelpit') ? 'Jelpit' : 'AsistenciaBolivar' }}"
          />
        </div>
        <div class="footer-content-middle">
          <div class="footer-text-content">
            <div class="head desktop" [innerHTML]="need_help"></div>
            <div class="head mobile" [innerHTML]="need_help_mobile"></div>
          </div>
          <div class="footer-text-content">
            <div class="description" [innerHTML]="technical_support"></div>
          </div>
        </div>
        <div class="share" *ngIf="this.path.includes('jelpit')">
          <ul>
            <li class="youtube">
              <a
                href="https://www.youtube.com/channel/UCq3Dez4wL-0VXf18QFFECTg"
                target="_blank"
                rel="nofollow, noopener"
              >
                <span
                  class="icon-youtube-circle"
                  (click)="createDataLayerYoutube()"
                ></span
                >youtube
              </a>
            </li>
            <li class="linkedin">
              <a
                href="https://co.linkedin.com/company/jelpit"
                target="_blank"
                rel="nofollow, noopener"
              >
                <span
                  class="icon-linkedin-circle"
                  (click)="createDataLayerLinkedin()"
                ></span
                >linkedin
              </a>
            </li>
            <li class="facebook">
              <a
                href="https://www.facebook.com/soyjelpit/"
                target="_blank"
                rel="nofollow, noopener"
                ><span
                  class="icon-facebook-circle"
                  (click)="createDataLayerFacebook()"
                ></span
                >Facebook</a
              >
            </li>
            <li class="instagram">
              <a
                href="https://www.instagram.com/soyjelpit/"
                target="_blank"
                rel="nofollow, noopener"
                ><span
                  class="icon-instagram-circle"
                  (click)="createDataLayerInstagram()"
                ></span
                >Instagram</a
              >
            </li>
            <li class="tiktok">
              <a
                href="https://www.tiktok.com/@jelpitcol/"
                target="_blank"
                rel="nofollow, noopener"
              >
                <img
                  class="icon-tiktok-footer"
                  src="/assets/fonts/icon_tik_tok.svg"
                  alt="TikTok"
                  (click)="createDataLayerTikTok()"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="this.path.includes('jelpit')" class="menu-footer-container qa custom-top-margin-qa">
        <div class="menu-footer-container-item">
          <a href="/preguntas-frecuentes" target="_blank" rel="nofollow"
            >Preguntas frecuentes</a
          >
        </div>
      </div>
      <div class="separator"></div>
      <div class="footer-mid-content">
        <div class="menu-footer-container">
          <div *ngIf="this.path.includes('jelpit')" class="menu-footer-container-item mobile">
            <a href="/preguntas-frecuentes" target="_blank" rel="nofollow"
              >Preguntas frecuentes</a
            >
          </div>
          <div class="menu-footer-container-item">
            <a *ngIf="!this.enableName" [routerLink]="['/legal/terminos-y-condiciones']" queryParamsHandling="preserve" rel="noopener"
              target="_blank" rel="nofollow">Términos y condiciones</a>

            <a *ngIf="this.enableName" href="https://novedades.jelpit.com/jelpitconjuntos/terminos-y-condiciones/" target="_blank" rel="noopener"
              rel="nofollow">Términos y condiciones</a>
          </div>
          <div class="menu-footer-container-item">
            <a
              routerLink="{{ this.enableName ? '/conjuntos/politica-de-privacidad' :'/legal/politica-de-privacidad' }}"
              queryParamsHandling="preserve"
              target="_blank"
              rel="nofollow"
              >Política de Tratamiento de Datos Personales</a
            >
          </div>
          <div *ngIf="!this.enableName" class="menu-footer-container-item">
            <a
              routerLink="/legal/politica-de-servicios"
              queryParamsHandling="preserve"
              target="_blank"
              rel="nofollow"
              >Políticas de servicio del portal</a
            >
          </div>
          <div *ngIf="!this.enableName" class="menu-footer-container-item">
            <a
              routerLink="/legal/politica-de-cookies"
              queryParamsHandling="preserve"
              target="_blank"
              rel="nofollow"
              >Política de cookies</a
            >
          </div>
          <div class="menu-footer-container-item">
            <a
              href="https://www.sic.gov.co/"
              target="_blank"
              rel="noopener nofollow"
              >Superintendencia de Industria y Comercio (SIC)</a
            >
          </div>
          <div class="menu-footer-container-item">
            <a
              href="{{ !this.enableName ? this.communicationAssistanceUrl :  this.residentialsComunicationsUrl }}"
              target="_blank"
              rel="noopener nofollow"
              >Canales de preferencia</a
            >
          </div>
        </div>
      </div>
      <div class="separator"></div>
    </div>
  </div>
  <div class="bottom-footer">
    <p>
      {{ this.enableName ? ("Jelpit " + year + "  © - " ):""}}Servicios Bolívar S.A.<br />
      <span class="nit"><br /></span>NIT: 900.311.092-7. Todos los derechos
      reservados. Dirección de notificación judicial:<br />
      Av. El Dorado No. 68B-31 Bogotá D.C.
    </p>
  </div>
</footer>
