<div class="mega_menu static_header" #headerDesktopWhite>
  <div class="container container_menu">
    <lib-tb-search
      *ngIf="menuData[1]?.show_searcher"
      [custom]="searchBarConfig"
    ></lib-tb-search>
    <div class="menu_options">
      <div class="menu_list" (clickOutside)="onClickedOutside($event)">
        <ul class="menu_collapse">
          <li class="has_submenu small" *ngIf="listJelpitResidentialMenu.name && this.domain.includes('jelpit')" >
            <p
              (click)="
                showMegamenu(3); eventMenuTag(listJelpitResidentialMenu.name)
              "
              class="item_menu button"
              [ngClass]="{ show_megamenu: selectedSubmenu === 3 }"
            >
              {{ listJelpitResidentialMenu.name }}
              <span class="icon-arrow_down"></span>
            </p>
            <div class="submenu small">
              <div class="container">
                <div>
                  <div class="grid-3">
                    <p
                      class="item_menu_subtitle"
                      *ngIf="listJelpitResidentialMenu.name"
                      (click)="
                        closeMegaMenu(listJelpitResidentialMenu.name);
                        redirectUrl(
                          {
                            name: listJelpitResidentialMenu.name,
                            url: listJelpitResidentialMenu.category_url
                          },
                          '/servicios/'
                        )
                      "
                    >
                      {{ listJelpitResidentialMenu.name }}
                    </p>
                    <ul>
                      <li
                        *ngFor="
                          let item of listJelpitResidentialMenu.options
                            | slice : 0 : 5
                        "
                      >
                        <a
                          (click)="
                            closeMegaMenu(item.name);
                            redirectUrl(item);
                            eventResidential(item.name)
                          "
                          queryParamsHandling="preserve"
                          >{{ item.name }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="has_submenu" *ngIf="listServicesMenu.length > 0 && this.domain.includes('jelpit')">
            <p
              (click)="showMegamenu(1); eventMenuTag('Servicios')"
              class="item_menu"
              [ngClass]="{ show_megamenu: selectedSubmenu === 1 }"
            >
              {{ menuNames?.service ?? 'Servicios' }} <span class="icon-arrow_down"></span>
            </p>
            <div class="submenu">
              <div class="container">
                <div class="grid">
                  <div
                    class="grid-3"
                    *ngFor="let service of listServicesMenu | slice : 0 : 4"
                  >
                    <p
                      class="item_menu_subtitle"
                      *ngIf="service.name"
                      (click)="
                        closeMegaMenu(service.name);
                        redirectUrl(service, '/servicios/')
                      "
                    >
                      {{ service.name }}
                    </p>
                    <ul *ngIf="service.name !== 'Combos'">
                      <li *ngFor="let item of service.services | slice : 0 : 5">
                        <a
                          (click)="
                            closeMegaMenu(item.name);
                            redirectUrl(item);
                            setCategoryUsed(service.slug)
                          "
                          queryParamsHandling="preserve"
                          >{{ item.name }}</a
                        >
                      </li>
                    </ul>
                    <ul *ngIf="service.name === 'Combos'">
                      <li *ngFor="let item of service.services | slice : 0 : 5">
                        <a
                          (click)="
                            closeMegaMenu(item.name);
                            redirectUrl(item);
                            setCategoryUsed(service.slug)
                          "
                          queryParamsHandling="preserve"
                          >{{ item.name }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="link_others">
                  <a
                    routerLink="/servicios"
                    queryParamsHandling="preserve"
                    (click)="closeMegaMenu('Ver más')"
                    itemprop="url"
                    (click)="setType(1)"
                  >
                    Ver más
                    <span class="icon-arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li class="has_submenu" *ngIf="listJelpitpacksMenu.length > 0 && this.domain.includes('jelpit')" >
            <p
              (click)="showMegamenu(2); eventMenuTag('Jelpit Packs')"
              class="item_menu"
              [ngClass]="{ show_megamenu: selectedSubmenu === 2 }"
              data-qa-id="JP-P-SH-M-p_show_jelpitpacks_options"
            >
              Jelpit Packs<span class="icon-arrow_down"></span>
            </p>
            <div class="submenu">
              <div class="container">
                <div class="grid">
                  <div
                    class="grid-3"
                    *ngFor="
                      let jelpitpack of listJelpitpacksMenu | slice : 0 : 4
                    "
                  >
                    <p
                      class="item_menu_subtitle"
                      *ngIf="jelpitpack.name"
                      (click)="
                        closeMegaMenu(jelpitpack.name);
                        redirectUrl(jelpitpack, '/paquetes-servicios/')
                      "
                    >
                      {{ jelpitpack.name }}
                    </p>
                    <ul>
                      <li
                        *ngFor="let item of jelpitpack.services | slice : 0 : 5"
                      >
                        <a
                          (click)="
                            closeMegaMenu(item.name);
                            redirectUrl(item);
                            setCategoryUsed(jelpitpack.slug)
                          "
                          queryParamsHandling="preserve"
                          data-qa-id="JP-P-SH-M-aref_selected_jelpitpacks_option"
                          >{{ item.name }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="link_others">
                  <a
                    (click)="closeMegaMenu('Conocer todos los Jelpit packs')"
                    routerLink="/paquetes-servicios"
                    queryParamsHandling="preserve"
                    (click)="setType(2)"
                    data-qa-id="JP-P-SH-M-aref_show_all_jelpitpacks"
                  >
                    Conocer todos los Jelpit packs
                    <span class="icon-arrow_right"></span
                  ></a>
                </div>
              </div>
            </div>
          </li>
          <li *ngIf="alliesMenu.name && this.domain.includes('jelpit')">
            <a
              (click)="eventMenuTag(alliesMenu.name)"
              [routerLink]="alliesMenu.slug"
              queryParamsHandling="preserve"
              class="item_menu"
              >{{ alliesMenu.name }}</a
            >
          </li>
          <li *ngIf="false" >
            <a
              (click)="eventMenuTag('Blog')"
              routerLink="/blog/"
              queryParamsHandling="preserve"
              class="item_menu"
              target="_blank"
              >Blog</a
            >
          </li>
        </ul>
      </div>
      <div class="option_user">
        <ul>
          <li class="floating_btn"  *ngIf="this.domain.includes('jelpit')">
            <a
              href="{{ administrationFeeUrl }}"
              (click)="createDataLayerFloatingButton()"
            >
              <img
                src="../../../../assets/img/home/floating_residential_jelpit.svg"
                alt="pagar administracion jelpit"
              />
            </a>
          </li>
          <li
            (click)="eventUserMenu('Carrito')"
            *ngIf="listServicesMenu.length > 0 && this.domain.includes('asistencias')"
            [ngClass]="{'assistances': domain.includes('asistencias')}"
          >
            <a
              class="item_link"
              routerLink="/carrito-de-compras"
              data-qa-id="JP-P-SH-M-aref_go_shopping_cart"
            >
              <app-car-button-sumary
                [countProducts]="countProducts"
              ></app-car-button-sumary>
              <span class="txt_link">Carrito</span>
            </a>
          </li>
          <li (clickOutside)="onClickedOutMenu($event)" *ngIf="this.domain.includes('jelpit')">
            <a
              class="item_link"
              (click)="showMenuUser()"
              data-qa-id="JP-P-SH-M-aref_show_user_menu"
            >
              <i class="fa-light fa-user"></i>
              <span class="txt_link">{{ nameUser }}</span>
            </a>
            <div class="menu_user" [ngClass]="{ show_user_m: show_menu_user }">
              <app-menu-user></app-menu-user>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <app-menu-mobile [dataMenu]="menuData"></app-menu-mobile>
  </div>
</div>
<app-layout-mia [services]="listServicesMenu" [menuData]="menuData" [domain]="this.domain"></app-layout-mia>
