import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { throwError } from 'rxjs';
import { AllyServiceResponse, NewUserData, UserDataServiceResponse } from '../interfaces/allies';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlliesService {
  private urlBase: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }


  /**
   * If the error is a client-side or network error, log it to the console. If the error is a 404,
   * return a custom error message. Otherwise, log the error to the console
   * @param {HttpErrorResponse} error - HttpErrorResponse - The error object that was thrown
   * @returns An observable with a user-facing error message.
   */
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else if (error.error.code === 404) {
      //The user is not registered on DB. Not an actual error.
      return throwError(() => new Error('Not registered.'));
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }

  /**
   * The function `getAllies` makes an HTTP GET request to retrieve information about allies from a
   * specified URL.
   * @returns The getAllies() function is returning an Observable of type AllyServiceResponse.
   */
  public getAllies() {
    const url = `${this.urlBase}alliesSectionInfo`;
    return this.httpClient.get<AllyServiceResponse>(url).pipe(catchError(this.handleError));
  }

  /**
   * The function `getUserData` retrieves user data by making an HTTP GET request to a specified URL
   * with the provided email as a parameter.
   * @param {string} email - The email parameter is a string that represents the email address of the
   * user for whom you want to retrieve data.
   * @returns The `getUserData` function is returning an Observable of type `UserDataServiceResponse`.
   */
  public getUserData(email: string) {
    const url = `${this.urlBase}userRedirectedInfo`;
    return this.httpClient.get<UserDataServiceResponse>(url, { params: { email: email } })
      .pipe(catchError(this.handleError));
  }

  /**
   * The function `setUserData` sends a POST request to the `userRedirectedInfo` endpoint with the
   * provided `userData` and handles any errors that occur.
   * @param {NewUserData} userData - The `userData` parameter is of type `NewUserData`. It represents
   * the data that will be sent to the server when making a POST request to the `userRedirectedInfo`
   * endpoint.
   * @returns The `setUserData` function is returning an Observable of type `any`.
   */
  public setUserData(userData: NewUserData) {
    const url = `${this.urlBase}userRedirectedInfo`;
    return this.httpClient
      .post<any>(url, userData)
      .pipe(catchError(this.handleError));
  }
}
