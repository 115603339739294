<nav aria-label="navigation" class="pager">
    <div id="cont-scroll">
        <ul class="pagination">
            <li class="page-item page-prev" *ngIf="selectActivePage > 1"><a class="page-link" (click)="pageLess()"><span
                        class="icon-arrow_left"></span></a></li>
            <li class="page-item" [ngClass]="{'active': selectActivePage === (i+1)}" *ngFor="let item of [].constructor(dataForPage); let i = index ">
                <a class="page-link" (click)="changePage(i + 1)">{{ i + 1 }}</a>
            </li>
            <li class="page-item page-next" *ngIf="selectActivePage < dataForPage"><a class="page-link" (click)="pageMore()"><span class="icon-arrow_right"></span></a></li>
        </ul>
    </div>
</nav>