import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { AiMessageBody, AiMessageResponse, UserOpinionBody } from '@interfaces/ai';

@Injectable({
  providedIn: 'root'
})
export class ChatAiService {
  baseUrl = environment.iaEndpoint + '/jelpit/ia/python/ia/jelpit/';

  constructor(private httpClient: HttpClient) { }

  postMessageToAi(body: AiMessageBody, prompt: string, accesToken: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accesToken}`);
    return this.httpClient.post<AiMessageResponse>(this.baseUrl + prompt, body, { headers });
  }

  postUserOpinion(body: UserOpinionBody, accesToken: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accesToken}`);
    return this.httpClient.post<any>(this.baseUrl + 'messageLike', body, { headers });
  }
}
